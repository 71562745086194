import React from 'react';
import PropTypes from 'prop-types';
const matchCompany = (terms, company) => {
  let match = false;
  if (terms){
    terms.map((term, index)=>{
      if (term.core === company || term.ticker === company ){
        match = true;
      }
      return true; 
    });
  }
  return match;
}

const TermsInArticle = props => {
  let listString = '';
  props.articleCompanies._details.found_companies.map((company, index) => {
    if (matchCompany(props.selectedTerms, company))
      listString += company +' ';
    return null;
  });
  props.articleTerms.map((term, index) => {
    listString += term.term +'('+term.count+') ';
    return null;
  });
  props.articleMetrics.map((term, index) => {
    listString += term.term +'('+term.count+') ';
    return null;
  });
  return <div className="terms-in-article">{listString}</div>;
};

TermsInArticle.propTypes = {
  articleTerms: PropTypes.array,
  articleMetrics: PropTypes.array
};

TermsInArticle.defaultProps = {
  articleTerms: [],
  articleMetrics: []
};

export default TermsInArticle;
