import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchSaveFetchStart, searchEditFetchStart } from '../../actions/search';

import SaveModal from './save-modal';

const mapStateToProps = state => ({
  currentSource: state.articleList.currentSource,
  isLoadingSaveSearch: state.searchList.isLoading,
  searchSaveResponse: state.searchSave.response,
  selectedFilterInputs: state.selectedFilter,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      searchSaveFetchStart,
      searchEditFetchStart,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveModal);
