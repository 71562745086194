import {
  FILTERED_TOPIC_FETCH_START,
  FILTERED_TOPIC_FETCH_SUCCESS,
  FILTERED_TOPIC_FETCH_ERROR,
  SEARCH_TERMS_REMOVE,
} from '../../actions/topic';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

export const topicReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FILTERED_TOPIC_FETCH_START:
      return { ...state, isLoading: true };
    case FILTERED_TOPIC_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.result,
        error: null,
        isLoading: false,
      };
    case FILTERED_TOPIC_FETCH_ERROR:
      return {
        ...state,
        data: null,
        error: payload.error,
        isLoading: false,
      };
    case SEARCH_TERMS_REMOVE:
      return {
        ...state,
        data: null,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
