import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastProvider } from 'react-toast-notifications';
import Header from '../components/menu-header/header.container';
import Menu from '../components/menu-header/menu';
import ArticleCategories from '../pages/article/article-categories.container';
import SearchSidebar from '../components/search/search-sidebar.container';

function Main() {
  return (
    <ToastProvider>
      <SearchSidebar pageWrapId={'page-wrap'} outerContainerId={'App'} />
      <Container fluid id="page-wrap">
        <Header />
        <Menu />
        <ArticleCategories />
      </Container>
    </ToastProvider>
  );
}

export default Main;
