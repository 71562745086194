import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';
import { getFirstPartPV } from '../../utils/parser';

const titleCase = str => { 
    str = str.toLowerCase();
    str = str.charAt(0).toUpperCase() + str.slice(1); 
    str = str.replace(/(<([^>]+)>)/gi, "");
    return str;
}

const emptyHeadings = (navs) => {
    return (navs.length > 0) ? false: true;
}

const Navigator = props => {
    let navs = [];
    if (!props.isLoading) {
        props.content.topics.forEach((topic, index) => {
            topic.sentences.forEach((sentence, index) => {
                let className = '';
                let heading = true;
                switch (sentence.level){
                    case 'H1': 
                        className = '';
                        break;
                    case 'H2': 
                        className = 'nav-heading-2';
                        break;
                    case 'H3': 
                        className = 'nav-heading-3';
                        break;
                    case 'H4': 
                        className = 'nav-heading-4';
                        break;
                    case 'H5': 
                        className = 'nav-heading-5';
                        break;
                    default:
                        className = 'paragraph';
                        heading = false;
                        break;
                }
                if (heading){
                    const content = (<li className={className}><a href={'#'+sentence._id}>{(className === '') ? '•': '◦'} {titleCase(getFirstPartPV(sentence))}</a></li>);
                    navs.push(content);
                }
            });
        });
        if (!emptyHeadings(navs))
        return (
            <>
                <nav className="section-nav">
                    <ol>
                        {navs}
                    </ol>
                </nav>
                <Helmet>
                    <script src= "/navigator-javascript.js" type = "text/javascript" />
                </Helmet>
            </>
        ); 
        else return (<></>);
    }else return (<></>)
}

Navigator.propTypes = {
    content: PropTypes.object
  };

Navigator.defaultProps = {
    content: {}
  };

export default Navigator;
