import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import AccordionCustom from './accordion-custom';
import { getFirstPartPV, getSecondPartPV } from '../../utils/parser';

const ArticleAccordionSentence = props => {
  const [showExpanded, setShowExpanded] = useState(false);
  let structure = [];
  if (props.articleHighlights && props.articleHighlights.length > 0 && !showExpanded){
    props.topic.sentences.forEach((sentence, index) => {
      props.articleHighlights.forEach((highlight, index) => {
        if (highlight.id === sentence._id){
          setShowExpanded(true);
        }
      });
    });
  }
  props.topic.sentences.forEach((sentence, index) => {
    const accordion = (
      <AccordionCustom
        sentenceIndex={index}
        id = {sentence._id}
        level = {sentence.level}
        dataParentId = {sentence.dataParentId}
        title={getFirstPartPV(sentence)}
        content={getSecondPartPV(sentence)}
        showExpanded={showExpanded}
        articleHighlights={props.articleHighlights}
      />
    );
    structure.push(accordion);
  });

  return <div className={`accordion-parent ${showExpanded ? "expanded" : ""}`} >{structure}</div>;
};

ArticleAccordionSentence.propTypes = {
  isOwnerArticle: PropTypes.bool,
  topic: PropTypes.object
};

ArticleAccordionSentence.defaultProps = {
  topic: {}
};

export default ArticleAccordionSentence;
