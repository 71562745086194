import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Spinner, Alert } from 'react-bootstrap';

import './styles.css';

const Login = props => {
  const { userId , activationCode } = useParams();
  const { loginFetchStart, activateFetchStart, loginStatus, isLoading } = props;
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = event => {
    setValidated(false);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) setValidated(true);
    else {
      loginFetchStart({ email: email, password: password });
    }
  };

  if (userId && activationCode){
    activateFetchStart({userId: userId, activationCode: activationCode});
  }

  useEffect(() => {
    if (loginStatus) {
      history.push('/articles');
    }
  }, [loginStatus, history]);

  return (
    <Col>
      <Row>
        <Col className="auth-header">
          <img alt="logo" src="./logo.png" />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="auth-body">
          <Card>
            <Card.Header>
              <strong>Login</strong>
            </Card.Header>
            <Card.Body>
              {loginStatus === false && <Alert variant="danger">Email or password invalid.</Alert>}
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    required
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    You must enter your email.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    required
                    minLength={6}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    You must enter your password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicLabel">
                  <Form.Label>
                    Are you new in the platform? Click <Link to={'/register'}>here</Link> to
                    register.
                  </Form.Label>
                </Form.Group>
                <Button variant="primary" block type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        className="login-spinner"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      <span>Validating...</span>
                    </>
                  ) : (
                    'Login'
                  )}
                </Button>
                <Form.Group controlId="formBasicLabel" className="forgot-label">
                  <Form.Label>
                    Forgot your password? Click <Link to={'/send-reset-password'}>here</Link> to reset.
                  </Form.Label>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

Login.propTypes = {
  isLoading: PropTypes.bool,
  loginSuccess: PropTypes.bool,
  loginFetchStart: PropTypes.func
};

export default Login;
