import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ArticleCategories from './article-categories';

import { articleListFetchStart } from '../../actions/article';
import { categoryListFetchStart, categorySaveSelected } from '../../actions/category';

const mapStateToProps = state => ({
  articles: state.articleList.data,
  articlesPagination: state.articleList.pagination,
  sources: state.articleList.sources,
  currentSource: state.articleList.currentSource,
  categories: state.categoriesList.data,
  terms: state.termsList.data,
  selectedFilterInputs: state.selectedFilter.inputs,
  selectedFilterTerms: state.selectedFilter.terms,
  selectedCategory: state.selectedCategory.data,
  selectedTerms: state.selectedTerms.data,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { articleListFetchStart, categoryListFetchStart, categorySaveSelected },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategories);
