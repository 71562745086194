import { CATEGORY_SAVE_SELECTED } from '../../actions/category';

export const initialState = {
  data: 'News',
};

export const categorySelectedReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_SAVE_SELECTED:
      return { ...state, data: payload.selectedCategory };
    default:
      return state;
  }
};
