import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, ButtonGroup, Button, Badge, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { articleConstant } from '../../constants/article';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SaveArticle from './save-article';

const ArticleBrowser = props => {
  const {
    switchView,
    filteredTopic,
    filteredCustomTopic,
    hasCustomSearchResult,
    viewType,
    matchedTerms,
    matchedMetrics
  } = props;

  const termsModal  = useSelector(state => state.termsModal.data);
  const [selectedTerms, setVariantSelected] = useState(props.selectedTerms);
  const [showMessage, setCleanCustomSearchMessage] = useState(true);
  const [othersTerms, setOtherVariant] = useState(props.otherTerms);
  const [customSearchInputValue, setCustomSearchInputValue] = useState({});
  const [textView, setTextView] = useState((viewType === articleConstant.fullText) ? true : false);
  useEffect(() => {
    if (hasCustomSearchResult !== null) {
      setCleanCustomSearchMessage(hasCustomSearchResult);
    }
  }, [hasCustomSearchResult]);

  const onFilteredTopics = (term, type) => {
    setTextView(false);
    //actualizamos el estado de los items buscando el que ha sido clickeado
    if (type === 'selected' || type === 'metric') {
      setVariantSelected(
        selectedTerms.map(item => {
          let related_terms = item.related_terms.map((related, index) => {
            return (related.core === term.core ? { ...related, selected: !related.selected } : related);
          });
          let calculated_terms = item.calculated_terms.map(item => {
            return (item.core === term.core ? { ...item, selected: !item.selected} : item)
          });
          return (item.core === term.core ? { ...item, selected: !item.selected, related_terms: related_terms, calculated_terms: calculated_terms } : {...item, related_terms: related_terms, calculated_terms: calculated_terms});
      }));
    }
    if (type === 'others' || type === 'metric') {
      setOtherVariant(
        othersTerms.map(item => {
          let related_terms = item.related_terms.map((related, index) => {
            return (related.core === term.core ? { ...related, selected: !related.selected } : related);
          });
          let calculated_terms = item.calculated_terms.map(item => {
            return (item.core === term.core ? { ...item, selected: !item.selected} : item)
          });
          return (item.core === term.core ? { ...item, selected: !item.selected, related_terms: related_terms, calculated_terms: calculated_terms } : {...item, related_terms: related_terms, calculated_terms: calculated_terms})
      }));
    }
    filteredTopic(articleConstant.filteredTopic, term);
  };

  const termChecked = () => {
    let anyChecked = false;
    selectedTerms.forEach(item => {
      if (item.selected) anyChecked = true;
    });
    othersTerms.forEach(item => {
      if (item.selected) anyChecked = true;
    });
    return anyChecked;
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.target.value !== '') {
      filteredCustomTopic(articleConstant.filteredTopic, customSearchInputValue);
    }
  };

  const updateValue = e => {
    setCustomSearchInputValue({ core: e.target.value, custom: true });
  };

  const resetCustomSearch = () => {
    setCustomSearchInputValue({});
    setCleanCustomSearchMessage(true);
    if (viewType !== articleConstant.fullText) {
      filteredCustomTopic(articleConstant.filteredTopic, null);
    }
  };

  const getCountTerm = (term) => {
    let count = 0;
    if (matchedTerms){
      matchedTerms.map((match , index) =>{
        if (match.term === term.core){
          count = match.count;
        }
        return true;
      });
    }
    return count;
  }

  const getCountMetricTerm = (metric) => {
    let count = 0;
    if (matchedMetrics){
      matchedMetrics.map((match , index) =>{
        if (match.term === metric.core){
          count = match.count;
        }
        return true;
      });
    }
    return count;
  }

  const buildCoreTerm = (term, index, type) => {
    let name = '';
    switch (term.type) {
        case 'company':
          name = 'warning';break;
        case 'metric':
          name = 'success';break;
        case 'term':
          name = 'info';break;
        default:
          name = 'info';
    }
   return ( <> {
      term.found && (
        <Badge
          variant={term.selected ? 'primary' : name}
          key={index}
          onClick={() => onFilteredTopics(term, type)}
        >
        {term.core} ({(term.type === 'metric' ) ? getCountMetricTerm(term) : getCountTerm(term)})
        </Badge>
      )
    }
    {
      !term.found && (
        <Badge
          variant={'dark'}
          key={index}
        >
        {term.core}
        </Badge>
      )
    } </>
   );
  }

  const buildMySearchList = (terms) => {
    let structure = [];
    if (terms) {
      structure = terms.map((term, index) => {
        return (
          <>
          {buildCoreTerm(term, index, 'selected')}
          </>
        );
      });
      return structure;
    }
  };

  const getShortNameMetric = (metric) => {
    let terms = termsModal.metrics.items;
    terms.forEach((term, value) => {
      if (term.name === metric && term.alternative_names !== ''){
        let alternative = term.alternative_names.split(',');
        metric = alternative[0];
      }
    });
    return metric;
  }

  const buildTermsInArticleList = (terms, type) => {
    let structure = [];
    if (terms) {
      structure = terms.map((term, index) => {
        const str = term.core;
        const str2 = str.charAt(0).toUpperCase() + str.slice(1);
        return (term.type !== 'metric' && (term.related_terms.length > 0)) ? (
          <>
          <div style={{'fontSize': '85%', 'fontWeight': 'bold'}}>{str2+': '}</div>
          <div style={{'paddingLeft': '10px'}}>
          {(term.type !=='company' && term.type !=='term' && term.found) ? buildCoreTerm(term, index, type): ''}
          {(term.related_terms) ? term.related_terms.map((related, index) =>(
              <Badge
                variant={related.selected ? 'primary' : 'info'}
                key={index}
                onClick={() => onFilteredTopics(related, type)}
              >{related.core} ({getCountTerm(related)})</Badge>
            )): null}
          </div>
          </>
        ): (<></>);
      });
      return structure;
    }
  };

  const buildTermsMetricsList = (terms) => {
    let structure = [];
    if (terms) {
      structure = terms.map((term, index) => {
        const str = term.core;
        const str2 = str.charAt(0).toUpperCase() + str.slice(1);
        return (term.calculated_terms && term.calculated_terms.length > 0) ? (
          <>
          <div style={{'fontSize': '85%', 'fontWeight': 'bold'}}>{str2+': '}</div>
          <div style={{'paddingLeft': '10px'}}>{(term.calculated_terms) ? term.calculated_terms.map((calculated, index) =>{
              return (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">{calculated.core}</Tooltip>}
              >
              <Badge
                variant={calculated.selected ? 'primary' : 'success'}
                key={index}
                onClick={() => onFilteredTopics(calculated, 'metric')}
              >{getShortNameMetric(calculated.core)} ({getCountMetricTerm(calculated)})</Badge>
              </OverlayTrigger>
            ) }): null}
          </div>
          </>
        ) : (<></>);
      });
      return structure;
    }
  }
  const hasMetrics = (terms) => {
    let found = false;
    if (terms){
      terms.map((term, index) => {
        if (term.calculated_terms && term.calculated_terms.length > 0 ){
          found = true;
        }
        return true;
      });
    }
    return found;
  }
  let selectedTermsList = buildMySearchList(selectedTerms);
  let othersTermsList = buildTermsInArticleList(othersTerms, 'others');
  let relatedTermsList = buildTermsInArticleList(selectedTerms, 'selected');
  let selectedMetricsList = buildTermsMetricsList(selectedTerms);
  let othersMetricsList = buildTermsMetricsList(othersTerms);
  return (
    <Card className="article-browser-card">
      <Card.Header>
        <img alt="logo" className="logo" src={window.location.origin+'/logo.png'} />
        <SaveArticle />
      </Card.Header>
      <Card.Body className="article-browser">
        <Row>
          <Col>
            <ButtonGroup size="sm" aria-label={articleConstant.menu}>
              <Button
                onClick={() => {
                  setTextView(true);
                  switchView(articleConstant.fullText);
                }}
                variant={textView ? 'primary' : 'secondary'}
              >
                Text View
              </Button>
              {!termChecked() && (
                <Button
                  onClick={() => {
                    setTextView(false);
                    switchView(articleConstant.fullTopic);
                  }}
                  variant={!textView ? 'primary' : 'secondary'}
                >
                  Topic View
                </Button>
                )
              }
              {termChecked() && (
                <Button
                  onClick={() => {
                    setTextView(false);
                    switchView(articleConstant.filteredTopic);
                  }}
                  variant={!textView ? 'primary' : 'secondary'}
                >
                  Topic View
                </Button>
                )
              }
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <br></br>
            <div className="browser-custom-search">
              <Form.Control
                type="text"
                value={customSearchInputValue.core || ''}
                placeholder="Type your search"
                onKeyDown={handleKeyDown}
                onChange={updateValue}
              />
              <Button variant="danger" className="btn-margin" onClick={resetCustomSearch}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            {!showMessage && (
              <div className="browser-custom-search-message">
                This term is not present in this article and no changes on the article view
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="browser-content">
            {selectedTermsList.length > 0 && (
              <>
              <fieldset className="border p-2">
                <legend className="w-auto">My search</legend>
                <div>{selectedTermsList}</div>
              </fieldset>
              <br></br>
              </>
            )}
            {othersTermsList.length > 0 && (
              <fieldset className="border p-2">
                <legend className="w-auto">You may be interested in</legend>
                <div>{relatedTermsList}{othersTermsList}</div>
                {(hasMetrics(selectedTerms) || hasMetrics(othersTerms) ) && (
                  <div>
                    <span style={{'fontSize': '14px'}}><b>Metrics</b></span><br></br>
                    {selectedMetricsList}{othersMetricsList}
                  </div>
                )}
              </fieldset>
            )}

          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>

      </Card.Footer>
    </Card>
  );
};

ArticleBrowser.propTypes = {
  viewType: PropTypes.string,
  switchView: PropTypes.func,
  hasFilterResults: PropTypes.bool,
  hasCustomSearchResult: PropTypes.bool,
  filteredTopic: PropTypes.func,
  filteredCustomTopic: PropTypes.func,
  selectedTerms: PropTypes.array,
  otherTerms: PropTypes.array
};

ArticleBrowser.defaultProps = {
  customSearchInputValue: '',
  hasFilterResults: false,
  hasCustomSearchResult: true
};

export default ArticleBrowser;
