import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editUserFetchStart } from '../../actions/user';
import { loginChangeStatus } from '../../actions/auth';

import Header from './header';

const mapStateToProps = state => ({
  isLoadingEditUser: state.editUser.isLoading,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      editUserFetchStart,
      loginChangeStatus
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
