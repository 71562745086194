import { call, put, select } from 'redux-saga/effects';
import { termListFetchSuccess,termSaveSelected, termsArticleListFetchSuccess, termsModalListFetchSuccess, termTypeFetchSuccess, termSetTypeRefresh } from '../actions/term';
import { articleListFetchStart } from '../actions/article';
import { termService } from '../services/term';
import { cleanCurrentSavedSearch } from '../actions/search';
import { historySaveFetchStart } from '../actions/history';
import { filterInputsSaveSelected } from '../actions/menu';

export function* getTerms(action) {
  const response = yield call(termService.getTerms, action);
  let auxResponse = response.map((item, index) => {
    return item;
  })
  yield put(termListFetchSuccess(auxResponse));
}

export function* getTermsType(action) {
  const response = yield call(termService.getTermType, { term: action.payload.term });
  yield put(termTypeFetchSuccess(response));
}

export function* getTermsTypeAndSearch(action) {
  if (action.payload.term !==''){
    const response = yield call(termService.getTermType, { term: action.payload.term });
    yield put(termTypeFetchSuccess(response));
  }
  const getTermList = (state) => state.termsList.data;
  const termList = yield select(getTermList);
  const selectedTerms = termList.filter(term => term.selected);
  action.payload.filters.terms = selectedTerms;
  const payload = {
    date: new Date(),
    filters: action.payload.filters,
  };
  yield put(cleanCurrentSavedSearch());
  yield put(termSetTypeRefresh(''));
  yield put(termSaveSelected(selectedTerms, 'data'));
  yield put(filterInputsSaveSelected(action.payload.filters.filterInputs));
  yield put(historySaveFetchStart(payload));
  yield put(articleListFetchStart(action.payload.filters));
}

export function* getTermsOnArticle(action) {
  const response = yield call(termService.getTermsOnArticle, action);
  yield put(termsArticleListFetchSuccess(response));
}

export function* getTermsOnModal(action) {
  const response = yield call(termService.getTermsOnModal, action);
  yield put(termsModalListFetchSuccess(response));
}