export const TERM_LIST_FETCH_START = '[term] list term fetch start';
export const TERM_LIST_FETCH_SUCCESS = '[term] list term fetch success';
export const TERM_LIST_FETCH_ERROR = '[term] list term fetch error';
export const TERM_SAVE_SELECTED = '[term] save selected terms ';
export const TERM_REMOVE_SELECTED = '[term] remove selected term';
export const TERM_ARTICLE_LIST_FETCH_START = '[term] term article fetch start';
export const TERM_ARTICLE_LIST_FETCH_SUCCESS = '[term] term article fetch success';
export const TERM_ARTICLE_LIST_FETCH_ERROR = '[term] term article fetch error';
export const TERM_MODAL_LIST_FETCH_START = '[term] term modal fetch start';
export const TERM_MODAL_LIST_FETCH_SUCCESS = '[term] term modal fetch success';
export const TERM_MODAL_LIST_FETCH_ERROR = '[term] term modal fetch error';
export const TERM_CUSTOM_ADDED = '[term] term custom added';
export const TERM_GET_TYPE = '[term] term get type';
export const TERM_TYPE_FETCH_SUCCESS = '[term] term type fetch success';
export const TERM_CUSTOM_ADDED_CHANGE = '[term] term custom added change';
export const TERM_REFRESH_TYPE = '[term] term set refresh type';
export const TERM_LIST_CLEAN = '[term] term list clean';
export const TERM_TYPE_FETCH_SUCCESS_AND_SEARCH = '[term] term type fetch success and search';

export const termListFetchStart = () => ({
  type: TERM_LIST_FETCH_START,
  payload: null,
});

export const termListFetchSuccess = terms => ({
  type: TERM_LIST_FETCH_SUCCESS,
  payload: { terms },
});

export const termListFetchError = error => ({
  type: TERM_LIST_FETCH_ERROR,
  payload: { error },
});

export const termListClean = () => (
{
  type: TERM_LIST_CLEAN,
  payload: null
}
);

export const termSaveSelected = (selectedTerm, cache) => ({
  type: TERM_SAVE_SELECTED,
  payload: { terms: selectedTerm, cache: cache }
});

export const termsArticleListFetchStart = terms => ({
  type: TERM_ARTICLE_LIST_FETCH_START,
  payload: terms,
});

export const termsArticleListFetchSuccess = terms => ({
  type: TERM_ARTICLE_LIST_FETCH_SUCCESS,
  payload: { terms },
});

export const termsArticleListFetchError = error => ({
  type: TERM_ARTICLE_LIST_FETCH_ERROR,
  payload: { error },
});

export const termsModalListFetchStart = () => ({
  type: TERM_MODAL_LIST_FETCH_START,
  payload: null,
});

export const termsModalListFetchSuccess = terms => ({
  type: TERM_MODAL_LIST_FETCH_SUCCESS,
  payload: { terms },
});

export const termsModalListFetchError = error => ({
  type: TERM_MODAL_LIST_FETCH_ERROR,
  payload: { error },
});

export const termsCustomAdded = term => ({
  type: TERM_CUSTOM_ADDED,
  payload: { term },
});

export const termsGetType = term => ({
  type: TERM_GET_TYPE,
  payload: { term },
});

export const termTypeFetchSuccess = term => ({
  type: TERM_TYPE_FETCH_SUCCESS,
  payload: { term },

});

export const termTypeFetchSuccessAndSearch = filter => ({
  type: TERM_TYPE_FETCH_SUCCESS_AND_SEARCH,
  payload: { term: filter.term, filters: filter.filters },

});

export const termSetTypeRefresh = type => ({
  type: TERM_REFRESH_TYPE,
  payload: { type },
});
