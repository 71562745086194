import HttpService from './http';
import config from '../config';

export class TemplateService {
  async getTemplates() {
    const path = config.api.endpoints.getTemplates.replace(':id', (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).id : null);
    return HttpService.get(path);
  }

  async saveTemplate(payload) {
    payload.user = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).id : null;
    const path = config.api.endpoints.saveTemplate;
    return HttpService.post(path, payload);
  }

  async deleteTemplate(payload) {
    const path = config.api.endpoints.deleteTemplate.replace(':id', payload._id);
    return HttpService.delete(path, payload);
  }

  async copyTemplate(payload) {
    //payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.getTemplate;
    return HttpService.post(path, payload);
  }

  async editTemplate(payload) {
    const path = config.api.endpoints.editTemplate.replace(':id', payload._id);
    return HttpService.update(path, payload);
  }
}

export const templateService = new TemplateService();
