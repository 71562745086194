
import { Modal, Button } from 'react-bootstrap';
import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import 'antd/dist/antd.css';
import { TreeSelect } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { searchFolderEmailStart } from '../../actions/search-folder';
import { templateSaveFetchStart, templateEditFetchStart } from '../../actions/template';
import TemplateEmailPreview from './template-email-preview';
import './styles.css';

const TemplateEmail = (props) => {
  const { openModal, handleOpenModal, currentTemplate } = props;
  const dispatch = useDispatch();
  const { SHOW_PARENT } = TreeSelect;
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [preview, setPreview] = useState(false);
  const [draftEmail, setDraftEmail] = useState(null);
  const [favoriteTree, setFavoriteTree] = useState(null);
  const [checkedArticles, setCheckedArticles] = useState([]);
  const sendEmailTemplateResponse = useSelector(state => state.templateFolderEmail.data);
  const searchSaveList = useSelector(state =>  state.searchList.data);
  const folders = useSelector(state => state.searchFolderList.data);
  const { addToast } = useToasts();
  const handleClose = () => handleOpenModal(false);
  useEffect(() => {
      if (sendEmailTemplateResponse && sendEmailTemplateResponse.message) {
        addToast(sendEmailTemplateResponse.message, {
          appearance: sendEmailTemplateResponse.type,
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        //dispatch(sendEmailFavoriteCleanMessage())
      }
  }, [dispatch, sendEmailTemplateResponse, addToast]);

  useEffect(()=> {
      setName((currentTemplate) ? currentTemplate.name : '');
      setEmail((currentTemplate) ? currentTemplate.mailTo: '');
      setSubject((currentTemplate) ? currentTemplate.subject : '');
      setValue((currentTemplate) ? currentTemplate.content : null);
      setCheckedArticles((currentTemplate) ? currentTemplate.searches : []);
      setFavoriteTree(getFavoriteTree(searchSaveList));
  // eslint-disable-next-line
  },[currentTemplate]);

  const modules = {
  toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
  ],
  };

  const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
  ];

  const checkSelectedArticle = (item) => {
      let find = false;
      if (currentTemplate && currentTemplate.searches ){
          currentTemplate.searches.forEach((search, index)=>{
              if (search === item._id) {
                  find = true;
              }
          });
      }
      return find
  }

  const getFavoriteTree = (searchSaveList) => {
      const data = [];
      if (searchSaveList && folders){
          folders.forEach((folder, index) => {
              const articles = [];
              searchSaveList.forEach((item, index) => {
                  let selectedArticle = checkSelectedArticle(item);
                  if (folder._id === item.searchFolder)
                      articles.push({ label: item.name, value: item._id, checked: selectedArticle });
              });
              data.push({ label: folder.name, value: folder._id, children: articles});
          });

      }
      return data;
  }

  const updateFavoriteTree = (list) => {
      const data = [];
      if (searchSaveList && folders){
          folders.forEach((folder, index) =>{
              const articles = [];
              searchSaveList.forEach((item, index) =>{
                  if (folder._id === item.searchFolder)
                      articles.push({ title: item.name, value: item._id, key: item._id});
              });
              data.push({ title: folder.name, value: folder._id, children: articles, key: folder._id});
          });

      }
      return data;
  }

  const saveTemplateEmail = () => {
      saveTemplate();
      handleOpenModal(false);
      addToast('Template saved!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
  }

  const saveTemplate = () => {
      const payload = {
          name: name,
          mailTo: email,
          subject: subject,
          content: value,
          searches: checkedArticles,
          templateFolder: props.currentFolder
      }
      if (currentTemplate && currentTemplate._id){
          dispatch(templateEditFetchStart({ ...payload, _id: currentTemplate._id }));
      }else{
          dispatch(templateSaveFetchStart(payload));
      }
  }

  const getSearches = () => {
      const items = [];
      searchSaveList.forEach((item, index) =>{
          if (checkedArticles.includes(item._id) || checkedArticles.includes(item.searchFolder)){
              items.push(item);
          }
      });
      return items;
  }

  const previewEmail = () => {
      const values = {
          link: window.location.origin+'/article/share-folder/',
          body: value,
          items: getSearches(),
          email: email,
          subject: subject
      };
      saveTemplate();
      setPreview(true);
      setDraftEmail(values);
  };

  const goBackDraft = () => {
      setPreview(false);
  }

  const sendFavoriteListEmail = () => {
      dispatch(searchFolderEmailStart(draftEmail));
      handleOpenModal(false);
      addToast('Mail sent!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000,
      });
  }

  const onChange = (selectedNodes) => {
      setCheckedArticles(selectedNodes);
      setFavoriteTree(updateFavoriteTree(selectedNodes));
  };

  return (
      <>
          <Button
          variant="primary"
          className="pull-right btn-margin"
          onClick={() => {

              handleOpenModal(true)
          }}
          >
          + Template
          </Button>
          <Modal dialogClassName="modal-md modal-preview-template" show={openModal} onHide={handleClose}>
          <Modal.Header closeButton>
          <Modal.Title>
              New Template
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {!preview && (
          <>
              <label>Name</label>
              <input
                  className="form-control"
                  name="name"
                  placeholder="Template name"
                  defaultValue={name}
                  onChange={(evt) => setName(evt.target.value)}
              />
              <label>E-mail</label>
              <input
                  className="form-control"
                  name="email"
                  placeholder="Mail To"
                  defaultValue={email}
                  onChange={(evt) => setEmail(evt.target.value)}
              />
              <label>Subject</label>
              <input
                  className="form-control"
                  name="subject"
                  placeholder="Subject"
                  defaultValue={subject}
                  onChange={(evt) => setSubject(evt.target.value)}
              />
              <br/>
              <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={(value) => setValue(value)}
                  modules={modules}
                  formats={formats}
                  >
              </ReactQuill><br/>
              <label>Articles</label>
              <TreeSelect
                  treeData={favoriteTree}
                  value={checkedArticles}
                  onChange={onChange}
                  treeCheckable = {true}
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder={'Please select'}
                  style= {{
                    width: '100%',
                  }}
                  className="bootstrap-demo"
              />
          </>
          )}
          {preview && <TemplateEmailPreview draftEmail ={draftEmail} goBackDraft ={goBackDraft} sendFavoriteListEmail={sendFavoriteListEmail} />}
          </Modal.Body>
          {!preview &&
              <Modal.Footer>
                  <Button variant="primary" type="button" onClick={() => saveTemplateEmail()}>
                      Save & close
                  </Button>&nbsp;
                  <Button variant="primary" type="button" onClick={() => previewEmail()}>
                      Save & Preview
                  </Button>
              </Modal.Footer>
          }
          {preview &&
              <Modal.Footer>
                  <Button
                      variant="primary"
                      className="pull-right btn-margin"
                      onClick={() => goBackDraft()}
                      >
                      Go Back Draft
                  </Button>&nbsp;
                  <Button
                      variant="primary"
                      className="pull-left btn-margin"
                      onClick={() => sendFavoriteListEmail()}
                  >
                          Send E-mail
                  </Button>
              </Modal.Footer>
          }
          </Modal>
      </>
  );
}
export default TemplateEmail;
