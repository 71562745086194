export const EDIT_USER_FETCH_START = '[user] edit fetch start';
export const EDIT_USER_FETCH_SUCCESS = '[user] edit fetch success';
export const EDIT_USER_FETCH_ERROR = '[user] edit fetch error';

export const editUserFetchStart = param => ({
  type: EDIT_USER_FETCH_START,
  payload: param,
});

export const editUserFetchSuccess = result => ({
  type: EDIT_USER_FETCH_SUCCESS,
  payload: result,
});

export const editUserFetchError = error => ({
  type: EDIT_USER_FETCH_ERROR,
  payload: error,
});
