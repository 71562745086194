
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu} from 'antd';
import { Button, Form, Card } from 'react-bootstrap';
import FolderItem from './folder-item';
import {
    FolderOutlined, FolderOpenOutlined
  } from '@ant-design/icons';
import {
  searchFolderSaveFetchStart,
  searchFolderEditFetchStart,
  searchFolderListFetchStart,
  searchFolderDeleteFetchStart, 
} from '../../actions/search-folder';
import './styles.css';

export default function FavoriteSidebar (props) {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');

    useEffect( () => {
        dispatch(searchFolderListFetchStart());
    },[dispatch]);
    const folders = useSelector(state => state.searchFolderList.data);

    const deleteFolder = folder => {
      dispatch(searchFolderDeleteFetchStart(folder));
    };

    const editFolder = folder => {
      dispatch(searchFolderEditFetchStart(folder));
    };

    const handleSubmit = event => {
        setValidated(false);
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) setValidated(true);
        dispatch(searchFolderSaveFetchStart({ name: name }));
        setName('');
     };
    const handleClick = (e) => {
      props.handleCurrentFolder(e.key);
    }
    const renderList = () => {
        let items = '';
        if (folders){
            items = folders.map((folder, index) => {
                let icon = (folder._id === props.currentFolder) ? (<FolderOpenOutlined />) : (<FolderOutlined/>);
                return (<Menu.Item key={folder._id} icon={icon}>
                            <FolderItem
                              folder = {folder}
                              index={index}
                              key={index}
                              confirmDeleteAction={deleteFolder}
                              searchFolderEditFetchStart={editFolder}
                            >
                            </FolderItem>
                        </Menu.Item>)
            });
        }
        return (<>{items}</>
      );
    }
    return (
        <Card>
          <Card.Body className="folder-sidebar">
            <h4>Folders</h4>  
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Form.Group controlId="formGroupPassword">
                  <Form.Control
                    placeholder="Enter folder name"
                    type="text"
                    defaultValue={name}
                    aria-describedby="inputGroupPrepend"
                    onChange={e => setName(e.target.value)}
                    required
                  />
                  <Button variant="primary" type="submit">
                    Add Folder
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    Name folder empty.
                  </Form.Control.Feedback>
                  
                </Form.Group>
                
            </Form>
            <Card>
              <Card.Body>
                <Menu 
                  onClick={handleClick} 
                  style={{ width: 256 }}
                  defaultSelectedKeys={['1']}
                  mode={'inline'}
                  theme={'light'}
                >
                  {renderList()}
                </Menu>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      );
}