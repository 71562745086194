import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Badge ,Tabs, Tab, Collapse } from 'react-bootstrap';
import './../styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { termsModalListFetchStart, termsGetType } from '../../../actions/term';
import { countries } from '../../../constants/countries';
import clone from 'clone';

const MenuTermModal = props => {  
  const {
    termsModal,
    termsModalListFetchStart,
    termsGetType,
  } = props;
  const [openCompanies, setOpenCompanies] = useState([]);
  const [openMetrics, setOpenMetrics] = useState([]);
  const [currentTab, setCurrentTab] = useState('companies');
  const [terms, setTerms ] = useState(null);
  const scrollToRef = (ref) => {
    let myDiv = document.getElementsByClassName('modal');
    console.log(myDiv);
    myDiv[0].scrollTop = 0;
  };
  const myRef = React.useRef(null)
  const executeScroll = () => scrollToRef(myRef);

  useEffect(() => {
    termsModalListFetchStart();
  }, [termsModalListFetchStart]);

  const genCharArray = (charA, charZ) => {
    let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
        a.push(String.fromCharCode(i));
    }
    return a;
  }

  const resetItems = () => {
    let auxTerms = [];
    auxTerms = terms.metrics.items.map((i, index) =>{ 
      i.selected = false;
      return i;
    });
    terms.metrics.items = auxTerms;
    auxTerms = terms.companies.items.map((i, index) =>{ 
      i.selected = false;
      return i;
    });
    terms.companies.items = auxTerms;
    setTerms({...terms});
  }

  const updateItem = (item, type) => {
    let termsAux = clone(terms);
    let termList = (type === 'metrics') ? termsAux.metrics : termsAux.companies;
    termList = termList.items.map((i, index) =>{ 
      if (i.id === item.id){
        i.selected = true;
      }
      return i;
    });
    if (type === 'metrics'){
      termsAux.metrics.items = termList;
    }else if (type === 'companies'){
      termsAux.companies.items = termList;
    }
    setTerms(termsAux);
  }

  const printGlosary = (type) => {
    //setCurrentTab(type);
    let charA = genCharArray('A', 'Z');
    let buttons = charA.map((c , i ) =>{
      let collapseName = 'collapse-text-'+i;
      return (
        <Button
          onClick={() => { let auxOpen = clone((type ==='companies') ? openCompanies: openMetrics); for (let aux = 0; aux < auxOpen.length; aux++ ) if (aux !== i) auxOpen[aux]=0; auxOpen[i] = !auxOpen[i]; return (type ==='companies') ? setOpenCompanies(auxOpen): setOpenMetrics(auxOpen);}}
          aria-controls={collapseName}
          aria-expanded={(type ==='companies') ? openCompanies[i]: openMetrics[i]}
        >
          {c.toUpperCase()}
        </Button>
      );
    });
    let collapses = charA.map((c , i ) =>{
      let collapseName = 'collapse-text-'+i;
      return (
        <Collapse in={(type ==='companies') ? openCompanies[i]: openMetrics[i]}>
          <div id={collapseName}>
            <Row>{getTermsList(type,c.toUpperCase())}</Row>
          </div>
        </Collapse>
      );
    });
    return (<>{buttons} {collapses}</>);
    
  }

  const getTermsList = (type, c) => {
    if (!terms) return;
    let termList = {};
    switch (type){
      case 'metrics': termList = terms.metrics; break;
      case 'companies': termList = terms.companies; break;
      case 'countries': termList.items = countries; break;
      default: break;
    }
    let viewTermList = termList.items.filter((value, index)=> { return (value.name.toUpperCase().charAt(0) === c) ? true : false }).map((item, index) =>{
      let variant = 'main-terms'
      if (item.selected){
        variant = 'info';
      }
      return (
        <Badge
          className="badge-modal-search"
          variant={variant}
          key={index}
          onClick={() => {termsGetType(item.name); updateItem(item, type)}}
        >
          {item.name}
        </Badge>
      );
    });
    let count = viewTermList.length;
    let columnsList = [];
    if (count > 0) {
      let j = 0;
      let modCount = Math.trunc( count / 5);;
      for (let i = 0; i < 5; i++ ){
        columnsList[i] = [];
        while (j < viewTermList.length )
        {
          columnsList[i].push(viewTermList[j]);
          j++;
          if ( j % modCount*(i+1) === 0){
            break;
          }
          
        }
        
      }
    }
    return columnsList.map((column, index) =>{
      return (<div className="term-list">{column}</div>)
    })
  }
  if (termsModal && !terms){
    setTerms(termsModal);
  }

  return (
    <Modal
        show={props.isOpenModal}
        onHide={()=> {props.onCloseModal(false); resetItems() }}
        dialogClassName="modal-xl menu-modal"
        aria-labelledby="menu-modal"
      >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="tabs-more">
              <div className="ref" ref={myRef}></div> 
                <Tabs defaultActiveKey={currentTab}
                      activeKey={currentTab}
                      onSelect={(k) => setCurrentTab(k)}
                >
                  <Tab eventKey="companies" title="Companies">
                    <Row><Col><br></br>{printGlosary('companies')}</Col></Row>
                  </Tab>
                  <Tab eventKey="metrics" title="Metrics">
                    <Row><Col><br></br>{printGlosary('metrics')}</Col></Row>
                  </Tab>
                </Tabs>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=> executeScroll()}>Back To Top</Button>
      </Modal.Footer>
    </Modal> 
  );
};

MenuTermModal.propTypes = {
  isOpenModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onOpenModal: PropTypes.func,
  termsModalListFetchStart: PropTypes.func,
};

MenuTermModal.defaultProps = {
};

const mapStateToProps = state => ({
  termsModal: state.termsModal.data,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ termsModalListFetchStart, termsGetType }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuTermModal);
