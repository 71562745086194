
import { Modal, Button } from 'react-bootstrap';
import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { searchFolderEmailStart } from '../../actions/search-folder';
import FavoriteEmailPreview from './favorite-email-preview';

const FavoriteEmail = (props) => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const { items } = props;
    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [preview, setPreview] = useState(false);
    const [draftEmail, setDraftEmail] = useState(null);
    const sendEmailFavoriteResponse = useSelector(state => state.searchFolderEmail.data);
    const { addToast } = useToasts();
    const handleClose = () => setOpenModal(false);
    useEffect(() => {
        if (sendEmailFavoriteResponse && sendEmailFavoriteResponse.message) {
          addToast(sendEmailFavoriteResponse.message, {
            appearance: sendEmailFavoriteResponse.type,
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          //dispatch(sendEmailFavoriteCleanMessage())
        }
      }, [dispatch, sendEmailFavoriteResponse, addToast]);
    
    const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ],
    };

    const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
    ];

    const previewEmail = () => {
        const values = {
            link: window.location.origin+'/article/share-folder/',
            body: value,
            items: items,
            email: email,
            subject: subject
        };
        setPreview(true);
        setDraftEmail(values);
    };

    const goBackDraft = () => {
        setPreview(false);
    }

    const sendFavoriteListEmail = () => { 
        dispatch(searchFolderEmailStart(draftEmail));
        setOpenModal(false);
        addToast('Mail sent!', {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
    }
    const renderList = () => {
        if (items) {
          return items.map((item, index) => {
            return (
            <div key={index} className="article-list-item">
                <span className="article-access-item" role="button" aria-hidden="true" >
                {(item.value.filters.filterInputs && item.value.filters.filterInputs.article) ? '' : '#' }{item.value.name.length < 100 ? item.value.name : item.value.name.substr(0, 50).concat('...')}
                </span>
            </div>
            );
          });
        }
    };
    return (
        <>
            <Modal dialogClassName="modal-md" show={openModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>
                Create E-mail
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!preview && (
            <>
                <label>E-mail</label>
                <input
                    className="form-control"
                    name="email"
                    placeholder="e-mail"
                    defaultValue={email}
                    onChange={(evt) => setEmail(evt.target.value)}
                />
                <label>Subject</label>
                <input
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    defaultValue={subject}
                    onChange={(evt) => setSubject(evt.target.value)}
                />
                <br/>    
                <ReactQuill 
                        theme="snow" 
                        value={value} 
                        onChange={(value) => setValue(value)}
                        modules={modules}
                        formats={formats}
                    >
                </ReactQuill><br/>
                <h4>Picked list</h4>
                {renderList()}
                <Button variant="primary" type="button" onClick={() => previewEmail()}>
                    Preview
                </Button>
            </>    
            )}
            {preview && <FavoriteEmailPreview draftEmail ={draftEmail} goBackDraft ={goBackDraft} sendFavoriteListEmail={sendFavoriteListEmail} />}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
            </Modal>
        </>
    );
}
export default FavoriteEmail;