export const LOGIN_FETCH_START = '[auth] login fetch start';
export const LOGIN_FETCH_SUCCESS = '[auth] login fetch success';
export const LOGIN_FETCH_ERROR = '[auth] login fetch error';
export const REGISTER_FETCH_START = '[auth] register fetch start';
export const REGISTER_FETCH_SUCCESS = '[auth] register fetch success';
export const REGISTER_FETCH_ERROR = '[auth] register fetch error';
export const LOGIN_CHANGE_STATUS = '[auth] change login status'; 
export const ACTIVATE_FETCH_START = '[auth] activate fetch start';
export const ACTIVATE_FETCH_SUCCESS = '[auth] activate fetch success';
export const ACTIVATE_FETCH_ERROR = '[auth] activate fetch error';
export const RECOVER_FETCH_START = '[auth] recover fetch start';
export const RECOVER_FETCH_SUCCESS = '[auth] recover fetch success';
export const RECOVER_FETCH_ERROR = '[auth] recover fetch error';
export const RESET_FETCH_START = '[auth] reset fetch start';
export const RESET_FETCH_SUCCESS = '[auth] reset fetch success';
export const RESET_FETCH_ERROR = '[auth] reset fetch error';

export const loginFetchStart = param => ({
  type: LOGIN_FETCH_START,
  payload: param,
});

export const loginFetchSuccess = result => ({
  type: LOGIN_FETCH_SUCCESS,
  payload: result,
});

export const loginFetchError = error => ({
  type: LOGIN_FETCH_ERROR,
  payload: error,
});

export const loginChangeStatus = error => ({
  type: LOGIN_CHANGE_STATUS,
  payload: null,
});

export const registerFetchStart = param => ({
  type: REGISTER_FETCH_START,
  payload: param,
});

export const registerFetchSuccess = result => ({
  type: REGISTER_FETCH_SUCCESS,
  payload: result,
});

export const registerFetchError = error => ({
  type: LOGIN_CHANGE_STATUS,
  payload: null,
});

export const activateFetchStart = param => ({
  type: ACTIVATE_FETCH_START,
  payload: param,
});

export const activateFetchSuccess = result => ({
  type: ACTIVATE_FETCH_SUCCESS,
  payload: result,
});

export const activateFetchError = error => ({
  type: ACTIVATE_FETCH_ERROR,
  payload: error,
});

export const recoverFetchStart = param => ({
  type: RECOVER_FETCH_START,
  payload: param,
});

export const recoverFetchSuccess = result => ({
  type: RECOVER_FETCH_SUCCESS,
  payload: result,
});

export const recoverFetchError = error => ({
  type: RECOVER_FETCH_ERROR,
  payload: error,
});

export const resetFetchStart = param => ({
  type: RESET_FETCH_START,
  payload: param,
});

export const resetFetchSuccess = result => ({
  type: RESET_FETCH_SUCCESS,
  payload: result,
});

export const resetFetchError = error => ({
  type: RESET_FETCH_ERROR,
  payload: error,
});

