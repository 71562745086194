import { takeLatest } from 'redux-saga/effects';

//ACTIONS
import { ARTICLE_LIST_FETCH_START, ARTICLE_DETAIL_FETCH_START } from './../actions/article';
import { TERM_LIST_FETCH_START, TERM_ARTICLE_LIST_FETCH_START, TERM_MODAL_LIST_FETCH_START, TERM_GET_TYPE, TERM_TYPE_FETCH_SUCCESS_AND_SEARCH } from './../actions/term';
import { FILTERED_TOPIC_FETCH_START } from './../actions/topic';
import { CATEGORY_LIST_FETCH_START } from './../actions/category';
import { SECTOR_LIST_FETCH_START } from './../actions/sector';
import { EDIT_USER_FETCH_START } from './../actions/user';
import { SHARE_EMAIL_START } from './../actions/share';
import {
  SEARCH_LIST_FETCH_START,
  SEARCH_SAVE_FETCH_START,
  SEARCH_DELETE_FETCH_START,
  SEARCH_COPY_FETCH_START,
  SEARCH_EDIT_FETCH_START,
} from './../actions/search';
import {
  SEARCH_FOLDER_LIST_FETCH_START,
  SEARCH_FOLDER_SAVE_FETCH_START,
  SEARCH_FOLDER_DELETE_FETCH_START,
  SEARCH_FOLDER_COPY_FETCH_START,
  SEARCH_FOLDER_EDIT_FETCH_START,
  SEARCH_FOLDER_EMAIL_FETCH_START
} from './../actions/search-folder';
import {
  TEMPLATE_LIST_FETCH_START,
  TEMPLATE_SAVE_FETCH_START,
  TEMPLATE_DELETE_FETCH_START,
  TEMPLATE_COPY_FETCH_START,
  TEMPLATE_EDIT_FETCH_START,
} from './../actions/template';
import {
  TEMPLATE_FOLDER_LIST_FETCH_START,
  TEMPLATE_FOLDER_SAVE_FETCH_START,
  TEMPLATE_FOLDER_DELETE_FETCH_START,
  TEMPLATE_FOLDER_COPY_FETCH_START,
  TEMPLATE_FOLDER_EDIT_FETCH_START,
  TEMPLATE_FOLDER_EMAIL_FETCH_START
} from './../actions/template-folder';
import {
  HISTORY_LIST_FETCH_START,
  HISTORY_EDIT_FETCH_START,
  HISTORY_SAVE_FETCH_START
} from './../actions/history';
import { LOGIN_FETCH_START, REGISTER_FETCH_START, ACTIVATE_FETCH_START, RECOVER_FETCH_START, RESET_FETCH_START,  } from './../actions/auth';

//SAGAS
import { getArticles, getArticle } from './article';
import { filteredTopic } from './topic';
import { getTerms, getTermsOnArticle ,getTermsOnModal ,getTermsType, getTermsTypeAndSearch } from './term';
import { getCategories } from './category';
import { getSectors } from './sector';
import { getHistories, saveHistory, editHistory } from './history';
import { shareEmail } from './share';
import { getSearches, saveSearch, deleteSearch, editSearch, copySearch } from './search';
import { getSearchesFolders, saveSearchFolder, deleteSearchFolder, editSearchFolder, copySearchFolder, emailSearchFolder } from './search-folder';
import { getTemplates, saveTemplate, deleteTemplate, editTemplate, copyTemplate } from './template';
import { getTemplatesFolders, saveTemplateFolder, deleteTemplateFolder, editTemplateFolder, copyTemplateFolder, emailTemplateFolder } from './template-folder';
import { getAuthUser, activateUser, registerUser, recoverUser, resetUser } from './auth';
import { editUser } from './user';

export default function* rootSaga() {
  yield takeLatest(ARTICLE_LIST_FETCH_START, getArticles);
  yield takeLatest(ARTICLE_DETAIL_FETCH_START, getArticle);
  yield takeLatest(FILTERED_TOPIC_FETCH_START, filteredTopic);
  yield takeLatest(TERM_LIST_FETCH_START, getTerms);
  yield takeLatest(TERM_ARTICLE_LIST_FETCH_START, getTermsOnArticle);
  yield takeLatest(TERM_MODAL_LIST_FETCH_START, getTermsOnModal);
  yield takeLatest(TERM_GET_TYPE, getTermsType);
  yield takeLatest(TERM_TYPE_FETCH_SUCCESS_AND_SEARCH, getTermsTypeAndSearch);
  yield takeLatest(CATEGORY_LIST_FETCH_START, getCategories);
  yield takeLatest(SECTOR_LIST_FETCH_START, getSectors);
  yield takeLatest(HISTORY_LIST_FETCH_START, getHistories);
  yield takeLatest(HISTORY_SAVE_FETCH_START, saveHistory);
  yield takeLatest(HISTORY_EDIT_FETCH_START, editHistory);
  yield takeLatest(SEARCH_LIST_FETCH_START, getSearches);
  yield takeLatest(SEARCH_SAVE_FETCH_START, saveSearch);
  yield takeLatest(SEARCH_DELETE_FETCH_START, deleteSearch);
  yield takeLatest(SEARCH_COPY_FETCH_START, copySearch);
  yield takeLatest(SEARCH_EDIT_FETCH_START, editSearch);
  yield takeLatest(SEARCH_FOLDER_LIST_FETCH_START, getSearchesFolders);
  yield takeLatest(SEARCH_FOLDER_SAVE_FETCH_START, saveSearchFolder);
  yield takeLatest(SEARCH_FOLDER_DELETE_FETCH_START, deleteSearchFolder);
  yield takeLatest(SEARCH_FOLDER_COPY_FETCH_START, copySearchFolder);
  yield takeLatest(SEARCH_FOLDER_EDIT_FETCH_START, editSearchFolder);
  yield takeLatest(SEARCH_FOLDER_EMAIL_FETCH_START, emailSearchFolder);
  yield takeLatest(TEMPLATE_LIST_FETCH_START, getTemplates);
  yield takeLatest(TEMPLATE_SAVE_FETCH_START, saveTemplate);
  yield takeLatest(TEMPLATE_DELETE_FETCH_START, deleteTemplate);
  yield takeLatest(TEMPLATE_COPY_FETCH_START, copyTemplate);
  yield takeLatest(TEMPLATE_EDIT_FETCH_START, editTemplate);
  yield takeLatest(TEMPLATE_FOLDER_LIST_FETCH_START, getTemplatesFolders);
  yield takeLatest(TEMPLATE_FOLDER_SAVE_FETCH_START, saveTemplateFolder);
  yield takeLatest(TEMPLATE_FOLDER_DELETE_FETCH_START, deleteTemplateFolder);
  yield takeLatest(TEMPLATE_FOLDER_COPY_FETCH_START, copyTemplateFolder);
  yield takeLatest(TEMPLATE_FOLDER_EDIT_FETCH_START, editTemplateFolder);
  yield takeLatest(TEMPLATE_FOLDER_EMAIL_FETCH_START, emailTemplateFolder);
  yield takeLatest(LOGIN_FETCH_START, getAuthUser);
  yield takeLatest(ACTIVATE_FETCH_START, activateUser);
  yield takeLatest(RECOVER_FETCH_START, recoverUser);
  yield takeLatest(RESET_FETCH_START, resetUser);
  yield takeLatest(REGISTER_FETCH_START, registerUser);
  yield takeLatest(EDIT_USER_FETCH_START, editUser);
  yield takeLatest(SHARE_EMAIL_START, shareEmail);
  //news and politicals actions
}
