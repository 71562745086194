import HttpService from './http';
import config from '../config';

export class CategoryService {
  async getCategories() {
    const path = config.api.endpoints.getCategories;
    return HttpService.get(path);
  }
}

export const categoryService = new CategoryService();
