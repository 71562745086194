import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const SearchDeleteModal = props => {
  const { openModal, closeModal, confirmDeleteAction } = props;
  const handleClose = () => {
    closeModal(false);
  };

  const handleDelete = () => {
    closeModal(false);
    confirmDeleteAction();
  };

  return (
    <>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure that you want to delete this search?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleDelete}>
            Confirm
          </Button>
          <Button variant="primary" type="button" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SearchDeleteModal.propTypes = {
  closeModal: PropTypes.func,
  openModal: PropTypes.bool,
  confirmDeleteAction: PropTypes.func
};

export default SearchDeleteModal;
