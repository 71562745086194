export const TEMPLATE_LIST_FETCH_START = '[templates] list template fetch start';
export const TEMPLATE_LIST_FETCH_SUCCESS = '[templates] list template fetch success';
export const TEMPLATE_LIST_FETCH_ERROR = '[templates] list template fetch error';

export const TEMPLATE_SAVE_FETCH_START = '[templates] save template fetch start';
export const TEMPLATE_SAVE_FETCH_SUCCESS = '[templates] save template fetch success';
export const TEMPLATE_SAVE_FETCH_ERROR = '[templates] save template fetch error';

export const TEMPLATE_DELETE_FETCH_START = '[templates] delete template fetch start';
export const TEMPLATE_DELETE_FETCH_SUCCESS = '[templates] delete template fetch success';
export const TEMPLATE_DELETE_FETCH_ERROR = '[templates] delete template fetch error';

export const TEMPLATE_COPY_FETCH_START = '[templates] copy template fetch start';
export const TEMPLATE_COPY_FETCH_SUCCESS = '[templates] copy template fetch success';
export const TEMPLATE_COPY_FETCH_ERROR = '[templates] copy template fetch error';

export const TEMPLATE_EDIT_FETCH_START = '[templates] edit template fetch start';
export const TEMPLATE_EDIT_FETCH_SUCCESS = '[templates] edit template fetch success';
export const TEMPLATE_EDIT_FETCH_ERROR = '[templates] edit template fetch error';

export const TEMPLATE_CURRENT_SELECTION = '[templates] load current save template';
export const TEMPLATE_CLEAN_MESSAGE = '[templates] clean message';
export const CLEAN_CURRENT_SAVED_TEMPLATE = '[templates] clean current save template';

/// List templates actions
export const templateListFetchStart = query => ({
  type: TEMPLATE_LIST_FETCH_START,
  payload: query,
});

export const templateListFetchSuccess = templates => ({
  type: TEMPLATE_LIST_FETCH_SUCCESS,
  payload: { templates },
});

export const templateListFetchError = error => ({
  type: TEMPLATE_LIST_FETCH_ERROR,
  payload: { error },
});

/// Saved template actions
export const templateSaveFetchStart = query => ({
  type: TEMPLATE_SAVE_FETCH_START,
  payload: query,
});

export const templateSaveFetchSuccess = templates => ({
  type: TEMPLATE_SAVE_FETCH_SUCCESS,
  payload: { templates },
});

export const templateCleanMessage = query => ({
  type: TEMPLATE_CLEAN_MESSAGE,
  payload: { query },
});

export const templateSaveFetchError = error => ({
  type: TEMPLATE_SAVE_FETCH_ERROR,
  payload: { error },
});

/// Load current template action
export const populateCurrentSavedSelection = selectedTemplate => ({
  type: TEMPLATE_CURRENT_SELECTION,
  payload: { selectedTemplate },
});

export const cleanCurrentSavedTemplate = () => ({
  type: CLEAN_CURRENT_SAVED_TEMPLATE,
  payload: {  },
});
/// Delete templates actions
export const templateDeleteFetchStart = template => ({
  type: TEMPLATE_DELETE_FETCH_START,
  payload: template,
});

export const templateDeleteFetchSuccess = template => ({
  type: TEMPLATE_DELETE_FETCH_SUCCESS,
  payload: { template },
});

export const templateDeleteFetchError = error => ({
  type: TEMPLATE_DELETE_FETCH_ERROR,
  payload: { error },
});

/// Copy templates actions
export const templateCopyFetchStart = id => ({
  type: TEMPLATE_COPY_FETCH_START,
  payload: { id: id},
});

export const templateCopyFetchSuccess = template => ({
  type: TEMPLATE_COPY_FETCH_SUCCESS,
  payload: { template },
});

export const templateCopyFetchError = error => ({
  type: TEMPLATE_COPY_FETCH_ERROR,
  payload: { error },
});

export const templateEditFetchStart = template => ({
  type: TEMPLATE_EDIT_FETCH_START,
  payload: template,
});

export const templateEditFetchSuccess = template => ({
  type: TEMPLATE_EDIT_FETCH_SUCCESS,
  payload: { template },
});

export const templateEditFetchError = error => ({
  type: TEMPLATE_EDIT_FETCH_ERROR,
  payload: { error },
});
