import { call, put } from 'redux-saga/effects';

import {
  templateFolderListFetchStart,
  templateFolderListFetchSuccess,
  templateFolderSaveFetchSuccess,
  templateFolderSaveFetchError,
  templateFolderDeleteFetchSuccess,
  templateFolderDeleteFetchError,
  templateFolderCopyFetchSuccess,
  templateFolderCopyFetchError,
  templateFolderEditFetchSuccess,
  templateFolderEditFetchError,
  templateFolderEmailSuccess
} from '../actions/template-folder';

import { templateFolderService } from '../services/template-folder';

export function* emailTemplateFolder(action){
  const response = yield call(templateFolderService.templateFolderEmail, action);
  yield put(templateFolderEmailSuccess(response));
}

export function* getTemplatesFolders(action) {
  const response = yield call(templateFolderService.getTemplatesFolders, action);
  yield put(templateFolderListFetchSuccess(response));
}

export function* saveTemplateFolder(action) {
  try {
    const response = yield call(templateFolderService.saveTemplateFolder, action.payload);
    yield put(templateFolderSaveFetchSuccess(response));
    yield put(templateFolderListFetchStart());
  } catch (error) {
    yield put(templateFolderSaveFetchError(error));
  }
}

export function* deleteTemplateFolder(action) {
  try {
    const response = yield call(templateFolderService.deleteTemplateFolder, action.payload);
    yield put(templateFolderDeleteFetchSuccess(response));
  } catch (error) {
    yield put(templateFolderDeleteFetchError(error));
  }
}

export function* copyTemplateFolder(action) {
  try {
    const response = yield call(templateFolderService.copyTemplateFolder, action.payload);
    yield put(templateFolderCopyFetchSuccess(response));
    yield put(templateFolderListFetchStart());
  } catch (error) {
    yield put(templateFolderCopyFetchError(error));
  }
}

export function* editTemplateFolder(action) {
  try {
    const response = yield call(templateFolderService.editTemplateFolder, action.payload);
    yield put(templateFolderEditFetchSuccess(response));
  } catch (error) {
    yield put(templateFolderEditFetchError(error));
  }
}
