import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Login from './login';

import { loginFetchStart, activateFetchStart } from '../../actions/auth';

const mapStateToProps = state => ({
  isLoading: state.login.isLoading,
  loginStatus: state.login.status,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ loginFetchStart ,activateFetchStart }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
