import HttpService from './http';
import config from '../config';

export class UserService {
  async editUser(data) {
    const path = config.api.endpoints.editUser;
    return HttpService.put(path, {
      name: data.payload.name,
      email: data.payload.email,
    });
  }
}

export const userService = new UserService();
