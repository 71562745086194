import HttpService from './http';
import config from '../config';

export class SectorService {
  async getSectors() {
    const path = config.api.endpoints.getSectors;
    return HttpService.get(path);
  }
}

export const sectorService = new SectorService();
