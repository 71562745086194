import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Register from './register';

import { registerFetchStart } from '../../actions/auth';

const mapStateToProps = state => ({
  isLoading: state.register.isLoading,
  registerSuccess: state.register.success,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ registerFetchStart }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
