import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import './styles.css';

const Logout = props => {

  const { onLogout } = props;
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem('user');
    history.push('/login');
    onLogout();
  };

  return (
    <span
      className="logout-label"
      aria-hidden="true"
      onClick={() => {
        handleLogout();
      }}
    >
      Sign out
    </span>
  );
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
