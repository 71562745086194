import { SEARCH_FOLDER_EDIT_FETCH_START, SEARCH_FOLDER_EDIT_FETCH_ERROR } from '../../actions/search-folder';

export const initialState = {
  isLoading: false,
  response: null,
};

export const searchFolderEditReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_FOLDER_EDIT_FETCH_START:
      return { ...state, isLoading: true };
    case SEARCH_FOLDER_EDIT_FETCH_ERROR:
      return { ...state, response: payload.error.response, isLoading: false };
    default:
      return state;
  }
};
