import React from 'react';

export const getSearchDescription = (item) => {
    let itemName = '';
    if (item.name){
      itemName += ' #'+item.name;
    }
    if (item.filters.customTerm){
      itemName += ' '+item.filters.customTerm;
    }
    if (item.filters && item.filters.terms) {
      item.filters.terms.map((i, index) => {
        itemName += ' '+ i.core;
        return null;
      });
    }
    if (getCompaniesText(item) !== 'None'){
      itemName += ' '+getCompaniesText(item);
    }
    if (getSectorText(item) !== 'None' && getSectorText(item) !== undefined){
      itemName += ' '+getSectorText(item);
    }
    if (getSourcesText(item) !== 'None'){
      itemName += ' '+getSourcesText(item);
    }
    if (getAnalystsText(item) !== 'None'){
      itemName += ' '+getAnalystsText(item);
    }
    return (<> {itemName.substr(0, 50).concat('...')} </>);
  }

export const getTermsText = (item) => {
    let itemName = '';
    if (item.filters.customTerm){
      itemName += ' '+item.filters.customTerm;
    }
    if (item.filters && item.filters.terms) {
      item.filters.terms.map((i, index) => {
        itemName += ' '+ i.core;
        return null;
      });
    }
    return itemName;
  }

export const getCompaniesText = (item) => {
    if (item.filters.filterInputs.companies){
      return item.filters.filterInputs.companies;
    }else
     return 'None';
  }

export const getSectorText = (item) => {
    if (item.filters.filterInputs.sectors){
      return item.filters.filterInputs.sectors.name;
    }else
     return 'None';
  }

export const getSourcesText = (item) => {
    let sources = '';
    if (item.filters.filterInputs.sources){
      item.filters.filterInputs.sources.map((s, index)=>{
        sources += s.name + ' ';
        return null;
      });
      sources = 'News: '+sources+'';
    }
    if (sources !=='' ){
      return sources;
    }else
      return 'None';
  }

export const getAnalystsText = (item) => {
    let analysts = ''
    if (item.filters.filterInputs.analysis){
      item.filters.filterInputs.analysis.map((s, index)=>{
        analysts += s.name + ' ';
        return null;
      });
      analysts = 'Analysts: '+analysts+'';
    }
    if (analysts !=='' ){
      return analysts;
    }else
      return 'None';
  }

export const getTimeframeText = (item) => {
    let optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let fromDate = null;
    let toDate = null;
    if (item.filters.filterInputs.timeframe ){
      switch (item.filters.filterInputs.timeframe) {
        case '1': return 'Today';
        case '2': return 'Last Week';
        case '3': return 'Last Month';
        case '4':
          fromDate = new Date(item.filters.filterInputs.fromDate).toLocaleDateString('en-US',optionsDate);
          toDate = new Date(item.filters.filterInputs.toDate).toLocaleDateString('en-US',optionsDate);  
          return  'Between '+ fromDate +' - ' + toDate;
        case '5':
          fromDate = new Date(item.filters.filterInputs.fromDate).toLocaleDateString('en-US',optionsDate); 
          return 'From Date '+fromDate;
        case '6': 
          toDate = new Date(item.filters.filterInputs.toDate).toLocaleDateString('en-US',optionsDate);
          return 'To Date '+toDate;
        default: return 'All';
      }
    }else
      return 'All';
  }