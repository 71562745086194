import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tabs, Tab, Alert } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from "react-router-dom";

import Article from './article.container';
import Loading from '../../components/loading';
import './styles.css';
import ArticleListPagination from '../../components/article-list/article-list-pagination';
import ArticleModal from './modal/article-modal';
import { articleDetailFetchRemove } from '../../actions/article';
import { highlightFetchList } from '../../actions/highlight';
import {cleanCurrentSavedSearch, searchCopyRemove} from "../../actions/search";

const ArticleList = props => {
  const { article_id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showSingle, setShowSingle] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const { articles, sources, categorySources, selectedFilterInputs } = props;

  const openModal = (show, articleId) => {
    setShow(show);
    setCurrentArticleId((show)? articleId : null);
    if (!show){
      props.articleDetailFetchRemove();
      dispatch(cleanCurrentSavedSearch());
      dispatch(searchCopyRemove());
      dispatch(highlightFetchList({ notes: '', data: [] }));
    }
  }

  const getArticlesBySource = source => {
    let list = [];

    list = articles.map((item, index) => {
      return (
        <Row key={index}>
          <Col>
            <Article article={item} selectedTerms={props.selectedTerms} key={source} onOpenModal={(show, currentArticleId)=> openModal(show, currentArticleId) } />
          </Col>
        </Row>
      );
    });

    if (list.length === 0) {
      list = <Alert variant="info">There are no articles for this source</Alert>;
    }
    return list;
  };

  const changePage = page => {
    props.handleFilteredCategory(props.selectedCategory, props.currentSource, page);
  };

  const handleChangeSource = source => {
    props.handleFilteredCategory(props.selectedCategory, source, 1);
  };

  if (!showSingle && article_id){
    setShowSingle(true);
    openModal(true, article_id);
  }

  return sources ? (
    <>
      <Tabs
        defaultActiveKey={categorySources[0].name}
        activeKey={props.currentSource}
        className={"source-tabs index-"+props.index}
        unmountOnExit={true}
        onSelect={key => {
          handleChangeSource(key);
        }}
      >
        {categorySources.map(item => {
          let itemName = item.name;
          itemName = itemName.replace(/-/g,' ');
          itemName = itemName.charAt(0).toUpperCase() + itemName.slice(1);
          if (item.name === 'cnbc' || item.name === 'the-wall-street-journal'){
            let find = false;
            selectedFilterInputs.sources.map((i, index) => {
              if (i.id === item.name){
                find = true;
              }
              return null;
            });
            if (!find) return null;
          }
          if (item.name ==='pdf') itemName = 'Analysis';
          if (item.name ==='html') itemName = 'News';
          return articles ? (
            <Tab key={item.name} eventKey={item.name} title={itemName}>
              {getArticlesBySource(item.id)}
            </Tab>
          ): (<Tab key={item.name} eventKey={item.name} title={itemName}><Loading /></Tab>);

        })}
      </Tabs>
      {(props.sources.length > 0 && articles && articles.length > 0) && <ArticleListPagination
        pagination={props.pagination}
        changePage={changePage}
      />}
      {!props.sources.length && <Alert variant="warning">There are no sources selected</Alert>}
      <ArticleModal currentArticleId={currentArticleId} isOpenModal={show} onCloseModal={(show, currentArticleId)=> { openModal(false,currentArticleId); setShowSingle(false); }} />
    </>
  ) : (
    <Loading />
  );
};

ArticleList.propTypes = {
  articles: PropTypes.array,
  sources: PropTypes.array,
  handleFilteredCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
  selectedFilterInputs: PropTypes.object,
  currentSource: PropTypes.string,
  index: PropTypes.number
};

ArticleList.defaultProps = {
  articles: [],
  sources: []
};

const mapStateToProps = state => ({
  currentArticle: state.articleDetail.currentArticle,
  selectedTerms: state.selectedTerms.data,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({articleDetailFetchRemove}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleList);
