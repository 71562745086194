import {
  TEMPLATE_LIST_FETCH_START,
  TEMPLATE_LIST_FETCH_SUCCESS,
  TEMPLATE_LIST_FETCH_ERROR,
  TEMPLATE_COPY_FETCH_SUCCESS,
  TEMPLATE_DELETE_FETCH_SUCCESS,
  TEMPLATE_EDIT_FETCH_SUCCESS,
} from '../../actions/template';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  response: null,
};

export const templateListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TEMPLATE_LIST_FETCH_START:
      return { ...state, isLoading: true };
    case TEMPLATE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.templates,
        error: null,
        isLoading: false,
      };
    case TEMPLATE_LIST_FETCH_ERROR:
      return { ...state, data: '', error: payload.error };
    case TEMPLATE_DELETE_FETCH_SUCCESS:
      return {
        ...state,
        data: state.data.filter(template => template._id !== payload.template.data),
        response: payload.template,
        error: null,
        isLoading: false,
      };
    case TEMPLATE_COPY_FETCH_SUCCESS:
      return {
        ...state,
        data: state.data,
        response: payload.template,
        error: null,
        isLoading: false,
      };
    case TEMPLATE_EDIT_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updateIndex = state.data.findIndex(template => template._id === payload.template.data._id);
      state.data[updateIndex] = payload.template.data;
      return {
        ...state,
        data: state.data,
        response: payload.template,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
