import {
    SEARCH_FOLDER_EMAIL_FETCH_START,
    SEARCH_FOLDER_EMAIL_FETCH_SUCCESS,
    SEARCH_FOLDER_EMAIL_FETCH_ERROR,
  } from '../../actions/search-folder';
  
  export const initialState = {
    isLoading: false,
    response: null,
  };
  
  export const searchFolderEmailReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case SEARCH_FOLDER_EMAIL_FETCH_START:
        return { ...state, isLoading: true };
      case SEARCH_FOLDER_EMAIL_FETCH_SUCCESS:
        return {
          ...state,
          response: payload.data,
          isLoading: false,
        };
      case SEARCH_FOLDER_EMAIL_FETCH_ERROR:
        return { ...state, response: payload.error.response, isLoading: false };
      default:
        return state;
    }
  };