import { call, put } from 'redux-saga/effects';

import { editUserFetchSuccess, editUserFetchError } from '../actions/user';

import { userService } from '../services/user';

export function* editUser(action) {
  try {
    const response = yield call(userService.editUser, action);
    yield put(editUserFetchSuccess(response));
  } catch (error) {
    yield put(editUserFetchError(error));
  }
}
