import {
  ARTICLE_LIST_FETCH_START,
  ARTICLE_LIST_FETCH_SUCCESS,
  ARTICLE_LIST_FETCH_ERROR
} from '../../actions/article';
import { availableSources, availableAnalysisSources } from '../../constants/sources';
export const initialState = {
  isLoading: false,
  data: null,
  sources: [],
  currentSource: 'the-wall-street-journal',
  pagination: null,
  error: null,
};

export const articleListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ARTICLE_LIST_FETCH_START:
      return { ...state, isLoading: true, data: null };
    case ARTICLE_LIST_FETCH_SUCCESS:
      const hasPrevPage = (payload.articles.page_number > 1) ? true : false;
      const hasNextPage = (payload.articles.page_number < payload.articles.total_pages) ? true : false;
      const prevPage = payload.articles.page_number -1;
      const nextPage = payload.articles.page_number +1;
      return {
        ...state,
        data: payload.articles.list,
        sources: availableSources,
        analysis: availableAnalysisSources,
        currentSource: payload.articles.current_source,
        pagination: {
          totalDocs: payload.articles.total_count,
          limit: payload.articles.per_page,
          totalPages: payload.articles.total_pages,
          page: payload.articles.page,
          pagingCounter: payload.articles.count,
          hasPrevPage: hasPrevPage,
          hasNextPage: hasNextPage,
          prevPage: prevPage,
          nextPage: nextPage,
        },
        error: null,
        isLoading: false,
      };
    case ARTICLE_LIST_FETCH_ERROR:
      return { ...state, data: '', error: payload.error, isLoading: false };
    default:
      return state;
  }
};
