import HttpService from './http';
import config from '../config';

export class TermService {
  async getTerms() {
    const path = config.api.endpoints.getTerms;
    return HttpService.get(path);
  }

  async getTermsOnArticle(data) {
    const path = config.api.endpoints.getTermsArticle;
    return HttpService.post(path, {
      terms: data.payload.terms,
      selectedTerms: data.payload.selectedTerms,
      id: data.payload.id,
    });
  }

  async getTermsOnModal() {
    let result = {};
    result.companies = await HttpService.get(config.api.endpoints.getTermsModalCompanies);
    result.metrics = await HttpService.get(config.api.endpoints.getTermsModalMetrics);
    return result;
  }

  async getTermType(params) {
    const path = config.api.endpoints.getTermType;
    return await HttpService.post(path, params);
  }
}

export const termService = new TermService();
