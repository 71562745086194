export const HIGHLIGHT_FETCH_LIST = '[highlight] get list list';
export const HIGHLIGHT_REMOVE = '[highlight] remove from list';
export const HIGHLIGHT_ADD = '[highlight] add from list';
export const HIGHLIGHT_SAVE_LIST = '[highlight] save list';
export const HIGHLIGHT_SAVE_NOTES = '[highlight] save note';

export const highlightFetchList = highlights => ({
    type: HIGHLIGHT_FETCH_LIST,
    payload: { highlights },
});

export const highlightRemove = highlight => ({
    type: HIGHLIGHT_REMOVE,
    payload: { highlight },
});

export const highlightAdd = highlight => ({
    type: HIGHLIGHT_ADD,
    payload: { highlight },
});

export const highlightSaveList = highlights => ({
    type: HIGHLIGHT_SAVE_LIST,
    payload: { highlights },
});

export const highlightSaveNotes = notes => ({
    type: HIGHLIGHT_SAVE_NOTES,
    payload: { notes },
});
