import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AccordionGroup = props => {
  const [showContent, setShowContent] = useState(false);
  const handleClick = () => {
    setShowContent(!showContent);
  };
  if (!showContent)
    return (
      <div className="accordion-parent" >
        <span className="markup-blue-group" aria-hidden="true" onClick={handleClick}>
          [...]<sup>{props.accordionsGroup.length}</sup>
        </span>
      </div>
    );
  else
    return (
      <div className="accordion-parent" >
        <div className="accordion-custom-parent">{props.accordionsGroup}</div>
        <span className="markup-blue-group" aria-hidden="true" onClick={handleClick}>
          [&#8598;]
        </span>
      </div>
    );
};

AccordionGroup.propTypes = {
  accordionsGroup: PropTypes.array
};

AccordionGroup.defaultProps = {
  accordionsGroup: [],
};

export default AccordionGroup;
