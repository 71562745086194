import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Col, Row, Button, Form } from 'react-bootstrap';
import Logout from '../logout';
import Loading from './../loading';
import './styles.css';

const MenuHeader = props => {
  const { editUserFetchStart, isLoadingEditUser, loginChangeStatus } = props;
  const [openModal, setOpenModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(() => {
    let user = JSON.parse(localStorage.getItem('user'));

    return (user) ? user.name: 'Anonymous';
  });
  const handleClose = () => setOpenModal(false);

  const getEmail = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    return (user) ? user.email: 'anonymous@anonymous.com';
  };

  const handleSubmit = event => {
    setValidated(false);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) setValidated(true);
    else {
      editUserFetchStart({ name: name, email: form.elements[0].value });
      let user = JSON.parse(localStorage.getItem('user'));
      user.name = name;
      localStorage.removeItem('user');
      localStorage.setItem('user', JSON.stringify(user));
      setOpenModal(false);
    }
  };

  const logout = () => {
    loginChangeStatus();
  };

  return (
    <Row className="header">
      <Col>
        <img alt="logo" className="logo" src={window.location.origin+'/logo.png'} />
      </Col>
      <Col className="right-col">
        Hello {name} |
        <span
          className="link"
          role="button"
          aria-hidden="true"
          tabIndex="0"
          onClick={() => setOpenModal(true)}
        >
         My Account
        </span>{' '}
        | <Logout onLogout={logout}/>
      </Col>
      <Col xs={1}><span className="bm-burger-button-notification"></span> </Col>
      <Modal show={openModal} onHide={handleClose} className="edit-profile-modal">
        <Modal.Header closeButton>
          <Modal.Title>My Account</Modal.Title>
        </Modal.Header>
        {!isLoadingEditUser && (
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <Modal.Body>
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" defaultValue={getEmail()} readOnly />
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={name}
                  aria-describedby="inputGroupPrepend"
                  onChange={e => setName(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  You must enter your name.
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Edit profile
              </Button>
            </Modal.Footer>
          </Form>
        )}
        {isLoadingEditUser && <Loading />}
      </Modal>
    </Row>
  );
};

MenuHeader.propTypes = {
  isLoadingEditUser: PropTypes.bool,
  editUserFetchStart: PropTypes.func
};

export default(MenuHeader);
