import {
  TEMPLATE_FOLDER_SAVE_FETCH_START,
  TEMPLATE_FOLDER_SAVE_FETCH_SUCCESS,
  TEMPLATE_FOLDER_SAVE_FETCH_ERROR,
} from '../../actions/template-folder';

export const initialState = {
  isLoading: false,
  response: null,
};

export const templateFolderSaveReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TEMPLATE_FOLDER_SAVE_FETCH_START:
      return { ...state, isLoading: true };
    case TEMPLATE_FOLDER_SAVE_FETCH_SUCCESS:
      return {
        ...state,
        response: payload.folders,
        isLoading: false,
      };
    case TEMPLATE_FOLDER_SAVE_FETCH_ERROR:
      return { ...state, response: payload.error.response, isLoading: false };
    default:
      return state;
  }
};
