import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import Loading from '../../components/loading';
import ArticleList from './article-list';
import './styles.css';

const ArticleCategories = props => {
  const [tabsCategories, setTabsCategories] = useState([]);
  useEffect(() => {
    setTabsCategories(props.categories);
    if (!props.terms) {
      props.categoryListFetchStart();
      props.articleListFetchStart({
        category: props.selectedCategory,
        currentSource: props.currentSource,
        filterInputs: props.selectedFilterInputs,
        allTerms: props.terms,
      });
    }
  }, [props.currentSource, props.selectedCategory, props.terms, props.categoryListFetchStart, props.articleListFetchStart, props.selectedFilterInputs, props]);

  const handleFilteredCategory = (category, source, page) => {
    if (source === null){
      tabsCategories.map((cat, index) =>{
        if (cat.name === category){
          source = cat.sources[0].name;
        }
        return null
      })
    }

    let query = { category: category, currentSource: source, allTerms: props.terms, page: page };

    if (props.selectedFilterInputs) {
      query.filterInputs = props.selectedFilterInputs;
    }

    query.terms = props.selectedTerms;
    props.articleListFetchStart(query);
  };

  const handleChangeCategory = category => {
    props.categorySaveSelected(category);
  };

  let list = [];
  if (tabsCategories) {
    list = tabsCategories.map((item, index) => {
      return (
        <Tab
          eventKey={item.name}
          title={item.name}
          variant={props.selectedCategory === item.name ? 'primary' : 'secondary'}
          key={index}
        >
          <ArticleList
            index = {index}
            articles={props.articles}
            categorySources = {item.sources}
            sources={props.sources}
            handleFilteredCategory={handleFilteredCategory}
            selectedCategory={props.selectedCategory}
            selectedFilterInputs={props.selectedFilterInputs}
            currentSource={props.currentSource}
            pagination={props.articlesPagination}
          />
        </Tab>
      );
    });

    return (
      <Tabs
        className="category-tab"
        defaultActiveKey='News'
        unmountOnExit={true}
        onSelect={key => {
          handleChangeCategory(key);
          handleFilteredCategory(key, null);
        }}
      >
        {list}
      </Tabs>
    );
  }else{
    return (<Loading />);
  }
};

ArticleCategories.propTypes = {
  articles: PropTypes.array,
  sources: PropTypes.array,
  categories: PropTypes.array,
  terms: PropTypes.array,
  selectedTerms: PropTypes.array,
  selectedFilterInputs: PropTypes.object,
  selectedFilterTerms: PropTypes.object,
  selectedCategory: PropTypes.string,
  selectedSource: PropTypes.string,
  articleListFetchStart: PropTypes.func,
  categoryListFetchStart: PropTypes.func,
  termsArticleListFetchStart: PropTypes.func,
  categorySaveSelected: PropTypes.func,
  currentSource: PropTypes.string,
  articlesPagination: PropTypes.object
};

ArticleCategories.defaultProps = {
  articles: [],
  sources: [],
  selectedTerms: [],
  selectedFilterInputs: {}
};

export default ArticleCategories;
