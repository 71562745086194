const paths = {
  FAVORITES: '/favorites',
  TEMPLATES: '/templates',
  ARTICLE_LIST: '/articles',
  ARTICLE_LIST_SINGLE: '/articles/:article_id',
  ARTICLE_LIST_SHARE: '/share/:shareId',
  ARTICLE_LIST_SHARE_FOLDER: '/article/share-folder/:shareFolderId',
  AUTH_LOGIN: '/login',
  AUTH_REGISTER: '/register',
  AUTH_ACTIVATE: '/activate/:userId/:activationCode',
  SEND_RESET_PASSWORD: '/send-reset-password',
  RESET_PASSWORD: '/reset-password/:userId/:activationCode',
};

export default paths;
