import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const SearchShareAcceptModal = props => {
  const { openModal, closeModal, confirmShareAction } = props;
  const handleClose = () => {
    closeModal(false);
  };
  
  const handleShare = () => {
    closeModal(false);
    confirmShareAction();
  };

  return (
    <>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Do you accept shared search ? </label>&nbsp;
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleShare}>
            Ok
          </Button>
          <Button variant="primary" type="button" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SearchShareAcceptModal.propTypes = {
  closeModal: PropTypes.func,
  openModal: PropTypes.bool,
  shareItem: PropTypes.object,
  confirmShareAction: PropTypes.func
};

export default SearchShareAcceptModal;
