/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { highlightAdd, highlightRemove } from '../../actions/highlight';
import { useDispatch } from 'react-redux';

const AccordionCustom = props => {
  const dispatch = useDispatch();
  const [showContent, setShowContent] = useState(false);
  const [selected, setSelected] = useState(false);
  const articleHighlights  = props.articleHighlights;
  const handleClick = () => {
    setShowContent(!showContent);
  };
  useEffect(()=>{
    if (articleHighlights && articleHighlights.length > 0) {
      articleHighlights.forEach((highlight, index) => {
        if (highlight.id === props.id){
          setSelected(true);
        }
      });
    }
  },[articleHighlights, props.id]);
  const getEndSentenceTerm = () => {
    let appearances = '';
    if (props.highlights) {
      props.highlights.map(highlight => {
        let searchedTerm = highlight.original.split(" ");
        let caseInsensitive = (searchedTerm.length > 1) ? 'gi' : 'g';
        let content = props.content;
        let termOriginal = highlight.original;
        const positions = [];
        let regex1 = /(^|[,.;:/?|\\{["'!*()}\]<>\-─“”&#_+\s\n\t\r\v\0])/;
        let regex2 = /(?=[,.;:/?|\\{["'!*()}\]<>\-─“”&#_+\s\n\t\r\v\0]|$)/;
        let regExprTerm = new RegExp(regex1.source+"("+termOriginal+")"+regex2.source, caseInsensitive);
        let result;
        while ((result = regExprTerm.exec(content))) {
          positions.push(result.index);
        }
        if (positions.length){
          appearances += highlight.original + ', ';
        }
        return null;
      });
      return appearances.substring(0, appearances.length - 2);
    }
    return null;
  };

  const addSentenceHighlight = () => {
    dispatch(highlightAdd({ id: props.id, content: props.title+' '+props.content }));
    setSelected(true);
  }

  const removeSentenceHighlight = () => {
    dispatch(highlightRemove({ id: props.id, content: props.title+' '+props.content }));
    setSelected(false);
  }

  const getHighlightNotes = () => {
    let notes = '';
    articleHighlights.forEach((highlight, index) => {
      if (highlight.id === props.id){
        notes = highlight.notes;
      }
    });
    return notes;
  }

  const handleSentenceHighlight = () => {
    if (selected) {
      removeSentenceHighlight();
    }else{
      addSentenceHighlight();
    }
  }

  const lengthEndSentence = props.content.length;
  return (
    <div className="accordion-custom" >
      {(!showContent && !props.showExpanded) && (
        <>
          <section id={props.id} data-parent-id={props.dataParentId} className={props.level}>
            <div className="accordion-custom-title">{parser(props.title)}</div>
            { lengthEndSentence!==0 && <span className="markup" onClick={handleClick}>
              &nbsp;[...]&nbsp;
            </span> }
            &nbsp;
            {(articleHighlights) && <FontAwesomeIcon data-for={'tooltip_'+props.id} data-tip='1' icon={faThumbtack} className={(selected) ? 'thumbtack selected': 'thumbtack'} onClick={handleSentenceHighlight} />}&nbsp;
            <strong>{getEndSentenceTerm()}</strong>
          </section>
        </>
        )}
      {(showContent || props.showExpanded) && (
        <>
          <section id={props.id} data-parent-id={props.dataParentId} className={props.level}>
            <div className="accordion-custom-title">{parser(props.title)}</div>&nbsp;
            <div className="accordion-custom-content">{parser(props.content)}</div>
            <span className="markup" onClick={handleClick}>
              &nbsp;[&#8598;]&nbsp;
            </span>
            &nbsp;
            {(articleHighlights) && <FontAwesomeIcon data-for={'tooltip_'+props.id} data-tip='1' icon={faThumbtack} className={(selected) ? 'thumbtack selected': 'thumbtack'} onClick={handleSentenceHighlight}  />}&nbsp;
          </section>
        </>
      )}
      {(selected) && (<ReactTooltip 
        id={'tooltip_'+props.id} 
        getContent={(dataTip) => 
          <div>
            <p>{getHighlightNotes()} </p>    
          </div>
          }
        delayShow={1000}
        effect='solid'
      />)}
    </div>
  );
};

AccordionCustom.propTypes = {
  sentenceIndex: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
  terms: PropTypes.array,
  highlights: PropTypes.array,
  sentence: PropTypes.string,
  hasCustomSearchResult: PropTypes.bool
};

export default AccordionCustom;
