import HttpService from './http';
import config from '../config';

export class SearchService {
  async getSearches() {
    const path = config.api.endpoints.getSearches.replace(':id', (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).id : null);
    return HttpService.get(path);
  }

  async saveSearch(payload) {
    payload.user = (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).id : null;
    const path = config.api.endpoints.saveSearch;
    return HttpService.post(path, payload);
  }

  async deleteSearch(payload) {
    const path = config.api.endpoints.deleteSearch.replace(':id', payload._id);
    return HttpService.delete(path, payload);
  }

  async copySearch(payload) {
    //payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.getSearch;
    return HttpService.post(path, payload);
  }

  async editSearch(payload) {
    const path = config.api.endpoints.editSearch.replace(':id', payload._id);
    return HttpService.update(path, payload);
  }
}

export const searchService = new SearchService();
