import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { Row, Col } from 'react-bootstrap';

const ArticleListPagination = props => {
  let active = props.pagination.page;
  let items = [];

  const handleEllipsis = () => {
    const limitPaginatorSizeBetween = 10;
    if (props.pagination.totalPages < limitPaginatorSizeBetween){
      return [0, 0];
    }
    let ellipsisPosition = [
      props.pagination.page - limitPaginatorSizeBetween / 2,
      props.pagination.page + limitPaginatorSizeBetween / 2,
    ];

    if (ellipsisPosition[0] <= 1) ellipsisPosition[0] = 0;
    if (ellipsisPosition[1] >= props.pagination.totalPages) ellipsisPosition[1] = 0;
    return ellipsisPosition;
  };

  const handleClick = (e) => {
    props.changePage(parseInt(e.target.text));
  };

  const handleNext = () => {
    if (props.pagination.page < props.pagination.totalPages)
      props.changePage(props.pagination.page + 1)
  }

  let ellipsisPosition = handleEllipsis();
  for (let number = 1; number <= props.pagination.totalPages; number++) {
    if (number === ellipsisPosition[0] || number === ellipsisPosition[1])
      items.push(<Pagination.Ellipsis key={number}/>);
    if (number > ellipsisPosition[0] && (number < ellipsisPosition[1] || ellipsisPosition[1] === 0 ))
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={(e) => handleClick(e)}>
          {number}
        </Pagination.Item>
      );
  }

  const pagination = (
    <Row>
      <Col md={{ span: 4, offset: 4 }}>
        <Pagination size="sm">
          <Pagination.First onClick={() => props.changePage(1)}/>
          <Pagination.Prev onClick={() => props.changePage(props.pagination.page - 1)} />
          {items}
          <Pagination.Next onClick={() => handleNext()}/>
          <Pagination.Last onClick={() => props.changePage(props.pagination.totalPages)} />
        </Pagination>
      </Col>
    </Row>
  );

  return pagination;
};

ArticleListPagination.propTypes = {};

ArticleListPagination.defaultProps = {};

export default ArticleListPagination;
