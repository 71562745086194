import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import TermsInArticle from '../../components/article-list/terms-in-article';
import { useHistory } from 'react-router-dom';
import './styles.css';

const Article= props => {

  const {
    article
  } = props;
  const history = useHistory();
  return (
    <>
      <Col className="article-list-item">
        <Row>
          <Col className="article-access-item" onClick={()=> history.push('/articles/'+article._id) }>
            {article.metainfo.title}
          </Col>
        </Row>
        <Row>
          <Col className="article-list-subtext">
            <span className="red">[{article.metainfo.type || 'html'}]</span>{' '}
            <strong>By {article.metainfo.author} </strong> - {' '}
            <i>
            <FormattedDate
              value={article.metainfo.date}
              year="numeric"
              month="long"
              day="2-digit"
              timeZone="UTC"
            />
            </i>
          </Col>
        </Row>
        <Row>
          <Col className="article-list-subtext">{article.metainfo.tagline}</Col>
        </Row>
        <Row>
          <Col className="article-list-subtext">
            <TermsInArticle selectedTerms={props.selectedTerms} articleCompanies={article.companies} articleTerms={article.matched_terms} articleMetrics = {article.matched_metrics_terms}/>
          </Col>
        </Row>
      </Col>
    </>
  );
};

Article.propTypes = {
  article: PropTypes.object,
  isLoading: PropTypes.bool,
  onOpenModal: PropTypes.func
};

Article.defaultProps = {
  article: {},
  isLoading: false,
};

export default Article;
