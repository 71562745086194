import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { termSaveSelected, termListFetchStart, termSetTypeRefresh } from '../../actions/term';
import {
  searchListFetchStart,
  populateCurrentSavedSelection,
  searchDeleteFetchStart,
  searchCopyFetchStart,
  searchEditFetchStart,
} from '../../actions/search';
import {
  searchFolderCopyFetchStart,
} from '../../actions/search-folder';
import {
  historyListFetchStart, historySaveFetchStart
} from '../../actions/history';
import { articleListFetchStart } from '../../actions/article';

import SaveSidebar from './search-sidebar';

const mapStateToProps = state => ({
  historySaveList: state.historyList.data,
  searchSaveList: state.searchList.data,
  searchActionResponse: state.searchList.response,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      termListFetchStart,
      searchListFetchStart,
      historyListFetchStart,
      historySaveFetchStart,
      articleListFetchStart,
      populateCurrentSavedSelection,
      searchDeleteFetchStart,
      searchCopyFetchStart,
      searchFolderCopyFetchStart,
      searchEditFetchStart,
      termSetTypeRefresh,
      termSaveSelected
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveSidebar);
