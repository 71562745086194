import HttpService from './http';
import config from '../config';

export class TopicService {
  async filteredTopic(params) {
    const path = config.api.endpoints.filteredTopic.replace(':id', params.payload.id);
    return HttpService.post(path, { article_id: params.payload.article_id, search: params.payload.term });
  }
}

export const topicService = new TopicService();
