import HttpService from './http';
import config from '../config';

export class ShareService {

  async shareEmail(payload) {
    const path = config.api.endpoints.shareEmail.replace(':id', JSON.parse(localStorage.getItem('user')).id);
    payload.user = JSON.parse(localStorage.getItem('user')).id;
    return HttpService.post(path, payload);
  }

}

export const shareService = new ShareService();