import { 
    RECOVER_FETCH_START,
    RECOVER_FETCH_SUCCESS,
    RECOVER_FETCH_ERROR,
  } from '../../actions/auth';
  
  export const initialState = {
    isLoading: false,
    error: null,
    status: null,
  };
  
  export const recoverReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case RECOVER_FETCH_START:
        return { ...state, isLoading: true };
      case RECOVER_FETCH_SUCCESS:
        return {
          ...state,
          status: true,
          error: null,
          isLoading: false,
        };
      case RECOVER_FETCH_ERROR:
        return { ...state, error: payload, status: false, isLoading: false, }; 
      default:
        return state;
    }
  };