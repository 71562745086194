import React, { useState }  from 'react';
import { Container } from 'react-bootstrap';
import { ToastProvider } from 'react-toast-notifications';
import Header from '../../components/menu-header/header.container';
import SearchSidebar from '../../components/search/search-sidebar.container';
import TemplateList from './template-list';
import TemplateSidebar from './template-sidebar';
import { Row, Col } from 'react-bootstrap';

function Template() {
  const [currentFolder, setCurrentFolder] = useState('');
  const handleCurrentFolder = (key) => {
    setCurrentFolder(key);
  } 
  return (
    <ToastProvider>
      <SearchSidebar pageWrapId={'page-wrap'} outerContainerId={'App'} />
      <Container fluid id="page-wrap">
        <Header />
        <Row>
          <Col md={4} lg={4}>
            <TemplateSidebar currentFolder={currentFolder} handleCurrentFolder={handleCurrentFolder}/>
          </Col>
          <Col md={8} lg={8}>
            <TemplateList currentFolder={currentFolder} />
          </Col>
        </Row>
      </Container>
    </ToastProvider>
  );
}

export default Template;