
import React, { useEffect, useState } from 'react';
import TemplateItem from './template-item';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import {
  templateListFetchStart,
  templateDeleteFetchStart,
  templateEditFetchStart,
} from '../../actions/template';
import {
  historyListFetchStart
} from '../../actions/history';
import TemplateEmail from './template-email.js';
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from '@createnl/grouped-checkboxes';

const TemplateList = props => {
    const dispatch = useDispatch();
    //const [checkboxes, setCheckboxes] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const templateSaveList = useSelector(state =>  state.templateList.data);
    const [currentFolder, setCurrentFolder] = useState(props.currentFolder);
    const [currentTemplate, setCurrentTemplate] = useState({});
    useEffect(() => {
        dispatch(templateListFetchStart());
        dispatch(historyListFetchStart());
        setCurrentFolder(props.currentFolder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch, props.currentFolder]);

    const deleteTemplate = template => {
        dispatch(templateDeleteFetchStart(template));
    };
    
    const editSaveTemplate = template => {
        setOpenEdit(true);
        dispatch(templateEditFetchStart(template));
    };

    const openEditModal = (state, template = null) => {
      setOpenEdit(state);
      dispatch(templateListFetchStart());
      setCurrentTemplate(template);
    }

    const onCheckboxChange = (checks) => {
      //setCheckboxes(checks);
    } 
    const renderList = () => {
        if (templateSaveList) {
          return templateSaveList.map((item, index) => {
            if (currentFolder === item.templateFolder){
              return (
                <div key={index} className="template-list article-list-item">
                  <Checkbox value={item}/>
                  <TemplateItem
                    index={index}
                    item={item}
                    key={index}
                    openEditModal={openEditModal}
                    confirmDeleteAction={deleteTemplate}
                    editSaveTemplateAction={editSaveTemplate}
                  />
                </div>
              );
            }
            return null;
          });
        }
    };
    return (
        <>
        <Card>
          <Card.Body className="template-list">
            <Card.Title>Templates <TemplateEmail currentFolder={currentFolder} currentTemplate={currentTemplate} openModal={openEdit} handleOpenModal={openEditModal} /></Card.Title>
            <CheckboxGroup onChange={onCheckboxChange}>
              <label>
                <AllCheckerCheckbox />
                &nbsp;Select All
              </label>
              {renderList()}
            </CheckboxGroup>
          </Card.Body>
        </Card>
        </>
    )
}

export default TemplateList;