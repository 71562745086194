import axios from 'axios';

import config from '../config';
import { ApiError, Status } from '../classes/ApiError';

export class HttpService {
  constructor() {
    this.service = axios.create({
      baseURL: config.api.baseUrl,
      responseType: 'json',
    });
  }

  async request(requestConfig) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (!requestConfig.headers) {
      requestConfig.headers = {};
    }

    if (user) {
      requestConfig.headers = { Authorization: 'Basic ' + user.token };
    }

    try {
      const response = await this.service.request(requestConfig);
      return this.handleSuccess(response);
    } catch (e) {
      this.handleError(e);
    }
  }

  handleSuccess(response) {
    return response.data;
  }

  handleError(error) {
    let message;
    let status;
    let response;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      response = error.response.data;
      status = error.response.status;
      message = `${status} - ${response.title}`;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      message = 'Connection problem';
      status = Status.CONNECTION;
    } else {
      // Something happened in setting up the request that triggered an Error
      message = error.message || 'Problem with request';
      status = Status.REQUEST;
    }

    throw new ApiError(status, response, error, `API Error: ${message}`);
  }

  async get(path, queryParams) {
    return this.request({
      method: 'get',
      url: path,
      params: queryParams,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async post(path, body) {
    return this.request({
      method: 'post',
      url: path,
      data: body,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async put(path, body) {
    return this.request({
      method: 'put',
      url: path,
      data: body,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async delete(path, body) {
    return this.request({
      method: 'delete',
      url: path,
      data: body,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async update(path, body) {
    return this.request({
      method: 'put',
      url: path,
      data: body,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

const httpService = new HttpService();

export default httpService;
