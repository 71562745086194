/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Spinner, Alert } from 'react-bootstrap';

import './styles.css';

const Register = props => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');

  const handleSubmit = event => {
    setValidated(false);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) setValidated(true);
    else {
      props.registerFetchStart({ name: name, email: email, password: password });
    }
  };

  return (
    <Col>
      <Row>
        <Col className="auth-header">
          <img alt="logo" src="./logo.png" />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="auth-body">
          <Card>
            <Card.Header>Register to our platform</Card.Header>
            <Card.Body>
              {props.registerSuccess === false && (
                <Alert variant="danger">This email is already in use by other user.</Alert>
              )}
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    required
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    You must enter a name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    required
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    You must enter your email.
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    minLength={6}
                    required
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                  {!password && (
                    <Form.Control.Feedback type="invalid">
                      You must enter your password.
                    </Form.Control.Feedback>
                  )}
                  {password && password.length < 6 && (
                    <Form.Control.Feedback type="invalid">
                      You must enter a password with min 6 character.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicRePassword">
                  <Form.Label>Re-type Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Re-type Password"
                    minLength={6}
                    required
                    onChange={e => {
                      setRepassword(e.target.value);
                    }}
                  />
                  {!repassword && (
                    <Form.Control.Feedback type="invalid">
                      You must re-type your password.
                    </Form.Control.Feedback>
                  )}
                  {repassword !== password && (
                    <Form.Control.Feedback type="invalid">
                      Passwords doesn't match.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicLabel">
                  <Form.Label>
                    Are you already register in the platform? Click <Link to={'/login'}>here</Link>{' '}
                    to login.
                  </Form.Label>
                </Form.Group>
                <Button variant="primary" block type="submit" disabled={props.isLoading}>
                  {props.isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        className="login-spinner"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      <span>Registering...</span>
                    </>
                  ) : (
                    'Register'
                  )}
                </Button>
                <br></br>
                {props.registerSuccess && 
                    (
                      <div class="alert alert-success" role="alert">
                        Registration Successfully! To complete the process verify you email! 
                      </div>
                    )
                  }
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

Register.propTypes = {
  isLoading: PropTypes.bool,
  registerSuccess: PropTypes.bool,
  registerFetchStart: PropTypes.func
};

export default Register;
