import {
    TERM_MODAL_LIST_FETCH_START,
    TERM_MODAL_LIST_FETCH_SUCCESS,
    TERM_MODAL_LIST_FETCH_ERROR,
  } from '../../actions/term';
  
  export const initialState = {
    isLoading: false,
    data: null,
    error: null,
  };
  
  export const termsModalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case TERM_MODAL_LIST_FETCH_START:
        return { ...state, isLoading: true };
      case TERM_MODAL_LIST_FETCH_SUCCESS:
        return {
          ...state,
          data: payload.terms,
          error: null,
        };
      case TERM_MODAL_LIST_FETCH_ERROR:
        return { ...state, data: '', error: payload.error, isLoading: false, };
      default:
        return state;
    }
  };
  