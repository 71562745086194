import { HIGHLIGHT_FETCH_LIST, HIGHLIGHT_SAVE_LIST, HIGHLIGHT_SAVE_NOTES, HIGHLIGHT_REMOVE, HIGHLIGHT_ADD } from '../../actions/highlight';

export const initialState = {
  notes: '',
  data: [],
};

export const articleHighlightReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let highlights = [];
  switch (type) {
    case HIGHLIGHT_FETCH_LIST:
      return { ...state, data: payload.highlights.data, notes: payload.highlights.notes};
    case HIGHLIGHT_REMOVE:
      highlights = state.data.filter((highlight, index)=>{
        return (highlight.id !== payload.highlight.id);
      }); 
      return { ...state, data: highlights };
    case HIGHLIGHT_ADD:
      highlights = [...state.data];
      highlights.push(payload.highlight);
      return { ...state, data: highlights};
    case HIGHLIGHT_SAVE_LIST:
      highlights = [...payload.highlights];
      return { ...state, data: highlights};
    case HIGHLIGHT_SAVE_NOTES:
      return { ...state, notes: payload.notes};
    default:
      return state;
  }
};
