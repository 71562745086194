export const Status = Object.freeze({
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  UNKNOWN: 1000,
  CONNECTION: 1001,
  REQUEST: 1002,
});

export class ApiError extends Error {
  constructor(status, response, originalError, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    this.name = 'ApiError';

    const statusValue = Object.values(Status).find(statusValue => statusValue === status);

    this.status = statusValue || Status.UNKNOWN;
    this.originalError = originalError;
    this.response = response;
    this.type = response && response.type;
  }
}
