import React from 'react';
import { Switch, Route } from 'react-router-dom';
import paths from './paths';
import Main from '../pages/main';
import Login from '../pages/auth/login.container';
import Register from '../pages/auth/register.container';
import ResetPassword from '../pages/auth/reset.password';
import RecoverPassword from '../pages/auth/recover.password';
import Favorite from '../pages/favorite/favorite';
import Template from '../pages/template/template';
import PrivateRoute from './private.route';

function Router() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path={paths.AUTH_LOGIN} component={Login} />
      <Route path={paths.AUTH_ACTIVATE} component={Login} />
      <Route path={paths.AUTH_REGISTER} component={Register} />
      <Route path={paths.SEND_RESET_PASSWORD} component={RecoverPassword} />
      <Route path={paths.RESET_PASSWORD} component={ResetPassword} />
      <Route path={paths.ARTICLE_LIST_SHARE} component={Main} />
      <Route path={paths.ARTICLE_LIST_SINGLE} component={Main} />
      <PrivateRoute path={paths.ARTICLE_LIST_SHARE_FOLDER} component={Main} />
      <PrivateRoute path={paths.ARTICLE_LIST} component={Main} />
      <PrivateRoute path={paths.FAVORITES} component={Favorite} />
      <PrivateRoute path={paths.TEMPLATES} component={Template} />
    </Switch>
  );
}

export default Router;
