import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TemplateDeleteModal from './template-delete-modal';
import './styles.css';

const TemplateItem = props => {
  const [editOpen, setEditOpen] = useState(false);
  const { item, editSaveTemplateAction, confirmDeleteAction } = props;
  const [itemName, setItemName] = useState(item.name);
  const [openDeleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    setItemName(item.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const displayModalDeleteItem = () => {
    setDeleteConfirmationModal(true);
  };

  const handleEditItem = () => {
    props.openEditModal(true, item);
  };

  const handlePressEnter = e => {
    setItemName(e.target.value);
    if (e.keyCode === 13) {
      // EDIT SEARCH NAME
      item.name = e.target.value;
      editSaveTemplateAction(item);
      setEditOpen(!editOpen);
    }
  };

  return (
      <>
      {!editOpen ? (
        <>
          <div className="template-item-buttons">
            <FontAwesomeIcon size="lg"
              icon={faTimesCircle}
              className="icon-action-template red"
              onClick={() => displayModalDeleteItem()}
            />
            &nbsp;
            <TemplateDeleteModal
              openModal={openDeleteConfirmationModal}
              confirmDeleteAction={() => confirmDeleteAction(item)}
              closeModal={value => setDeleteConfirmationModal(value)}
            />
          </div>
          <span className="article-access-item" role="button" aria-hidden="true" tabIndex="0" onClick={() => handleEditItem()}>
            {item.name}
          </span>
        </>
      ) : (
        <>
          <span className="enter-label">Press enter to save the name</span>
          <input
            type="text"
            className="enter-label-input"
            value={itemName}
            onChange={e => {
              setItemName(e.target.value);
            }}
            onKeyDown={e => handlePressEnter(e)}
          />
        </>
      )}
    </>
  );
};

TemplateItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  loadSavedTemplate: PropTypes.func,
};

export default TemplateItem;
