import HttpService from './http';
import config from '../config';
import * as moment from 'moment';
import clone from 'clone';

export class ArticleService {
  async getArticles(query) {
    const path = config.apiTakeApps.endpoints.getArticles;
    const payload = {};
    if (query.payload){
      if (query.payload.terms) {
        payload.terms = clone (query.payload.terms);
        payload.terms = payload.terms.filter( term => term.type !== 'company').map((item, index) => {
          item.related_terms = [];
          return item;
        });
        let companiesAux = query.payload.terms.filter( term => term.type === 'company').map((item, index) => {
          return item.core;
        });
        if (companiesAux.length > 0){
          if (!query.payload.filterInputs.companies){
            query.payload.filterInputs.companies=[];
          }
          companiesAux.forEach((company,index) => {
            query.payload.filterInputs.companies.push(company);
          })
        }
      }
      if (query.payload.allTerms) {
        payload.allTerms = query.payload.allTerms;
      }
      if (query.payload.category) {
        let tab = query.payload.category;
        payload.tab = tab.toLowerCase();
      }
      if (query.payload.currentSource){
        let tab_type = query.payload.currentSource;
        payload.current_tab_type = tab_type.toLowerCase();
      }
      if (query.payload.page) {
        payload.page = query.payload.page;
        payload.per_page = 10;
      }
      
      if (query.payload.filterInputs) {
        if (payload.tab === 'news' && query.payload.filterInputs.sources) {
          let findSource = query.payload.filterInputs.sources.filter( source => payload.current_tab_type === source.id);
          payload.sources = findSource;
        }else if (query.payload.filterInputs.analysis){
          payload.sources = query.payload.filterInputs.analysis;
        }
        if (query.payload.filterInputs.customTerm)
          payload.custom_term = query.payload.filterInputs.customTerm;
        if (query.payload.filterInputs.sectors)
          payload.icb_level_id = query.payload.filterInputs.sectors.id;
        if (query.payload.filterInputs.companies)
          payload.companies = query.payload.filterInputs.companies;
        if (query.payload.filterInputs.timeframe && query.payload.filterInputs.timeframe !==0) {
          payload.timeframe = query.payload.filterInputs.timeframe;
          switch (payload.timeframe) {
            case '1': //today
              payload.from_date = new Date().toISOString();
              payload.to_date = new Date().toISOString();
              break;
            case '2': //last week
              payload.from_date = moment().subtract('days', 7).toISOString();
              payload.to_date = new Date().toISOString();
              break;
            case '3': //last month
              payload.from_date = moment().subtract('months', 1).toISOString();
              payload.to_date = new Date().toISOString();
              break;
            case '4':
              payload.from_date = query.payload.filterInputs.fromDate;
              payload.to_date = query.payload.filterInputs.toDate;
              break;
            case '5':
              payload.from_date = query.payload.filterInputs.fromDate;
              break;
            case '6':
              payload.to_date = query.payload.filterInputs.toDate;
              break;
            default:
              break;
          }
        }
      }
    }
    return HttpService.post(path, payload);
  }

  async getArticle(params) {
    const path = config.api.endpoints.getArticle.replace(':id', params.payload.id);
    return HttpService.post(path, params.payload);
  }
}

export const articleService = new ArticleService();
