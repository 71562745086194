import React, { useState }   from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { shareEmailStart } from '../../actions/share';
import useForm from 'react-hook-form';

const SearchShareFolderModal = props => {
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const [sharedEmail, setSharedEMail] = useState(false);
  const { openModal, closeModal, shareItem } = props;
  const handleClose = () => {
    closeModal(false);
    setSharedEMail(false);
  };
  
  const handleShare = () => {
    closeModal(false);
    setSharedEMail(false);
  };

  const shareEmail = values => {
    const payload = {
        linkname: shareItem.name,
        link: window.location.origin+'/article/share-folder/'+shareItem._id,
        sendto: values.sendto,
        username: 'News kiosk'
      }
    dispatch(shareEmailStart(payload));
    setSharedEMail(true);
  };

  return (
    <>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div style={{'text-align':'right'}}>
          <CopyToClipboard text={window.location.origin+'/article/share-folder/'+shareItem._id} >
            <Button variant="primary" type="button" ><img alt="Copy" width="16" height="16" src={window.location.origin+'/copy.png'} /> Copy Link</Button>
          </CopyToClipboard>
          </div>
          <form style={{'margin-top':'10px'}} onSubmit={handleSubmit(shareEmail)}>
            <input
                  className="form-control"
                  name="sendto"
                  style = {{'width':'300px','display':'inline', 'margin-right':'10px'}}
                  placeholder="Enter e-mail user"
                  defaultValue=""
                  ref={register({
                    required: 'This field is required',
                  })}
            />
            <Button style={{'float':'right'}} variant="primary" type="submit">
              Share E-mail
            </Button>
            <label style={{'color':'green'}}>{(sharedEmail) ? 'E-mail sent successfully!':''}</label>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleShare}>
            Ok
          </Button>
          <Button variant="primary" type="button" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SearchShareFolderModal.propTypes = {
  closeModal: PropTypes.func,
  openModal: PropTypes.bool,
  shareItem: PropTypes.object,
};

export default SearchShareFolderModal;
