import React from 'react';
import { Row, Col } from 'react-bootstrap';
const TemplateEmailPreview = (props) =>{
    const { draftEmail } = props;
    const getSource= (sourceKey, sources) => {
        let name = '';
        sources.forEach((source, index) => {
            if (source.id === sourceKey){
                name = source.label;
            }
        });
        return name;
    }
    const printArticles = () =>{
        if(draftEmail.items){
            return draftEmail.items.map((item, index)=>{
                return(<>
                    <div className="article-list-item" style={{width:'95%'}}>
                        <Row>
                            <Col className="article-list-subtext">{getSource(item.filters.currentSource, item.filters.filterInputs.sources)}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <div dangerouslySetInnerHTML={{__html: printSummary(item.filters.filterInputs.article, item)}}></div>
                            </Col>
                        </Row>
                    </div>
                </>)
            });
        }
    }

    const printSummary = (item, article) => {
        let text = '';
        if (item.highlights){
          text +=`
            <table border="0" style="border: 1px solid #ccc; margin: 10px 10px 10px 10px;" cellpadding="0" cellspacing="0" width="100%" style="max-width: 700px;">
                <tr>
                    <td style="font-size: 18px;cursor: pointer;color: #007bff;text-decoration:underline;padding: 10px 10px 10px 10px;">`+article.name+`</td>
                </tr>
                <tr>
                    <td style="padding: 20px 20px 20px 20px; color: #333; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 12px; font-weight: 400; ">
          `;
          if (item.highlights.notes){
            text += item.highlights.notes;
          }
          if (item.highlights.data){
            item.highlights.data.forEach((highlight, index)=>{
              text += `<p><b>`+highlight.content+`</b></p>`;
              text += `<p style="padding-left:10px"><i>`+highlight.notes+`</i></p>`
            });
          }
          text +=`
                    </td>
                </tr>
            </table>
          `;
        }
        return text;
    }

    return(
        <>
            <div className="preview-draft" dangerouslySetInnerHTML={{__html: draftEmail.body}}>
            </div>
            <div>
                {printArticles()}
            </div>
        </>
    )
}

export default TemplateEmailPreview;