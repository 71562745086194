import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import article from './article';
import { filteredTopicFetchStart } from '../../actions/topic';
import { termsArticleListFetchStart } from '../../actions/term';

const mapStateToProps = state => ({
  filteredTopic: state.filteredTopics.data,
  isLoading: state.filteredTopics.isLoading,
  terms: state.termsList.data,
  selectedTerms: state.selectedTerms.data,
  termsArticle: state.termsArticle.data,
  termsArticleIsLoading: state.termsArticle.isLoading,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ filteredTopicFetchStart, termsArticleListFetchStart}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(article);
