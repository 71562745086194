export const SHARE_EMAIL_START = '[share] share email start';
export const SHARE_EMAIL_SUCCESS = '[share] share email success';
export const SHARE_EMAIL_ERROR = '[share] share email error';

export const shareEmailStart = query => ({
  type: SHARE_EMAIL_START,
  payload: query,
});

export const shareEmailSuccess = response => ({
  type: SHARE_EMAIL_SUCCESS,
  payload: { response },
});

export const shareEmailError = error => ({
  type: SHARE_EMAIL_ERROR,
  payload: { error },
});