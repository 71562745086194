/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { scaleRotate as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SearchItemHistory from './search-item-history';
import SearchShareAcceptModal from './search-share-accept-modal';
import { useToasts } from 'react-toast-notifications';
import { Button } from 'react-bootstrap';
import Loading from '../../components/loading';
import {getTermsText, getCompaniesText, getSectorText, getSourcesText, getAnalystsText} from './search-utils';
import './../styles.css';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { articleDetailSavedTerms} from '../../actions/article';
import { searchCleanMessage, searchEditFetchStart } from '../../actions/search';
import { historyEditFetchStart } from '../../actions/history';
import {searchFolderListFetchStart} from '../../actions/search-folder';

const postsPerPage = 5;
let arrayForHoldingPosts = [];

const SearchSidebar = props => {
  const dispatch = useDispatch();
  const {
    searchActionResponse,
    historySaveList,
    historySaveFetchStart,
    populateCurrentSavedSelection,
    articleListFetchStart,
    searchCopyFetchStart,
    searchFolderCopyFetchStart,
    termSetTypeRefresh,
    termSaveSelected,
  } = props;
  const history = useHistory();
  const { shareId, shareFolderId, article_id } = useParams();
  const copiedSearch = useSelector(state => state.searchCopy.response);
  const [postsToShow, setPostsToShow] = useState([]);
  const [historyFirst, setHistoryFirst] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showViewMore, setShowViewMore] = useState(true);
  const [next, setNext] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchShareOpen, setSearchShareOpen] = useState(true);
  const { addToast } = useToasts();

  useEffect(() => {
    if (shareId) {
      importShareSearch(shareId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (copiedSearch && (shareId || article_id )){
      loadSavedSearch(copiedSearch.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[copiedSearch]);

  useEffect(() => {
    props.searchListFetchStart();
    props.historyListFetchStart();
    dispatch(searchFolderListFetchStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.historyListFetchStart]);

  useEffect(() => {
    if (searchActionResponse && searchActionResponse.message) {
      addToast(searchActionResponse.message, {
        appearance: searchActionResponse.type,
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      dispatch(searchCleanMessage())
    }
  // eslint-disable-next-line
  }, [searchActionResponse, addToast]);


  const loopWithSlice = (start, end) => {
    if(historySaveList){
      const slicedPosts = historySaveList.slice(start, end);
      arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
      setPostsToShow(arrayForHoldingPosts);
    }
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  const loadSavedSearch = search => {
    termSetTypeRefresh('filter');
    termSaveSelected(search.filters.terms, 'data');
    populateCurrentSavedSelection(search);
    articleListFetchStart(search.filters);
    setSidebarOpen(false);
    const payload = {
      date: new Date(),
      name: search.name,
      filters: {
        terms: search.filters.terms,
        category: search.filters.category,
        filterInputs: search.filters.filterInputs,
        currentSource: search.filters.currentSource,
      },
    };
    historySaveFetchStart(payload);
    if (search.filters.filterInputs.article){
      history.push('/articles/' + search.filters.filterInputs.article.id);
      dispatch(articleDetailSavedTerms({
        id: search.filters.filterInputs.article.id,
        user: search.user,
        refTopicArticle: search.filters.filterInputs.article.refTopicArticle,
        terms: {
          selectedTerms: search.filters.filterInputs.article.selectedTerms,
          otherTerms: search.filters.filterInputs.article.otherTerms
        },
        highlights: search.filters.filterInputs.article.highlights
      }));
    }

  };

  const setNotifications = (type, item) => {
    item.notification = (!item.notification) ? true : !item.notification;
    dispatch((type === 'history') ? dispatch(historyEditFetchStart(item)): dispatch(searchEditFetchStart(item)));
  }

  const loadSavedHistory = history => {
    termSetTypeRefresh('filter');
    termSaveSelected(history.filters.terms, 'data');
    populateCurrentSavedSelection(history);
    articleListFetchStart(history.filters);
    setSidebarOpen(false);
  };

  const importShareSearch = (id) => {
    setSearchShareOpen(false);
    searchCopyFetchStart(id);
  };

  const confirmShareFolderSearch = (id) => {
    setSearchShareOpen(false);
    searchFolderCopyFetchStart(id);

  };

  const renderListHistory = () => {
    if (postsToShow) {
      return postsToShow.map((item, index) => {
        return (
          <SearchItemHistory
            index={index}
            item={item}
            key={index}
            loadSavedHistory={loadSavedHistory}
            setNotifications={setNotifications}
          />
        );
      });
    }
  };

  const findSearchHistory = () => {
    let findSearch = [];
    setIsLoading(true);
    setPostsToShow([]);
    setShowViewMore(false);
    setTimeout(() => {
      if(historySaveList){
        findSearch = historySaveList.filter(function(item){
          let find = false;
          var re = new RegExp(searchValue,"gi");
          let text = getTermsText(item)+getCompaniesText(item)+getSectorText(item)+getSourcesText(item)+getAnalystsText(item);
          find = text.search(re);
          return (find !==-1) ? true: false;
        });
      }
      setShowViewMore((findSearch.length <= postsPerPage) ? false : true);
      setPostsToShow(findSearch);
      setIsLoading(false);
    }, 500);

  }
  const fieldSetSearchValue = (event) => {
    setSearchValue(event.target.value);
    return null;
  }

  var isMenuOpen = function(state) {
    setSidebarOpen(state.isOpen);
    return sidebarOpen;
  };

  if (historySaveList && historySaveList.length > 0 && historyFirst !== historySaveList[0].date){
    setHistoryFirst(historySaveList[0].date);
    arrayForHoldingPosts = historySaveList.slice(0, postsPerPage);
    setPostsToShow(arrayForHoldingPosts);
  }

  return (

    <Menu disableAutoFocus {...props} right width={'350px'} isOpen={sidebarOpen} onStateChange={isMenuOpen}>
      <a id="articles" className="menu-item" href="/articles">Home</a>
      <a id="favorites" className="menu-item" href="/favorites">Favorites</a>
      <a id="templates" className="menu-item" href="/templates">Templates</a>
      <span>
        <FontAwesomeIcon icon={faSearch} /> History
      </span>
      <input style={{display:'inline', maxWidth:'200px'}} className="form-control" type="text" value={searchValue} onChange={fieldSetSearchValue} placeholder="Search History" />
      <Button onClick={() => findSearchHistory()} className="pull-right">
        <FontAwesomeIcon icon={faSearch} /> Search
      </Button>
      {renderListHistory()}
      {
        showViewMore ? <span role="button" aria-hidden="true" onClick={handleShowMorePosts}>View More...</span> : null
      }
      {
        ((postsToShow.length === 0 && !isLoading) ) ? <h6><span role="button" >No results...</span></h6> : null
      }
      { isLoading ? <Loading /> : null}
      { (shareFolderId && searchShareOpen) ?
          <SearchShareAcceptModal
            openModal={searchShareOpen}
            confirmShareAction={() => confirmShareFolderSearch(shareFolderId)}
            closeModal={value => setSearchShareOpen(value)}
          />
        : null
      }
    </Menu>
  );
};

SearchSidebar.propTypes = {
  searchSaveList: PropTypes.array,
  historySaveList: PropTypes.array,
  articleListFetchStart: PropTypes.func,
  populateCurrentSavedSelection: PropTypes.func,
  termListFetchStart: PropTypes.func,
  searchListFetchStart: PropTypes.func,
  historyListFetchStart: PropTypes.func,
  historySaveFetchStart: PropTypes.func,
  searchDeleteFetchStart: PropTypes.func,
  searchCopyFetchStart: PropTypes.func,
  searchFolderCopyFetchStart: PropTypes.func,
  searchActionResponse: PropTypes.object,
  termSetTypeRefresh: PropTypes.func,
  termSaveSelected: PropTypes.func
};

export default SearchSidebar;

