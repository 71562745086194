import {
    HISTORY_LIST_FETCH_START,
    HISTORY_LIST_FETCH_SUCCESS,
    HISTORY_LIST_FETCH_ERROR,
  } from '../../actions/history';
  
  export const initialState = {
    isLoading: false,
    data: null,
    error: null,
    response: null,
  };
  
  export const historyListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case HISTORY_LIST_FETCH_START:
        return { ...state, isLoading: true };
      case HISTORY_LIST_FETCH_SUCCESS:
        return {
          ...state,
          data: payload.histories,
          error: null,
          isLoading: false,
        };
      case HISTORY_LIST_FETCH_ERROR:
        return { ...state, data: '', error: payload.error };
      default:
        return state;
    }
  };
  