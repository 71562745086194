import {
  TEMPLATE_FOLDER_LIST_FETCH_START,
  TEMPLATE_FOLDER_LIST_FETCH_SUCCESS,
  TEMPLATE_FOLDER_LIST_FETCH_ERROR,
  TEMPLATE_FOLDER_COPY_FETCH_SUCCESS,
  TEMPLATE_FOLDER_DELETE_FETCH_SUCCESS,
  TEMPLATE_FOLDER_EDIT_FETCH_SUCCESS,
} from '../../actions/template-folder';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  response: null,
};

export const templateFolderListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TEMPLATE_FOLDER_LIST_FETCH_START:
      return { ...state, isLoading: true };
    case TEMPLATE_FOLDER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.folders,
        error: null,
        isLoading: false,
      };
    case TEMPLATE_FOLDER_LIST_FETCH_ERROR:
      return { ...state, data: '', error: payload.error };
    case TEMPLATE_FOLDER_DELETE_FETCH_SUCCESS:
      return {
        ...state,
        data: state.data.filter(folder => folder._id !== payload.folder.data),
        response: payload.folder,
        error: null,
        isLoading: false,
      };
    case TEMPLATE_FOLDER_COPY_FETCH_SUCCESS:
      return {
        ...state,
        data: state.data,
        response: payload.folder,
        error: null,
        isLoading: false,
      };
    case TEMPLATE_FOLDER_EDIT_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updateIndex = state.data.findIndex(folder => folder._id === payload.folder.data._id);
      state.data[updateIndex] = payload.folder.data;
      return {
        ...state,
        data: state.data,
        response: payload.folder,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
