export const getFirstPartPV = sentence => {
  let arr = sentence.sentence;
  let res = arr.slice(0, sentence.positionVerb);
  if (sentence.level !== 'P')
    return sentence.sentence;
  return res.length > 0 ? res : '';
};

export const getSecondPartPV = sentence => {
  let arr = sentence.sentence;
  let res = arr.slice(sentence.positionVerb, arr.length);
  if (sentence.level !== 'P')
    return '';
  return res.length > 0 ? res : '';
};
