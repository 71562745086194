import { TERM_SAVE_SELECTED, TERM_REFRESH_TYPE } from '../../actions/term';

export const initialState = {
  data: null,
  cache: null,
  type: null,
};

export const termsSelectedReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TERM_SAVE_SELECTED:
      return { ...state, data: payload.terms, cache: payload.cache === 'cache' ? payload.terms : state.cache };
    case TERM_REFRESH_TYPE:
      return { ...state, type: payload.type };
    default:
      return state;
  }
};
