import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { recoverFetchStart } from '../../actions/auth';

import './styles.css';

const RecoverPassword = () => {
  const dispatch = useDispatch();
  const status = useSelector(state => state.recover.status);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const handleSubmit = event => {
    setValidated(false);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) setValidated(true);
    else {
      dispatch(recoverFetchStart({ email: email }));
    }
  };
  return (
    <Col>
      <Row>
        <Col className="auth-header">
          <img alt="logo" src="./logo.png" />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="auth-body">
          <Card>
            <Card.Header>Reseting your password</Card.Header>
            <Card.Body>
              <Card.Text>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Enter your email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email"
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Form.Text className="text-muted">
                      You will receive an email with the next instructions.
                    </Form.Text>
                  </Form.Group>
                  <Button variant="primary" block type="submit">
                    Send Email
                  </Button>
                  <br></br>
                  {status && 
                    (
                      <div class="alert alert-success" role="alert">
                        E-mail sent, check you inbox! 
                      </div>
                    )
                  }
                  <Form.Group controlId="formBasicLabel" className="forgot-label">
                    <Form.Label>
                      Go back to <Link to={'/login'}>Login Page.</Link>
                    </Form.Label>
                  </Form.Group>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default RecoverPassword;
