import {
  TERM_ARTICLE_LIST_FETCH_START,
  TERM_ARTICLE_LIST_FETCH_SUCCESS,
  TERM_ARTICLE_LIST_FETCH_ERROR,
} from '../../actions/term';

export const initialState = {
  isLoading: false,
  data: { selectedTerms: [], otherTerms: []},
  error: null,
};

export const termsArticleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TERM_ARTICLE_LIST_FETCH_START:
      return { ...state, isLoading: true };
    case TERM_ARTICLE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.terms,
        error: null,
        isLoading: false,
      };
    case TERM_ARTICLE_LIST_FETCH_ERROR:
      return { ...state, data: null, error: payload.error, isLoading: false };
    default:
      return state;
  }
};
