import { call, put } from 'redux-saga/effects';

import {
  shareEmailSuccess,
  shareEmailError,
} from '../actions/share';

import { shareService } from '../services/share';

export function* shareEmail(action) {
  try {
    const response = yield call(shareService.shareEmail, action.payload);
    yield put(shareEmailSuccess(response));
  } catch (error) {
    yield put(shareEmailError(error));
  }
}