import { call, put } from 'redux-saga/effects';

import { filteredTopicFetchSuccess } from '../actions/topic';

import { topicService } from '../services/topic';

export function* filteredTopic(action) {
  const response = yield call(topicService.filteredTopic, action);
  yield put(filteredTopicFetchSuccess(response));
}
