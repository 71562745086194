import {
  REGISTER_FETCH_START,
  REGISTER_FETCH_SUCCESS,
  REGISTER_FETCH_ERROR,
} from '../../actions/auth';

export const initialState = {
  isLoading: false,
  error: null,
  success: undefined,
};

export const registerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_FETCH_START:
      return { ...state, isLoading: true };
    case REGISTER_FETCH_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
        isLoading: false,
      };
    case REGISTER_FETCH_ERROR:
      return { ...state, error: payload, success: false, isLoading: false };
    default:
      return state;
  }
};
