import HttpService from './http';
import config from '../config';

export class HistoryService {
  async getHistories() {
    const path = config.api.endpoints.getHistories.replace(':id', (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).id : null);
    return HttpService.get(path);
  }

  async saveHistory(payload) {
    payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.saveHistory;
    return HttpService.post(path, payload);
  }

  async editHistory(payload) {
    const path = config.api.endpoints.editHistory.replace(':id', payload._id);
    return HttpService.update(path, payload);
  }
}

export const historyService = new HistoryService();
