import { 
  LOGIN_FETCH_START,
  LOGIN_FETCH_SUCCESS,
  LOGIN_FETCH_ERROR,
  LOGIN_CHANGE_STATUS 
} from '../../actions/auth';

export const initialState = {
  isLoading: false,
  error: null,
  status: null,
};

export const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_FETCH_START:
      return { ...state, isLoading: true };
    case LOGIN_FETCH_SUCCESS:
      return {
        ...state,
        status: true,
        error: null,
        isLoading: false,
      };
    case LOGIN_FETCH_ERROR:
      return { ...state, error: payload, status: false, isLoading: false, };
    case LOGIN_CHANGE_STATUS:
        return { ...state, status: null };  
    default:
      return state;
  }
};
