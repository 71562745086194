export const SECTOR_LIST_FETCH_START = '[sectors] list sector fetch start';
export const SECTOR_LIST_FETCH_SUCCESS = '[sectors] list sector fetch success';
export const SECTOR_LIST_FETCH_ERROR = '[sectors] list sector fetch error';

export const sectorListFetchStart = query => ({
  type: SECTOR_LIST_FETCH_START,
  payload: query,
});

export const sectorListFetchSuccess = sectors => ({
  type: SECTOR_LIST_FETCH_SUCCESS,
  payload: { sectors },
});

export const sectorListFetchError = error => ({
  type: SECTOR_LIST_FETCH_ERROR,
  payload: { error },
});
