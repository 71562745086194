/* eslint-disable import/no-unresolved */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { IntlProvider } from 'react-intl';
import configureStore from './configureStore';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';


const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale='en'>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
