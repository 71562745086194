export const ARTICLE_LIST_FETCH_START = '[articles] list articles fetch start';
export const ARTICLE_LIST_FETCH_SUCCESS = '[articles] list articles fetch success';
export const ARTICLE_LIST_FETCH_ERROR = '[articles] list articles fetch error';

export const ARTICLE_DETAIL_FETCH_START = '[articles] get article by id fetch start';
export const ARTICLE_DETAIL_FETCH_SUCCESS = '[articles] get article by id fetch success';
export const ARTICLE_DETAIL_FETCH_ERROR = '[articles] get article by idfetch error';
export const ARTICLE_DETAIL_FETCH_REMOVE = '[articles] get article by idfetch remove';
export const ARTICLE_DETAIL_SAVED_TERMS = '[articles] update terms saved';


export const articleListFetchStart = query => ({
  type: ARTICLE_LIST_FETCH_START,
  payload: query,
});

export const articleListFetchSuccess = articles => ({
  type: ARTICLE_LIST_FETCH_SUCCESS,
  payload: { articles },
});

export const articleListFetchError = error => ({
  type: ARTICLE_LIST_FETCH_ERROR,
  payload: { error },
});

export const articleDetailFetchStart = params => ({
  type: ARTICLE_DETAIL_FETCH_START,
  payload: params,
});

export const articleDetailFetchSuccess = article => ({
  type: ARTICLE_DETAIL_FETCH_SUCCESS,
  payload: { article },
});


export const articleDetailSavedTerms = article => ({
  type: ARTICLE_DETAIL_SAVED_TERMS,
  payload: { article },
});

export const articleDetailFetchError = error => ({
  type: ARTICLE_DETAIL_FETCH_ERROR,
  payload: { error },
});

export const articleDetailFetchRemove = () => ({
  type: ARTICLE_DETAIL_FETCH_REMOVE,
  payload: { },
});