import {
  ARTICLE_DETAIL_FETCH_START,
  ARTICLE_DETAIL_FETCH_SUCCESS,
  ARTICLE_DETAIL_FETCH_ERROR,
  ARTICLE_DETAIL_FETCH_REMOVE,
  ARTICLE_DETAIL_SAVED_TERMS
} from '../../actions/article';

export const initialState = {
  isLoading: false,
  currentArticle: null,
  terms: null,
  dataSaved: null,
  savedArticle: false,
  error: null,
};

export const articleDetailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ARTICLE_DETAIL_FETCH_START:
      return { ...state, isLoading: true, currentArticle: null };
    case ARTICLE_DETAIL_FETCH_SUCCESS:
      if (!state.savedArticle)
        return { ...state, isLoading: false, currentArticle: payload.article, terms: payload.article.terms };
      else
        return { ...state, isLoading: false, savedArticle: false, currentArticle: payload.article};
    case ARTICLE_DETAIL_SAVED_TERMS:
      return { ...state, savedArticle: true, isLoading: false, terms: payload.article.terms, user: payload.article.user, dataSaved : { id: payload.article.id, refTopicArticle: payload.article.refTopicArticle, highlights: payload.article.highlights }};
    case ARTICLE_DETAIL_FETCH_ERROR:
      return { ...state, currentArticle: null, error: payload.error, isLoading: false };
    case ARTICLE_DETAIL_FETCH_REMOVE:
      return { ...state, currentArticle: null, isLoading: false, dataSaved: null, savedArticle: null };
    default:
      return state;
  }
};
