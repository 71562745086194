import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { searchSaveFetchStart } from '../../actions/search';
import Loading from './../loading';
import ReactTooltip from 'react-tooltip';
import {searchFolderListFetchStart} from '../../actions/search-folder';

export default function SaveArticle(props) {
  const dispatch = useDispatch();
  const currentArticle = useSelector(state => state.articleDetail.currentArticle);
  const currentSource  = useSelector(state => state.articleList.currentSource);
  const termsArticle  = useSelector(state => state.articleDetail.terms);
  const termsGlobal  = useSelector(state => state.termsList.data);
  const articleHighlight = useSelector(state => state.articleHighlight);
  const selectedCategory  = useSelector(state => state.selectedCategory.data);
  const isLoadingSaveSearch  = useSelector(state => state.searchList.isLoading);
  const selectedFilterInputs  = useSelector(state => state.selectedFilter);
  const folders = useSelector(state => state.searchFolderList.data);
  //const login = useSelector(state => state.login);
  const [openModal, setOpenModal] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const handleClose = () => setOpenModal(false);
  useEffect(() => {
    dispatch(searchFolderListFetchStart());
  }, [dispatch]);

  const saveSearch = values => {
    const payload = {
      name: values.search,
      searchFolder: values.folder,
      filters: {
        terms: termsGlobal.filter(term => term.selected),
        category: selectedCategory,
        allTerms: termsGlobal,
        filterInputs: {...selectedFilterInputs.inputs,
        article: {
          id: currentArticle._id,
          refTopicArticle: currentArticle.refTopicArticle._id,
          selectedTerms: termsArticle.selectedTerms,
          otherTerms: termsArticle.otherTerms,
          highlights: articleHighlight
        }},
        currentSource: currentSource

      }
    };
    dispatch(searchSaveFetchStart(payload));
    setOpenModal(false);
  };

  const listFolders = () => {
    if (folders){
      return folders.map((folder, index) => {
        return (<option value={folder._id}>{folder.name}</option>)
      });
    }
  }

  return (
    <>
      <Button
        data-for={'tooltip_register_save_button'} data-tip='1'
        variant="success"
        className="pull-right btn-margin"
        onClick={() => { setOpenModal(true);}}
      >
        <FontAwesomeIcon icon={faBookmark} /> Add to favorites
      </Button>
        <ReactTooltip
        id={'tooltip_register_save_button'}
        disable={true}
        getContent={(dataTip) =>
          <div>
            Please register to access this functionality!
          </div>
          }
        delayShow={20}
        effect='solid'
      />
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Save your article
          </Modal.Title>
        </Modal.Header>
        {!isLoadingSaveSearch && (
          <form onSubmit={handleSubmit(saveSearch)}>
            <Modal.Body>
              <label>Name</label>
              <input
                className="form-control"
                name="search"
                placeholder="Enter the name for the article"
                defaultValue={currentArticle.metainfo.title || ''}
                ref={register({
                  required: 'This field is required',
                })}
              /><br></br>
              <label>Folder</label>
              <select className="form-control" name="folder" ref={register({
                  required: 'This field is required',
                })}>
                {listFolders()}
              </select>
              <span className="modal-validation-message">
                {errors.search && errors.search.message}
              </span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save Article
              </Button>
            </Modal.Footer>
          </form>
        )}
        {isLoadingSaveSearch && <Loading />}
      </Modal>
    </>
  );
};
