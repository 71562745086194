import {
  CATEGORY_LIST_FETCH_START,
  CATEGORY_LIST_FETCH_SUCCESS,
  CATEGORY_LIST_FETCH_ERROR,
} from '../../actions/category';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

export const categoriesListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_LIST_FETCH_START:
      return { ...state, isLoading: true };
    case CATEGORY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.categories,
        error: null,
        isLoading: false,
      };
    case CATEGORY_LIST_FETCH_ERROR:
      return { ...state, data: '', error: payload.error, isLoading: false };
    default:
      return state;
  }
};
