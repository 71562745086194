export const SEARCH_FOLDER_LIST_FETCH_START = '[folders] list folder fetch start';
export const SEARCH_FOLDER_LIST_FETCH_SUCCESS = '[folders] list folder fetch success';
export const SEARCH_FOLDER_LIST_FETCH_ERROR = '[folders] list folder fetch error';

export const SEARCH_FOLDER_SAVE_FETCH_START = '[folders] save folder fetch start';
export const SEARCH_FOLDER_SAVE_FETCH_SUCCESS = '[folders] save folder fetch success';
export const SEARCH_FOLDER_SAVE_FETCH_ERROR = '[folders] save folder fetch error';

export const SEARCH_FOLDER_DELETE_FETCH_START = '[folders] delete folder fetch start';
export const SEARCH_FOLDER_DELETE_FETCH_SUCCESS = '[folders] delete folder fetch success';
export const SEARCH_FOLDER_DELETE_FETCH_ERROR = '[folders] delete folder fetch error';

export const SEARCH_FOLDER_COPY_FETCH_START = '[folders] copy folder fetch start';
export const SEARCH_FOLDER_COPY_FETCH_SUCCESS = '[folders] copy folder fetch success';
export const SEARCH_FOLDER_COPY_FETCH_ERROR = '[folders] copy folder fetch error';

export const SEARCH_FOLDER_EDIT_FETCH_START = '[folders] edit folder fetch start';
export const SEARCH_FOLDER_EDIT_FETCH_SUCCESS = '[folders] edit folder fetch success';
export const SEARCH_FOLDER_EDIT_FETCH_ERROR = '[folders] edit folder fetch error';

export const SEARCH_FOLDER_EMAIL_FETCH_START = '[folders] search folder email fetch start';
export const SEARCH_FOLDER_EMAIL_FETCH_SUCCESS = '[folders] search folder email fetch success';
export const SEARCH_FOLDER_EMAIL_FETCH_ERROR = '[folders] search folder email fetch error';

export const SEARCH_FOLDER_CURRENT_SELECTION = '[folders] load current save folder';

/// List folders actions
export const searchFolderListFetchStart = query => ({
  type: SEARCH_FOLDER_LIST_FETCH_START,
  payload: query,
});

export const searchFolderListFetchSuccess = folders => ({
  type: SEARCH_FOLDER_LIST_FETCH_SUCCESS,
  payload: { folders },
});

export const searchFolderListFetchError = error => ({
  type: SEARCH_FOLDER_LIST_FETCH_ERROR,
  payload: { error },
});

/// Saved folder actions
export const searchFolderSaveFetchStart = query => ({
  type: SEARCH_FOLDER_SAVE_FETCH_START,
  payload: query,
});

export const searchFolderSaveFetchSuccess = folders => ({
  type: SEARCH_FOLDER_SAVE_FETCH_SUCCESS,
  payload: { folders },
});

export const searchFolderSaveFetchError = error => ({
  type: SEARCH_FOLDER_SAVE_FETCH_ERROR,
  payload: { error },
});

/// Load current folder action
export const populateCurrentSavedSelection = selectedSearch => ({
  type: SEARCH_FOLDER_CURRENT_SELECTION,
  payload: { selectedSearch },
});

/// Delete folders actions
export const searchFolderDeleteFetchStart = folder => ({
  type: SEARCH_FOLDER_DELETE_FETCH_START,
  payload: folder,
});

export const searchFolderDeleteFetchSuccess = folder => ({
  type: SEARCH_FOLDER_DELETE_FETCH_SUCCESS,
  payload: { folder },
});

export const searchFolderDeleteFetchError = error => ({
  type: SEARCH_FOLDER_DELETE_FETCH_ERROR,
  payload: { error },
});

/// Copy folders actions
export const searchFolderCopyFetchStart = id => ({
  type: SEARCH_FOLDER_COPY_FETCH_START,
  payload: { id: id},
});

export const searchFolderCopyFetchSuccess = folder => ({
  type: SEARCH_FOLDER_COPY_FETCH_SUCCESS,
  payload: { folder },
});

export const searchFolderCopyFetchError = error => ({
  type: SEARCH_FOLDER_COPY_FETCH_ERROR,
  payload: { error },
});

/// Delete folders actions
export const searchFolderEditFetchStart = folder => ({
  type: SEARCH_FOLDER_EDIT_FETCH_START,
  payload: folder,
});

export const searchFolderEditFetchSuccess = folder => ({
  type: SEARCH_FOLDER_EDIT_FETCH_SUCCESS,
  payload: { folder },
});

export const searchFolderEditFetchError = error => ({
  type: SEARCH_FOLDER_EDIT_FETCH_ERROR,
  payload: { error },
});


export const searchFolderEmailStart = query => ({
  type: SEARCH_FOLDER_EMAIL_FETCH_START,
  payload: query,
});

export const searchFolderEmailSuccess = response => ({
  type: SEARCH_FOLDER_EMAIL_FETCH_SUCCESS,
  payload: { response },
});

export const searchFolderEmailError = error => ({
  type: SEARCH_FOLDER_EMAIL_FETCH_ERROR,
  payload: { error },
});