import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import Loading from './../loading';
import {searchFolderListFetchStart} from '../../actions/search-folder';
import {searchCleanMessage} from '../../actions/search';
import './styles.css';

const SaveModal = props => {
  const dispatch = useDispatch();
  const { isLoadingSaveSearch, searchSaveResponse, selectedFilterInputs } = props;
  const [openModal, setOpenModal] = useState(false);
  const folders = useSelector(state => state.searchFolderList.data);
  const { handleSubmit, register, errors } = useForm();
  const { addToast } = useToasts();
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    dispatch(searchFolderListFetchStart());
  }, [dispatch]);

  useEffect(() => {
    if (searchSaveResponse && searchSaveResponse.message) {
      addToast(searchSaveResponse.message, {
        appearance: searchSaveResponse.type,
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      dispatch(searchCleanMessage())
    }
  }, [dispatch, searchSaveResponse, addToast]);

  const saveSearch = values => {
    const payload = {
      name: values.search,
      searchFolder: values.folder,
      filters: {
        terms: props.terms.filter(term => term.selected),
        category: props.selectedCategory,
        allTerms: props.terms,
        filterInputs: props.inputs,
        currentSource: props.currentSource
      },
    };
    if (selectedFilterInputs.id) {
      payload._id = selectedFilterInputs.id;
      props.searchEditFetchStart(payload);
    } else {
      props.searchSaveFetchStart(payload);
    }
    setOpenModal(false);
  };

  const listFolders = () => {
    if (folders){
      return folders.map((folder, index) => {
        return (<option value={folder._id}>{folder.name}</option>)
      });
    }
  }

  return (
    <>
      <Button
        variant="success"
        className="pull-right btn-margin"
        onClick={() => setOpenModal(true)}
      >
        <FontAwesomeIcon icon={faSave} /> Add to favorites
      </Button>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedFilterInputs.id ? 'Do you want overwrite your search?' : 'Save your search'}
          </Modal.Title>
        </Modal.Header>
        {!isLoadingSaveSearch && (
          <form onSubmit={handleSubmit(saveSearch)}>
            <Modal.Body>
              <label>Name</label>
              <input
                className="form-control"
                name="search"
                placeholder="Enter the name of the current search"
                defaultValue={selectedFilterInputs.name || ''}
                ref={register({
                  required: 'This field is required',
                })}
              />
              <label>Folder</label>
              <select className="form-control" name="folder" ref={register({
                  required: 'This field is required',
                })} >
                {listFolders()}
              </select>
              <span className="modal-validation-message">
                {errors.search && errors.search.message}
              </span>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {selectedFilterInputs.id ? 'Edit Search' : 'Save Search'}
              </Button>
            </Modal.Footer>
          </form>
        )}
        {isLoadingSaveSearch && <Loading />}
      </Modal>
    </>
  );
};

SaveModal.propTypes = {
  searchSaveFetchStart: PropTypes.func,
  searchEditFetchStart: PropTypes.func,
  terms: PropTypes.array,
  currentSource: PropTypes.string,
  selectedCategory: PropTypes.string,
  inputs: PropTypes.object,
  isLoadingSaveSearch: PropTypes.bool,
  searchSaveResponse: PropTypes.object,
  selectedFilterInputs: PropTypes.object
};

export default SaveModal;
