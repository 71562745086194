import HttpService from './http';
import config from '../config';

export class SearchFolderService {
  async getSearchesFolders() {
    const path = config.api.endpoints.getSearchesFolders.replace(':id', (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).id : null);
    return HttpService.get(path);
  }

  async saveSearchFolder(payload) {
    payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.saveSearchFolder;
    return HttpService.post(path, payload);
  }

  async deleteSearchFolder(payload) {
    const path = config.api.endpoints.deleteSearchFolder.replace(':id', payload._id);
    return HttpService.delete(path, payload);
  }

  async copySearchFolder(payload) {
    payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.copySearchFolder;
    return HttpService.post(path, payload);
  }

  async editSearchFolder(payload) {
    const path = config.api.endpoints.editSearchFolder.replace(':id', payload._id);
    return HttpService.update(path, payload);
  }

  async searchFolderEmail(payload) {
    const path = config.api.endpoints.searchFolderEmail.replace(':id', JSON.parse(localStorage.getItem('user')).id);
    return HttpService.post(path, payload);
  }
}

export const searchFolderService = new SearchFolderService();