import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

const Timeframe = props => {
  const { inputs, setInputs } = props;
  const [fromVisible, setFromVisible] = useState(false);
  const [toVisible, setToVisible] = useState(false);

  const switchView = timeframe => {
    switch (timeframe) {
      case '4':
        setFromVisible(true);
        setToVisible(true);
        break;
      case '5':
        setFromVisible(true);
        setToVisible(false);
        break;
      case '6':
        setFromVisible(false);
        setToVisible(true);
        break;
      default:
        setFromVisible(false);
        setToVisible(false);
        break;
    }
  };

  const handleSelectChange = e => {
    setFromVisible(false);
    setToVisible(false);
    setInputs({ ...inputs, timeframe: e.target.value });
    switchView(e.target.value);
  };

  useEffect(() => {
    switchView(inputs.timeframe);
  }, [inputs]);

  return (
    <Row style={{'paddingTop': '10px'}}>
      <Col xs={2} >
        <span className="header-label">Timeframe:</span>
      </Col>
      <Col xs={4}>
        <Form.Control as="select" value={inputs.timeframe} onChange={e => handleSelectChange(e)}>
          <option value="0">All</option>
          <option value="1">Today</option>
          <option value="2">Last week</option>
          <option value="3">Last month</option>
          <option value="4">Between</option>
          <option value="5">After</option>
          <option value="6">Before</option>
        </Form.Control>
      </Col>
      <Col xs={6}>
        {fromVisible && (
          <>
            <span className="bold">From:</span>{' '}
            <DatePicker
              className="form-control"
              selected={inputs.fromDate ? new Date(inputs.fromDate) : ''}
              onChange={date => setInputs({ ...inputs, fromDate: date })}
            />
          </>
        )}
        {toVisible && (
          <>
            <span className="bold space-timeframe">To:</span>{' '}
            <DatePicker
              className="form-control"
              selected={inputs.toDate ? new Date(inputs.toDate) : ''}
              onChange={date => setInputs({ ...inputs, toDate: date })}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

Timeframe.propTypes = {
  inputs: PropTypes.object,
  setInputs: PropTypes.func
};

export default Timeframe;
