
import React, { useEffect, useState } from 'react';
import FavoriteItem from './favorite-item';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { termSaveSelected, termSetTypeRefresh } from '../../actions/term';
import {
  searchListFetchStart,
  populateCurrentSavedSelection,
  searchDeleteFetchStart,
  searchEditFetchStart,
} from '../../actions/search';
import {
  historyListFetchStart, historySaveFetchStart
} from '../../actions/history';
import { articleListFetchStart } from '../../actions/article';
import {articleDetailSavedTerms} from '../../actions/article';
import { useHistory } from 'react-router-dom';
import FavoriteEmail from './favorite-email.js';
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from '@createnl/grouped-checkboxes';

const FavoriteList = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [checkboxes, setCheckboxes] = useState([]);
    const searchSaveList = useSelector(state =>  state.searchList.data);
    const [currentFolder, setCurrentFolder] = useState(props.currentFolder);
    useEffect(() => {
        dispatch(searchListFetchStart());
        dispatch(historyListFetchStart());
        setCurrentFolder(props.currentFolder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch, props.currentFolder]);
    const loadSavedSearch = search => {
        dispatch(termSetTypeRefresh('filter'));
        dispatch(termSaveSelected(search.filters.terms, 'data'));
        dispatch(populateCurrentSavedSelection(search));
        dispatch(articleListFetchStart(search.filters));
        const payload = {
          date: new Date(),
          name: search.name,
          filters: {
            terms: search.filters.terms,
            category: search.filters.category,
            allTerms: search.filters.allTerms,
            filterInputs: search.filters.filterInputs,
            currentSource: search.filters.currentSource,
          },
        };
        dispatch(historySaveFetchStart(payload));
        if (search.filters.filterInputs.article){
          history.push('/articles/'+search.filters.filterInputs.article.id);
          dispatch(articleDetailSavedTerms({id: search.filters.filterInputs.article.id, 
            refTopicArticle: search.filters.filterInputs.article.refTopicArticle,
            user: search.user, 
            terms: {
              selectedTerms: search.filters.filterInputs.article.selectedTerms, 
              otherTerms: search.filters.filterInputs.article.otherTerms
            },
            highlights: search.filters.filterInputs.article.highlights
          }));
        }else {
          history.push('/articles/');
        }
          
    };
    const deleteSearch = search => {
        dispatch(searchDeleteFetchStart(search));
    };
    
    const editNameSearch = search => {
        dispatch(searchEditFetchStart(search));
    };
    const onCheckboxChange = (checks) => {
      setCheckboxes(checks);
    } 
    const renderList = () => {
        if (searchSaveList) {
          return searchSaveList.map((item, index) => {
            if (currentFolder === item.searchFolder){
              return (
                <div key={index} className="article-list-item">
                  <Checkbox value={item}/>
                  <FavoriteItem
                    index={index}
                    item={item}
                    key={index}
                    loadSavedSearch={loadSavedSearch}
                    confirmDeleteAction={deleteSearch}
                    editSaveSearchAction={editNameSearch}
                  />
                </div>
              );
            }
            return null;
          });
        }
    };
    return (
        <>
        <Card>
          <Card.Body>
            <h4>Favorites</h4><FavoriteEmail items={checkboxes.filter((check, index) => check.checked)} />
            <CheckboxGroup onChange={onCheckboxChange}>
              <label>
                <AllCheckerCheckbox />
                &nbsp;Select All
              </label>
              {renderList()}
            </CheckboxGroup>
          </Card.Body>
        </Card>
        </>
    )
}

export default FavoriteList;