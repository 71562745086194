import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Card, Badge, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SaveModal from './save-modal.container';
import MenuTermModal from './modal/menu-term-modal';
import Submenu from './submenu';
import './styles.css';
import {
  termListFetchStart,
  termsCustomAdded,
  termsGetType,
  termListFetchSuccess,
  termSetTypeRefresh,
  termTypeFetchSuccessAndSearch
} from '../../actions/term';
import { sectorListFetchStart } from '../../actions/sector';
import clone from 'clone';

export default function Menu () {
  const dispatch = useDispatch();
  const termsModal  = useSelector(state => state.termsModal.data);
  const currentSource  = useSelector(state => state.articleList.currentSource);
  const termsGlobal  = useSelector(state => state.termsList.data);
  const selectedCategory  = useSelector(state => state.selectedCategory.data);
  const selectedFilterInputs  = useSelector(state => state.selectedFilter.inputs);
  const selectedSearchName  = useSelector(state => state.selectedFilter.name);
  const selectedFilterTerms = useSelector(state => state.selectedFilter.terms);
  const selectedTerms = useSelector(state => state.selectedTerms.data);
  const categories = useSelector(state => state.categoriesList.data);
  const sectors = useSelector(state => state.sectorList.data);
  const termRefreshType = useSelector(state => state.selectedTerms.type);
  const [terms, setVariant] = useState(clone(termsGlobal));
  const [inputs, setInputs] = useState(selectedFilterInputs);
  const [searchError, setSearchError] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [termsModalLocal, setTermsModalLocal ] = useState(termsModal);
  
  let structure = [];
  let termsSelected = [];
  let companiesSelected = [];
  let metricsSelected = [];
  useEffect(() => {
    dispatch(termListFetchStart());
    dispatch(sectorListFetchStart());
  }, [dispatch]);

  useEffect(() => {
    if (termsGlobal && termRefreshType === 'filter') {
      dispatch(termSetTypeRefresh(''));
      updateSelectionFromFilter(selectedFilterTerms);
    }
    setVariant(clone(termsGlobal));
    setInputs(selectedFilterInputs);
    updateValueCompany(termsGlobal);
  // eslint-disable-next-line
  }, [termsGlobal, selectedTerms, selectedFilterInputs, termRefreshType]);

  const updateSelectionFromFilter = selectedTermsType => {
    if (terms){
      terms.forEach(term => {
        return (term.selected = false);
      });
    }
    selectedTermsType.selected.map(selectedTerm => {
      if (selectedTerm.custom && terms.indexOf(selectedTerm) === -1) {
        dispatch(termsCustomAdded(selectedTerm));
      } else {
        addOrRemoveTerms(selectedTerm);
      }
      return null;
    });
  };

  const updateValueCompany = terms => {
    let companies = [];
    if (terms){
      terms.forEach(item => {
        if (item.selected === true && item.type === 'company') companies.push(item.core);
      });
    }
    setInputs({ ...inputs, companies: companies });
  };

  const updateValueTerm = e => {
    setInputs({ ...inputs, customTerm: e.target.value });
  };

  const addOrRemoveTerms = term => {
    let index = -1;
    if (term.custom === true || term.type === 'metric' || term.type === 'company'){
      terms.map((item, i) => {
        if (item.core === term.core){
          index = i;
        }
        return true;
      });
      terms.splice(index,1);
    }
    setVariant(terms.map(item => (item.core === term.core ? { ...item, selected: !item.selected } : item)));
    terms.forEach(item => {
      if (item.core === term.core) return (item.selected = !item.selected);
    });
    dispatch(termListFetchSuccess(terms));
  };

  /// List searchs actions

  const searchTermType = () => {
    if (inputs.customTerm !=='')
      dispatch(termsGetType(inputs.customTerm));
      setInputs({ ...inputs, customTerm: '' });
  }

  const searchArticle = () => {
    
    if (inputs.sources.length === 0 && inputs.analysis.length === 0){
      setSearchError(true);
      return;
    }
    setSearchError(false);
    const selectedTerms = terms.filter(term => term.selected);
    const filters = {
      terms: selectedTerms,
      category: selectedCategory,
      allTerms: terms,
      filterInputs: inputs,
      currentSource: currentSource,
    }
    dispatch(termTypeFetchSuccessAndSearch({term: inputs.customTerm, filters: filters}));
    setInputs({ ...inputs, customTerm: '' });
  };

  const handlePressEnter = e => {
    if (e.keyCode === 13) {
      searchTermType();
    }
  };

  const getBadgesTerms = (type, terms) =>{
    let count = 0;
    let auxTerms = terms.map((term, index) => {
      let name = '';
      switch (term.type) {
        case 'company': 
          name = 'warning';break; 
        case 'metric': 
          name = 'success';break;
        case 'term':
          name = 'info';break;
        default: 
          name = 'info';
      }
      if (term.selected && ((type === 'term' && !term.type) || term.type === type)){
        count++;
        return (
          <Badge
            className="badge-main-search"
            variant={name}
            key={index}
            onClick={() => addOrRemoveTerms(term)}
          >
            {term.core} {(term.ticker) ? '('+term.ticker+')' : ''}<b>&nbsp;&nbsp;X</b>
          </Badge>
        );
      }
      else return null;
    });
    return (count === 0) ? null : auxTerms;
  }

  if (termsModal && !termsModalLocal){
    setTermsModalLocal(termsModal);
  }

  if (terms) {
    structure = terms.map((term, index) => {
      if (!term.custom && term.type !=='company' && term.type !=='metric')
        return (
          <Badge
            variant={'main-terms'}
            key={index}
            onClick={() => addOrRemoveTerms(term)}
          >
            {term.core}
          </Badge>
        );
        return null
    });
  }

  termsSelected = (!terms) ? null : getBadgesTerms('term',terms);
  companiesSelected =  (!terms) ? null : getBadgesTerms('company',terms);
  metricsSelected =  (!terms) ? null : getBadgesTerms('metric',terms);
  return (
    <div>
      <Card>
        <Card.Body>
          <Row xs={12}>
            <Col xs={4}></Col>
            <Col xs={4}>
              <InputGroup className="main-input-group ">
                <Form.Control id="input-main-search" type="text" value={inputs.customTerm} onChange={updateValueTerm} onKeyDown={e => handlePressEnter(e)} placeholder="Type your search" />
                <InputGroup.Append>
                  <InputGroup.Text id="button-main-search" onClick={searchTermType}><b>+</b></InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={4}></Col>
            <Col xs={4}>{structure}</Col>
            <Col xs={4}>
              <Badge
                variant={'main-terms'}
                key={'more-term'}
                onClick={() => {setShowMenuModal(true)}}
              >
              MORE...
              </Badge>
              <MenuTermModal isOpenModal={showMenuModal} onCloseModal={(showMenuModal)=> setShowMenuModal(false)} />
            </Col>
          </Row>
          <Row>
            <Col xs={4}></Col>
            <Col xs={2}>{termsSelected && (<span><b>TERMS</b></span>)}</Col>
            <Col xs={2}>{companiesSelected && (<span><b>COMPANIES</b></span>)}</Col>
            <Col xs={2}>{metricsSelected && (<span><b>METRICS</b></span>)}</Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={4}></Col>
            <Col xs={2}>{termsSelected}</Col>
            <Col xs={2}>{companiesSelected}</Col>
            <Col xs={2}>{metricsSelected}</Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={12}>
              {advancedSearch &&(
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <span
                  role="button"
                  className="advanced-search"
                  onClick={() => setAdvancedSearch(false)}
                >
                  {}- Settings
                </span>
              )}
              {!advancedSearch &&(
                <span
                  role="button"
                  className="advanced-search"
                  onClick={() => setAdvancedSearch(true)}
                >
                  + Settings
                </span>
              )}
            </Col>
          </Row>
          {advancedSearch && (
            <Submenu
              categories={categories}
              inputs={inputs}
              setInputs={setInputs}
              sectors={sectors}
            />
          )}
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col xs={9} className="showing-search-label">
              {selectedSearchName && (
                <>
                  <FontAwesomeIcon icon={faSearch} /> Showing your current saved search:
                  <strong> #{selectedSearchName}</strong>
                </>
              )}
              {searchError && (
                <>
                  <strong> Empty news and analysis sources, please select one</strong>
                </>
              )}
            </Col>
            <Col xs={3}>
              <Button onClick={() => searchArticle()} className="pull-right">
                <FontAwesomeIcon icon={faSearch} /> Search
              </Button>
              <SaveModal terms={terms} selectedCategory={selectedCategory} inputs={inputs} />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </div>
  );
};
