import React, { useState }  from 'react';
import { Container } from 'react-bootstrap';
import { ToastProvider } from 'react-toast-notifications';
import Header from '../../components/menu-header/header.container';
import SearchSidebar from '../../components/search/search-sidebar.container';
import FavoriteList from './favorite-list';
import FavoriteSidebar from './favorite-sidebar';
import { Row, Col } from 'react-bootstrap';

function Favorite() {
  const [currentFolder, setCurrentFolder] = useState('');
  const handleCurrentFolder = (key) => {
    setCurrentFolder(key);
  } 
  return (
    <ToastProvider>
      <SearchSidebar pageWrapId={'page-wrap'} outerContainerId={'App'} />
      <Container fluid id="page-wrap">
        <Header />
        <Row>
          <Col md={3}>
            <FavoriteSidebar currentFolder={currentFolder} handleCurrentFolder={handleCurrentFolder}/>
          </Col>
          <Col md={9}>
            <FavoriteList currentFolder={currentFolder} />
          </Col>
        </Row>
      </Container>
    </ToastProvider>
  );
}

export default Favorite;