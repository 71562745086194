import { call, put } from 'redux-saga/effects';

import {
  searchFolderListFetchStart,
  searchFolderListFetchSuccess,
  searchFolderSaveFetchSuccess,
  searchFolderSaveFetchError,
  searchFolderDeleteFetchSuccess,
  searchFolderDeleteFetchError,
  searchFolderCopyFetchSuccess,
  searchFolderCopyFetchError,
  searchFolderEditFetchSuccess,
  searchFolderEditFetchError,
  searchFolderEmailSuccess
} from '../actions/search-folder';

import { searchFolderService } from '../services/search-folder';

export function* emailSearchFolder(action){
  const response = yield call(searchFolderService.searchFolderEmail, action);
  yield put(searchFolderEmailSuccess(response));
}

export function* getSearchesFolders(action) {
  const response = yield call(searchFolderService.getSearchesFolders, action);
  yield put(searchFolderListFetchSuccess(response));
}

export function* saveSearchFolder(action) {
  try {
    const response = yield call(searchFolderService.saveSearchFolder, action.payload);
    yield put(searchFolderSaveFetchSuccess(response));
    yield put(searchFolderListFetchStart());
  } catch (error) {
    yield put(searchFolderSaveFetchError(error));
  }
}

export function* deleteSearchFolder(action) {
  try {
    const response = yield call(searchFolderService.deleteSearchFolder, action.payload);
    yield put(searchFolderDeleteFetchSuccess(response));
  } catch (error) {
    yield put(searchFolderDeleteFetchError(error));
  }
}

export function* copySearchFolder(action) {
  try {
    const response = yield call(searchFolderService.copySearchFolder, action.payload);
    yield put(searchFolderCopyFetchSuccess(response));
    yield put(searchFolderListFetchStart());
  } catch (error) {
    yield put(searchFolderCopyFetchError(error));
  }
}

export function* editSearchFolder(action) {
  try {
    const response = yield call(searchFolderService.editSearchFolder, action.payload);
    yield put(searchFolderEditFetchSuccess(response));
  } catch (error) {
    yield put(searchFolderEditFetchError(error));
  }
}
