import {
  EDIT_USER_FETCH_START,
  EDIT_USER_FETCH_SUCCESS,
  EDIT_USER_FETCH_ERROR,
} from '../../actions/user';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_USER_FETCH_START:
      return { ...state, isLoading: true };
    case EDIT_USER_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.result,
        error: null,
        isLoading: false,
      };
    case EDIT_USER_FETCH_ERROR:
      return {
        ...state,
        data: null,
        error: payload.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
