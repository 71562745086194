import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArticleAccordionSentence from './article-accordion-sentence';
import ArticleAccordionSearch from './article-accordion-search';
import AccordionGroup from './accordion-group';
import Loading from '../../components/loading';

const FullTopicView = props => {

  function handleAccordionsState(newValue) {
    setAccordionsState(newValue);
  }
  function checkExist(headingsArray, id) {
    let exists = false;
    headingsArray.forEach((element, i) =>{
      if (element === id){
        exists = true;
      }
    });
    return exists;
  }
  const isOwnerArticle = (id) =>{
    let user = JSON.parse(localStorage.getItem('user'));
    return (user && user.id === id) ? true : false;
  }

  const getHeadingsToShow = (topics) => {
    let headingsArray = [];
    topics.forEach((topic, index) => {
      if (topic.hasTerm){
        topic.sentences.forEach((sentence, index) => {
          if (!checkExist(headingsArray, sentence.dataParentId))
            headingsArray.push(sentence.dataParentId);
        });
      }
    });
    headingsArray.forEach((heading, index) =>{
      let headingParent = heading;
      while (headingParent){
        let headingParentAux = headingParent;
        headingParent = null;
        for (let i = 0; i < topics.length; i++){
          for(let w = 0; w < topics[i].sentences.length; w++){
            if (topics[i].sentences[w].dataParentId && !checkExist(headingsArray, topics[i].sentences[w].dataParentId) && headingParentAux === topics[i].sentences[w]._id){
              headingsArray.push(topics[i].sentences[w].dataParentId);
              headingParent = topics[i].sentences[w].dataParentId;
            }
          }
        }
      }
    });
    return headingsArray;
  }

  const findSearchTermHeading = (sentences, headingsToShow) => {
    let found = false;
    sentences.forEach((sentence,index) => {
      if (checkExist(headingsToShow, sentence._id)){
        found = true;
      }
    });
    return found;
  };

  const findHighlightInTopic = (sentences, highlights) => {
    let find = false;
    if (highlights && highlights.length > 0 ){
      sentences.forEach((sentence, index) => {
        highlights.forEach((highlight, index) => {
          if (highlight.id === sentence._id){
            find = true;
          }
        });
      });
    }
    return find;
  }

  const insideAccordionGroup = (topics, highlights, headingsToShow, index) => {
    let countGroup = 0;
    let countGroupDesc = 0;
    let inGroup = true;
    let i = index;
    if (findHighlightInTopic(topics[i].sentences, highlights) || findSearchTermHeading(topics[i].sentences, headingsToShow)){
      return false;
    }
    if (index === 0 || index === topics.length -1) return false;
    while (inGroup && i < topics.length -1){
      if (!topics[i].hasTerm && !topics[i+1].hasTerm && !topics[i-1].hasTerm
        && !findSearchTermHeading(topics[i+1].sentences, headingsToShow)
        && !findSearchTermHeading(topics[i-1].sentences, headingsToShow)
        && !accordionsState[i+1] && !accordionsState[i-1])
        countGroup++;
      else
        inGroup = false;
      i++;
    }
    inGroup = true;
    i = index;
    while (inGroup && i > 0){
      if (!topics[i].hasTerm && !topics[i+1].hasTerm && !topics[i-1].hasTerm
        && !findSearchTermHeading(topics[i+1].sentences, headingsToShow)
        && !findSearchTermHeading(topics[i-1].sentences, headingsToShow)
        && !accordionsState[i+1] && !accordionsState[i-1])
        countGroupDesc++;
      else
        inGroup = false;
      i--;
    }
    return (countGroup > 1 || countGroupDesc > 1 ) ? true : false;
  }

  const [accordionsState, setAccordionsState ] = useState([]);
  let accordions = [];
  let accordionsGroup = [];
  let headingsToShow = [];
  if (!props.isLoading) {
    headingsToShow = getHeadingsToShow(props.content.topics);
    props.content.topics.forEach((topic, index) => {
      const customStyleInline = (!topic.hasTerm &&
        ((props.content.topics[index+1] && props.content.topics[index+1].hasTerm) ||
          (props.content.topics[index-1] && props.content.topics[index-1].hasTerm)) ) ? 1 : 0;
      const content =
        props.search && props.content.hasFilterResults ? (
          <ArticleAccordionSearch
            key={index}
            index={index}
            topic={topic}
            isOwnerArticle={isOwnerArticle(props.ownerArticle)}
            customStyleInline = {customStyleInline}
            terms={props.terms}
            highlights={props.content.highlights}
            articleHighlights={props.articleHighlights}
            hasCustomSearchResult={props.hasCustomSearchResult}
            accordionsState={accordionsState}
            onHandleAccordionsState={handleAccordionsState}
            headingsToShow = {headingsToShow}
          />
        ) : (
          <ArticleAccordionSentence isOwnerArticle={isOwnerArticle(props.ownerArticle)} key={index} topic={topic} articleHighlights={props.articleHighlights} />
        );
      if (insideAccordionGroup(props.content.topics, props.articleHighlights, headingsToShow, index) && props.hasCustomSearchResult){
        accordionsGroup.push(content);
      }else{
        if (accordionsGroup.length > 0){
          let contentGroup = (<AccordionGroup accordionsGroup={accordionsGroup} />);
          accordions.push(contentGroup);
          accordionsGroup = [];
        }
        accordions.push(content);
      }
    });
    return accordions;
  } else {
    if (accordionsState.length > 0)
      setAccordionsState([]);
    return <Loading />;
  }
};

FullTopicView.propTypes = {
  ownerArticle: PropTypes.string,
  content: PropTypes.object,
  search: PropTypes.bool,
  terms: PropTypes.array,
  hasCustomSearchResult: PropTypes.bool
};

FullTopicView.defaultProps = {
  content: {},
  search: false,
  terms: [],
  hasCustomSearchResult: false
};

export default FullTopicView;
