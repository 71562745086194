import HttpService from './http';
import config from '../config';

export class TemplateFolderService {
  async getTemplatesFolders() {
    const path = config.api.endpoints.getTemplatesFolders.replace(':id', (JSON.parse(localStorage.getItem('user'))) ? JSON.parse(localStorage.getItem('user')).id : null);
    return HttpService.get(path);
  }

  async saveTemplateFolder(payload) {
    payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.saveTemplateFolder;
    return HttpService.post(path, payload);
  }

  async deleteTemplateFolder(payload) {
    const path = config.api.endpoints.deleteTemplateFolder.replace(':id', payload._id);
    return HttpService.delete(path, payload);
  }

  async copyTemplateFolder(payload) {
    payload.user = JSON.parse(localStorage.getItem('user')).id;
    const path = config.api.endpoints.copyTemplateFolder;
    return HttpService.post(path, payload);
  }

  async editTemplateFolder(payload) {
    const path = config.api.endpoints.editTemplateFolder.replace(':id', payload._id);
    return HttpService.update(path, payload);
  }

  async templateFolderEmail(payload) {
    const path = config.api.endpoints.templateFolderEmail.replace(':id', JSON.parse(localStorage.getItem('user')).id);
    return HttpService.post(path, payload);
  }
}

export const templateFolderService = new TemplateFolderService();