import { call, put } from 'redux-saga/effects';
import {
  loginFetchSuccess,
  loginFetchError,
  registerFetchSuccess,
  registerFetchError,
  recoverFetchSuccess,
  recoverFetchError
} from '../actions/auth';

import { authService } from '../services/auth';

export function* getAuthUser(action) {
  try {
    const response = yield call(authService.getAuthUser, action);
    localStorage.setItem('user', JSON.stringify(response));
    yield put(loginFetchSuccess(response));
  } catch (error) {
    yield put(loginFetchError(error));
  }
}

export function* activateUser(action) {
  try {
    const response = yield call(authService.activateUser, action);
    localStorage.setItem('user', JSON.stringify(response));
    yield put(loginFetchSuccess(response));
  } catch (error) {
    yield put(loginFetchError(error));
  }
}

export function* resetUser(action) {
  try {
    const response = yield call(authService.resetUser, action);
    localStorage.setItem('user', JSON.stringify(response));
    yield put(loginFetchSuccess(response));
  } catch (error) {
    yield put(loginFetchError(error));
  }
}

export function* recoverUser(action) {
  try {
    const response = yield call(authService.recoverUser, action);
    yield put(recoverFetchSuccess(response));
  } catch (error) {
    yield put(recoverFetchError(error));
  }
}


export function* registerUser(action) {
  try {
    const response = yield call(authService.registerUser, action);
    yield put(registerFetchSuccess(response));
    //no logueamos al usuario lo llevamos al login ahora
    //localStorage.setItem('user', JSON.stringify(response));
  } catch (error) {
    yield put(registerFetchError(error));
  }
}
