import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './../styles.css';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import {getSearchDescription, getTermsText, getCompaniesText, getSectorText, getSourcesText, getAnalystsText, getTimeframeText } from './search-utils';

const SearchItemHistory = props => {
  const { index, item, loadSavedHistory, setNotifications } = props;
  const [itemNotification, setItemNotification] = useState(item.notification);

  useEffect(() => {
    setItemNotification(item.notification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleClick = (type, item) => {
    setNotifications('history',item);
    setItemNotification(item.notification);
  }

  return (
    <div key={index} className="menu-item">
      <span data-for={'tooltip_'+index} data-tip='1' role="button" aria-hidden="true" tabIndex="0" onClick={() => loadSavedHistory(item)}>
        <Moment format="YYYY/MM/DD HH:MM">
                {item.date}
        </Moment>
        {getSearchDescription(item)} 
      </span> <FontAwesomeIcon icon={(itemNotification !==false) ? faBell: faBellSlash } onClick={() => handleClick('history',item)} />
      <ReactTooltip 
        id={'tooltip_'+index} 
        getContent={(dataTip) => 
          <div>
            <p><span>Search terms: {getTermsText(item)}</span>&nbsp;
                <span>Companies: {getCompaniesText(item)}</span></p> 
            <p>
              <span>Sector/Industry: {getSectorText(item)}</span>&nbsp;
            </p>
            <p>
              <span>Sources:</span><br></br> 
              {getSourcesText(item)}<br></br>
              {getAnalystsText(item)}
            </p>
            
            <p>
              <span>Timeframe: {getTimeframeText(item)}</span>&nbsp;
            </p>     
          </div>
          }
        delayShow={1000}
        effect='solid'
      />
    </div>
  );
};

SearchItemHistory.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  loadSavedHistory: PropTypes.func,
  setNotifications: PropTypes.func,
};

export default SearchItemHistory;
