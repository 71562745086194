import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Alert, Button } from 'react-bootstrap';
import './../styles.css';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArticleBrowser from '../../../components/article-modal/article-browser';
import FullTopicView from '../../../components/article-modal/full-topic-view';
import Navigator from '../../../components/article-modal/navigator';
import Loading from '../../../components/loading';
import { articleConstant } from '../../../constants/article';
import { articleDetailFetchStart } from '../../../actions/article';
import { filteredTopicFetchStart } from '../../../actions/topic';
import { termsArticleListFetchStart } from '../../../actions/term';
import config from '../../../config';
import { useHistory } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import SliderHiglights from '../../../components/article-modal/slider-highlights';
import SummaryHighlights from '../../../components/article-modal/summary-highlights';
import SummaryHighlightsOnlyRead from '../../../components/article-modal/summary-highlights-only-read';
import { highlightFetchList } from '../../../actions/highlight';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ArticleModal = props => {
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState('full-text');
  let [termsToFilter, setTermsToFilter] = useState([]);
  let [customTermsToFilter, setCustomTermsToFilter] = useState([]);
  let [loadedSavedArticle, setLoadedSavedArticle] = useState(false);
  const savedArticle  = useSelector(state => state.articleDetail.savedArticle);
  const dataSaved  = useSelector(state => state.articleDetail.dataSaved);
  const articleHighlights = useSelector(state => state.articleHighlight.data);
  const ownerArticle = useSelector(state => state.articleDetail.user);
  const {
    currentArticle,
    filteredTopic,
    isLoading,
    selectedTerms,
    termsArticle,
    terms,
    termsArticleIsLoading,
  } = props;
  const history = useHistory();
  useEffect(() => {
    if (!props.currentArticle && props.currentArticleId){
      props.articleDetailFetchStart({id: props.currentArticleId, terms: selectedTerms, allTerms: terms});
    }
    if (savedArticle && !loadedSavedArticle){
      dispatch(highlightFetchList((dataSaved.highlights) ? dataSaved.highlights : {notes: '', data: []}));
      updateTopicViewFromSaved (termsArticle);
    }
  // eslint-disable-next-line
  }, [props.currentArticleId, props.currentArticle, props.articleDetailFetchStart, props, selectedTerms, terms, savedArticle]);

  useEffect(() => {

  },[dataSaved]);

  const updateTopicViewFromSaved = terms => {
    if (terms.selectedTerms){
      terms.selectedTerms.map((term, index) =>{
        updateTermsToFilterSaved(term);
        return true;
      });

    }
    if (terms.otherTerms){
      terms.otherTerms.map((term, index) =>{
        updateTermsToFilterSaved(term);
        return true;
      });

    }
    setTermsToFilter(termsToFilter);
    const allTerms = termsToFilter.concat(customTermsToFilter);
    if (!allTerms.length) {
      setViewType(articleConstant.fullTopic);
      return;
    }
    props.filteredTopicFetchStart({ id: dataSaved.refTopicArticle, article_id: dataSaved.id ,  term: allTerms });
    setViewType(articleConstant.filteredTopic);
    setLoadedSavedArticle(true)
  }

  const updateTermsToFilterSaved = term => {
    if (term.selected && !(findTermToFilter(term))) {
      termsToFilter.push(term);
    }
    term.related_terms.map((t,index)=>{
      if (t.selected && !(findTermToFilter(t))){
        termsToFilter.push(t);
      }
      return true;
    });
    term.calculated_terms.map((t,index)=>{
      if (t.selected && !(findTermToFilter(t))){
        termsToFilter.push(t);
      }
      return true;
    });
  }

  const findTermToFilter = term => {
    let find = false;
      termsToFilter.map(t => {
        if (t.core === term.core) find = true;
        return null;
      });
    return find;
  }

  const handleViewType = codeView => {
    setViewType(codeView);
  };

  const updateTermsToFilter = term => {
    if (term.selected) {
      termsToFilter = termsToFilter.filter(t => (t.core !== term.core));
    } else {
      let find = false;
      termsToFilter.map(t => {
        if (t.core === term.core) find = true;
        return null;
      });
      //acá se actualiza los terminos para enviar al endpoint y se le da formato
      if (!find){
        term.selected = true;
        termsToFilter.push(term);
      }
    }
  };

  const filterTerms = codeView => {
    const allTerms = termsToFilter.concat(customTermsToFilter);
    if (!allTerms.length) {
      setViewType(articleConstant.fullTopic);
      return;
    }
    props.filteredTopicFetchStart({ id: currentArticle.refTopicArticle._id, article_id: props.currentArticleId ,  term: allTerms });
    setViewType(codeView);
  };

  const handleFilteredTopic = (codeView, data) => {
    updateTermsToFilter(data);
    setTermsToFilter(termsToFilter);
    filterTerms(codeView);
  };

  const handleCustomFilteredTopic = (codeView, data) => {
    customTermsToFilter = [data];
    setCustomTermsToFilter(data);
    if (!data) {
      customTermsToFilter = [];
      setCustomTermsToFilter([]);
    }
    filterTerms(codeView);
  };

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleHtmlPdf = (currentArticle) => {
    const urlEndpoint = config.api.baseUrl+config.api.endpoints.getPdf+'/'+currentArticle._id;
    if (currentArticle.metainfo.type === 'html'){
      let htmlString = props.currentArticle.fullText.html;
      const htmlNode = document.createElement('div');
      htmlNode.innerHTML = htmlString;
      htmlNode.querySelectorAll('*').forEach(function(node) {
          node.removeAttribute('style');
      });
      return parse(htmlNode.innerHTML);
    }else{
      return (<div>
        <Document
          file={urlEndpoint}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {
                Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ),
                )
              }
        </Document>
      </div>);
    }
  }
  const printSummary = () =>{
    if (articleHighlights && viewType !== 'full-text'){
      return (loadedSavedArticle) ? (<SummaryHighlightsOnlyRead defaultExpanded={false} title={'Summary'}/>) : (<SummaryHighlights title={'Summary'}/>);
    }
  }
  const printView = () => {
    if (props.currentArticle){
      switch (viewType) {
        case articleConstant.fullText:
          return handleHtmlPdf(props.currentArticle);
        case articleConstant.fullTopic:
          return <>
          <Row>
              <Col md={9}>
                <FullTopicView ownerArticle={ownerArticle} articleHighlights={articleHighlights} dataSaved={dataSaved} content={props.currentArticle.refTopicArticle} structure={props.currentArticle.byTextLevel} search={false} />
              </Col>
              <Col md={3}>
                <Navigator content={props.currentArticle.refTopicArticle} isLoading={isLoading} />
              </Col>
          </Row>
          </>;
        case articleConstant.filteredTopic:
          return (
            <>
            <Row>
              <Col md={9}>
                <FullTopicView
                  ownerArticle={ownerArticle}
                  hasCustomSearchResult={filteredTopic && filteredTopic.hasCustomSearchResult}
                  content={filteredTopic}
                  articleHighlights={articleHighlights}
                  structure={props.currentArticle.byTextLevel}
                  search={true}
                  isLoading={isLoading}
                  terms={termsToFilter.concat(customTermsToFilter)}
                />
              </Col>
              <Col md={3}>
                <Navigator content={filteredTopic} hasCustomSearchResult={filteredTopic && filteredTopic.hasCustomSearchResult} isLoading={isLoading} />
              </Col>
            </Row>
            </>
          );
        default:
          return handleHtmlPdf(props.currentArticle);;
      }
    }
  };

  const contentToDisplay = printView();
  const summaryContent = printSummary();
  const closeModal = () => {
    setViewType(articleConstant.fullText);
    setTermsToFilter([]);
    props.onCloseModal(false);
    history.push('/articles');
  }
  return currentArticle && !termsArticleIsLoading ? (
      <Modal
        show={props.isOpenModal}
        onHide={()=> {closeModal(false)}}
        dialogClassName="modal-xl article-modal"
        aria-labelledby="article-modal"
      >
      <Modal.Header closeButton><strong style={{'paddingLeft': '10px'}}>Article Browser</strong></Modal.Header>
      <Modal.Body>
        <Alert show={false} variant="success">
          <Alert.Heading>Welcome to Newskiosk</Alert.Heading>
          <p>
            Please sign up to access full functionalities!
            <br></br>
            <br></br>
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() =>{ history.push('/register'); }} variant="outline-success">
              Register now!
            </Button>
          </div>
        </Alert>
        <Row style={{'height':'100%'}}>
          <Col md={8}>
            <Row>
              <Col md={12} className='topic-view-content'>
              <h5>{props.currentArticle.metainfo.title}</h5>
              <div>{contentToDisplay}</div>
              </Col>
            </Row>
            <Row>
              <Col md={12} className='highlights'>
                {summaryContent}
                <SliderHiglights />
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <ArticleBrowser
                currentTopicId={currentArticle.refTopicArticle._id}
                switchView={handleViewType}
                viewType={viewType}
                hasCustomSearchResult={filteredTopic && filteredTopic.hasCustomSearchResult}
                filteredTopic={handleFilteredTopic}
                filteredCustomTopic={handleCustomFilteredTopic}
                selectedTerms={termsArticle.selectedTerms}
                otherTerms={termsArticle.otherTerms}
                matchedTerms={currentArticle.matched_terms}
                matchedMetrics={currentArticle.matched_metrics_terms}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  ): (
    <Modal
        show={props.isOpenModal}
        onHide={()=> props.onCloseModal(false)}
        dialogClassName="modal-xl article-modal"
        aria-labelledby="article-modal"
      >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={8}>
            <Loading />
            <div>{}</div>
          </Col>
          <Col md={4}>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ArticleModal.propTypes = {
  isOpenModal: PropTypes.bool,
  currentArticleId: PropTypes.string,
  onCloseModal: PropTypes.func,
  article: PropTypes.object,
  filteredTopic: PropTypes.object,
  isLoading: PropTypes.bool,
  termsArticleIsLoading: PropTypes.bool,
  selectedTerms: PropTypes.array,
  termsArticle: PropTypes.object,
  terms: PropTypes.array,
  filteredTopicFetchStart: PropTypes.func,
  termsArticleListFetchStart: PropTypes.func,
  onOpenModal: PropTypes.func
};

ArticleModal.defaultProps = {
  article: {},
  selectedTerms: {},
  filteredTopic: {},
  isLoading: false,
  termsArticleIsLoading: false,
  termsArticle: {}
};

const mapStateToProps = state => ({
  currentArticle: state.articleDetail.currentArticle,
  filteredTopic: state.filteredTopics.data,
  isLoading: state.filteredTopics.isLoading,
  terms: state.termsList.data,
  selectedTerms: state.selectedTerms.data,
  termsArticle: state.articleDetail.terms,
  termsArticleIsLoading: state.termsArticle.isLoading,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ articleDetailFetchStart ,filteredTopicFetchStart, termsArticleListFetchStart}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleModal);
