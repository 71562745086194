import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionCustom from './accordion-custom';
import { getFirstPartPV, getSecondPartPV } from '../../utils/parser';
import clone from 'clone';

const ArticleAccordionSearch = props => {
  const [showContent, setShowContent] = useState(false);
  const [showExpanded, setShowExpanded] = useState(false);
  let structure = [];

  const handleClick = () => {
    let accordionsState = clone(props.accordionsState);
    accordionsState[props.index] = !showContent;
    props.onHandleAccordionsState(accordionsState)
    setShowContent(!showContent);
  };

  const printHeadingsMatch = (sentences, headingsToShow) => {
    let headings = [];
    sentences.forEach((sentence, index) => {
      headingsToShow.forEach((heading, index) => {
        if (sentence._id === heading){
          const headingPrint = (<section id={sentence._id} data-parent-id={sentence.dataParentId} className={sentence.level}><div className="accordion-custom-title" >{sentence.sentence}</div></section>);
          headings.push(headingPrint);
        }
      });

    });
    return headings;
  }

  if (props.articleHighlights && props.articleHighlights.length > 0 && !showExpanded){
    props.topic.sentences.forEach((sentence, index) => {
      props.articleHighlights.forEach((highlight, index) => {
        if (highlight.id === sentence._id){
          setShowExpanded(true);
          setShowContent(true);
        }
      });
    });
  }
  props.topic.sentences.forEach((sentence, index) => {
    const accordion = (
      <AccordionCustom
        id = {sentence._id}
        sentenceIndex={index}
        level = {sentence.level}
        dataParentId = {sentence.dataParentId}
        title={getFirstPartPV(sentence)}
        content={getSecondPartPV(sentence)}
        terms={props.terms}
        highlights={props.highlights}
        hasCustomSearchResult={props.hasCustomSearchResult}
        sentence={sentence.sentence}
        search={true}
        showExpanded={showExpanded}
        articleHighlights={props.articleHighlights}
        hasTerm={props.topic.hasTerm}
      />
    );
    structure.push(accordion);
  });
  const style = (props.customStyleInline) ?  'block' : 'block';
  const headings = printHeadingsMatch(props.topic.sentences, props.headingsToShow);
  if (props.topic.hasTerm)
    return <div className={`accordion-parent ${showExpanded ? "expanded" : ""}`}>{structure}</div>;
  else if (!showContent )
    return (
      <div className="accordion-parent" style={{display: style}}>
        {headings}
        {(headings.length < props.topic.sentences.length) ? (
          <span className="markup-blue" aria-hidden="true" onClick={handleClick}>
          [...]
        </span>
        ): ''}

      </div>
    );
  else
    return (
      <div className={`accordion-parent ${showExpanded ? "expanded" : ""}`} style={{display: 'block'}}>
        <div className="accordion-custom-parent">{structure}</div>
        <span className="markup-blue" style={{float: 'right'}} aria-hidden="true" onClick={handleClick}>
          [&#8598;]
        </span>
        <div className="clear-div"></div>
      </div>
    );
};

ArticleAccordionSearch.propTypes = {
  topic: PropTypes.object,
  terms: PropTypes.array,
  isOwnerArticle: PropTypes.bool,
  accordionsState: PropTypes.array,
  highlights: PropTypes.array,
  index: PropTypes.number
};

ArticleAccordionSearch.defaultProps = {
  topic: {},
  terms: [],
  accordionsState: [],
  highlights: []
};

export default ArticleAccordionSearch;
