
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { highlightSaveList, highlightSaveNotes } from '../../actions/highlight';
import useCollapse from 'react-collapsed';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import "./styles.css";

export default function SummaryHiglights(props) {
    const dispatch = useDispatch();
    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0
    };
    const [value, setValue] = useState('');
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
    const [list, setList ]= useState([]);
    const articleHighlight = useSelector(state => state.articleHighlight);
    useEffect(()=>{
      if (articleHighlight){
        setList([...articleHighlight.data]);
      }
    },[articleHighlight]);
    useEffect(()=>{
      if(articleHighlight){
        setValue(articleHighlight.notes);
      }
    // eslint-disable-next-line
    },[]);
    useEffect(()=>{
      dispatch(highlightSaveNotes(value));
    // eslint-disable-next-line
    },[value]);
    const modules = {
      toolbar: [
          [{ 'header': [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
      ],
    };
    const formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ];
    const handleChangeTextarea = (value, index) =>{
      const auxList = [...list];
      auxList[index].notes = value;
      setList(auxList);
      dispatch(highlightSaveList(auxList));
    }
return (
    <div className="collapsible">
        <div className="header" {...getToggleProps()}>
            <div className="title">{props.title}</div>
            <div className="icon">
                {( isExpanded && <FontAwesomeIcon icon={faChevronCircleDown} />)}
                {( !isExpanded && <FontAwesomeIcon icon={faChevronCircleUp} />)}
            </div>
        </div>
        <div className="summary" {...getCollapseProps()}>
          <h3>Summary</h3>
          <ReactQuill
              theme="snow"
              value={value}
              onChange={(value) => { setValue(value)}}
              modules={modules}
              formats={formats}
          >
          </ReactQuill>
          <br></br>
          {list.map((item, index) => { return (
              <li className="summary-item" key={index}>
                <div>
                  <p style={{'color':'black'}} dangerouslySetInnerHTML={{__html: item.content}}></p>
                </div>
                <div>
                  <textarea value={item.notes} onChange={event => { handleChangeTextarea(event.target.value, index) }} rows="2" style={{fontSize:'10px',color:'black'}} placeholder="Notes" ></textarea>
                </div>
              </li>
            );}
          )}
          <br></br>
        </div>
    </div>
    );
}
