import { combineReducers } from 'redux';
import { articleListReducer } from './article/article-list';
import { articleDetailReducer } from './article/article-detail';
import { articleHighlightReducer } from './highlight/highlight';
import { categoriesListReducer } from './category/categories-list';
import { categorySelectedReducer } from './category/category-selected';
import { historyListReducer } from './history/history-list';
import { historySaveReducer } from './history/history-save';
import { historyEditReducer } from './history/history-edit';
import { searchListReducer } from './search/search-list';
import { searchSaveReducer } from './search/search-save';
import { searchCopyReducer } from './search/search-copy';
import { searchDeleteReducer } from './search/search-delete';
import { searchEditReducer } from './search/search-edit';
import { searchFolderListReducer } from './search-folder/search-folder-list';
import { searchFolderEmailReducer } from './search-folder/search-folder-email';
import { searchFolderSaveReducer } from './search-folder/search-folder-save';
import { searchFolderCopyReducer } from './search-folder/search-folder-copy';
import { searchFolderDeleteReducer } from './search-folder/search-folder-delete';
import { searchFolderEditReducer } from './search-folder/search-folder-edit';
import { templateListReducer } from './template/template-list';
import { templateSaveReducer } from './template/template-save';
import { templateCopyReducer } from './template/template-copy';
import { templateDeleteReducer } from './template/template-delete';
import { templateEditReducer } from './template/template-edit';
import { templateFolderListReducer } from './template-folder/template-folder-list';
import { templateFolderEmailReducer } from './template-folder/template-folder-email';
import { templateFolderSaveReducer } from './template-folder/template-folder-save';
import { templateFolderCopyReducer } from './template-folder/template-folder-copy';
import { templateFolderDeleteReducer } from './template-folder/template-folder-delete';
import { templateFolderEditReducer } from './template-folder/template-folder-edit';
import { filterInputsSelectedReducer } from './search/filter-inputs-selected';
import { sectorListReducer } from './sector/sector-list';
import { termsListReducer } from './term/terms-list';
import { termsModalReducer } from './term/terms-modal';
import { termsSelectedReducer } from './term/terms-selected';
import { termsArticleReducer } from './term/terms-article';
import { topicReducer } from './topic/topic';
import { loginReducer } from './auth/login';
import { activateReducer } from './auth/activate';
import { recoverReducer } from './auth/recover';
import { resetReducer } from './auth/reset';
import { registerReducer } from './auth/register';
import { userReducer } from './user/user';

const rootReducer = combineReducers({
  articleDetail: articleDetailReducer,
  articleList: articleListReducer,
  articleHighlight: articleHighlightReducer,
  filteredTopics: topicReducer,
  termsList: termsListReducer,
  termsModal: termsModalReducer,
  termsArticle: termsArticleReducer,
  selectedTerms: termsSelectedReducer,
  selectedFilter: filterInputsSelectedReducer,
  selectedCategory: categorySelectedReducer,
  categoriesList: categoriesListReducer,
  sectorList: sectorListReducer,
  historyList: historyListReducer,
  historySave: historySaveReducer,
  historyEdit: historyEditReducer,
  searchList: searchListReducer,
  searchSave: searchSaveReducer,
  searchCopy: searchCopyReducer,
  searchDelete: searchDeleteReducer,
  searchEdit: searchEditReducer,
  searchFolderList: searchFolderListReducer,
  searchFolderEmail: searchFolderEmailReducer,
  searchFolderSave: searchFolderSaveReducer,
  searchFolderCopy: searchFolderCopyReducer,
  searchFolderDelete: searchFolderDeleteReducer,
  searchFolderEdit: searchFolderEditReducer,
  templateList: templateListReducer,
  templateSave: templateSaveReducer,
  templateCopy: templateCopyReducer,
  templateDelete: templateDeleteReducer,
  templateEdit: templateEditReducer,
  templateFolderList: templateFolderListReducer,
  templateFolderEmail: templateFolderEmailReducer,
  templateFolderSave: templateFolderSaveReducer,
  templateFolderCopy: templateFolderCopyReducer,
  templateFolderDelete: templateFolderDeleteReducer,
  templateFolderEdit: templateFolderEditReducer,
  login: loginReducer,
  activate: activateReducer,
  reset: resetReducer,
  recover: recoverReducer,
  register: registerReducer,
  editUser: userReducer,
});

export default rootReducer;
