export const TEMPLATE_FOLDER_LIST_FETCH_START = '[folders] template list folder fetch start';
export const TEMPLATE_FOLDER_LIST_FETCH_SUCCESS = '[folders] template list folder fetch success';
export const TEMPLATE_FOLDER_LIST_FETCH_ERROR = '[folders] template list folder fetch error';

export const TEMPLATE_FOLDER_SAVE_FETCH_START = '[folders] template save folder fetch start';
export const TEMPLATE_FOLDER_SAVE_FETCH_SUCCESS = '[folders] template save folder fetch success';
export const TEMPLATE_FOLDER_SAVE_FETCH_ERROR = '[folders] template save folder fetch error';

export const TEMPLATE_FOLDER_DELETE_FETCH_START = '[folders] template delete folder fetch start';
export const TEMPLATE_FOLDER_DELETE_FETCH_SUCCESS = '[folders] template delete folder fetch success';
export const TEMPLATE_FOLDER_DELETE_FETCH_ERROR = '[folders] template delete folder fetch error';

export const TEMPLATE_FOLDER_COPY_FETCH_START = '[folders] template copy folder fetch start';
export const TEMPLATE_FOLDER_COPY_FETCH_SUCCESS = '[folders] template copy folder fetch success';
export const TEMPLATE_FOLDER_COPY_FETCH_ERROR = '[folders] template copy folder fetch error';

export const TEMPLATE_FOLDER_EDIT_FETCH_START = '[folders] template edit folder fetch start';
export const TEMPLATE_FOLDER_EDIT_FETCH_SUCCESS = '[folders] template edit folder fetch success';
export const TEMPLATE_FOLDER_EDIT_FETCH_ERROR = '[folders] template edit folder fetch error';

export const TEMPLATE_FOLDER_EMAIL_FETCH_START = '[folders] template folder email fetch start';
export const TEMPLATE_FOLDER_EMAIL_FETCH_SUCCESS = '[folders] template folder email fetch success';
export const TEMPLATE_FOLDER_EMAIL_FETCH_ERROR = '[folders] template folder email fetch error';

export const TEMPLATE_FOLDER_CURRENT_SELECTION = '[folders] load current save folder';

/// List folders actions
export const templateFolderListFetchStart = query => ({
  type: TEMPLATE_FOLDER_LIST_FETCH_START,
  payload: query,
});

export const templateFolderListFetchSuccess = folders => ({
  type: TEMPLATE_FOLDER_LIST_FETCH_SUCCESS,
  payload: { folders },
});

export const templateFolderListFetchError = error => ({
  type: TEMPLATE_FOLDER_LIST_FETCH_ERROR,
  payload: { error },
});

/// Saved folder actions
export const templateFolderSaveFetchStart = query => ({
  type: TEMPLATE_FOLDER_SAVE_FETCH_START,
  payload: query,
});

export const templateFolderSaveFetchSuccess = folders => ({
  type: TEMPLATE_FOLDER_SAVE_FETCH_SUCCESS,
  payload: { folders },
});

export const templateFolderSaveFetchError = error => ({
  type: TEMPLATE_FOLDER_SAVE_FETCH_ERROR,
  payload: { error },
});

/// Load current folder action
export const populateCurrentSavedSelection = selectedTemplate => ({
  type: TEMPLATE_FOLDER_CURRENT_SELECTION,
  payload: { selectedTemplate },
});

/// Delete folders actions
export const templateFolderDeleteFetchStart = folder => ({
  type: TEMPLATE_FOLDER_DELETE_FETCH_START,
  payload: folder,
});

export const templateFolderDeleteFetchSuccess = folder => ({
  type: TEMPLATE_FOLDER_DELETE_FETCH_SUCCESS,
  payload: { folder },
});

export const templateFolderDeleteFetchError = error => ({
  type: TEMPLATE_FOLDER_DELETE_FETCH_ERROR,
  payload: { error },
});

/// Copy folders actions
export const templateFolderCopyFetchStart = id => ({
  type: TEMPLATE_FOLDER_COPY_FETCH_START,
  payload: { id: id},
});

export const templateFolderCopyFetchSuccess = folder => ({
  type: TEMPLATE_FOLDER_COPY_FETCH_SUCCESS,
  payload: { folder },
});

export const templateFolderCopyFetchError = error => ({
  type: TEMPLATE_FOLDER_COPY_FETCH_ERROR,
  payload: { error },
});

/// Delete folders actions
export const templateFolderEditFetchStart = folder => ({
  type: TEMPLATE_FOLDER_EDIT_FETCH_START,
  payload: folder,
});

export const templateFolderEditFetchSuccess = folder => ({
  type: TEMPLATE_FOLDER_EDIT_FETCH_SUCCESS,
  payload: { folder },
});

export const templateFolderEditFetchError = error => ({
  type: TEMPLATE_FOLDER_EDIT_FETCH_ERROR,
  payload: { error },
});


export const templateFolderEmailStart = query => ({
  type: TEMPLATE_FOLDER_EMAIL_FETCH_START,
  payload: query,
});

export const templateFolderEmailSuccess = response => ({
  type: TEMPLATE_FOLDER_EMAIL_FETCH_SUCCESS,
  payload: { response },
});

export const templateFolderEmailError = error => ({
  type: TEMPLATE_FOLDER_EMAIL_FETCH_ERROR,
  payload: { error },
});