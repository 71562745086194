import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.css';

const Loading = () => {
  return (
    <div className="loading">
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">Loading data...</span>
      </Spinner>
    </div>
  );
};

export default Loading;
