import { call, put } from 'redux-saga/effects';

import {
  templateListFetchStart,
  templateListFetchSuccess,
  templateSaveFetchSuccess,
  templateSaveFetchError,
  templateDeleteFetchSuccess,
  templateDeleteFetchError,
  templateCopyFetchSuccess,
  templateCopyFetchError,
  templateEditFetchSuccess,
  templateEditFetchError,
} from '../actions/template';

import { templateService } from '../services/template';

export function* getTemplates(action) {
  const response = yield call(templateService.getTemplates, action);
  yield put(templateListFetchSuccess(response));
}

export function* saveTemplate(action) {
  try {
    const response = yield call(templateService.saveTemplate, action.payload);
    yield put(templateSaveFetchSuccess(response));
    yield put(templateListFetchStart());
  } catch (error) {
    yield put(templateSaveFetchError(error));
  }
}

export function* deleteTemplate(action) {
  try {
    const response = yield call(templateService.deleteTemplate, action.payload);
    yield put(templateDeleteFetchSuccess(response));
  } catch (error) {
    yield put(templateDeleteFetchError(error));
  }
}

export function* copyTemplate(action) {
  try {
    const response = yield call(templateService.copyTemplate, action.payload);
    yield put(templateCopyFetchSuccess(response));
    yield put(templateListFetchStart());
  } catch (error) {
    yield put(templateCopyFetchError(error));
  }
}

export function* editTemplate(action) {
  try {
    const response = yield call(templateService.editTemplate, action.payload);
    yield put(templateEditFetchSuccess(response));
  } catch (error) {
    yield put(templateEditFetchError(error));
  }
}
