export const SEARCH_LIST_FETCH_START = '[searches] list search fetch start';
export const SEARCH_LIST_FETCH_SUCCESS = '[searches] list search fetch success';
export const SEARCH_LIST_FETCH_ERROR = '[searches] list search fetch error';

export const SEARCH_SAVE_FETCH_START = '[searches] save search fetch start';
export const SEARCH_SAVE_FETCH_SUCCESS = '[searches] save search fetch success';
export const SEARCH_SAVE_FETCH_ERROR = '[searches] save search fetch error';

export const SEARCH_DELETE_FETCH_START = '[searches] delete search fetch start';
export const SEARCH_DELETE_FETCH_SUCCESS = '[searches] delete search fetch success';
export const SEARCH_DELETE_FETCH_ERROR = '[searches] delete search fetch error';

export const SEARCH_COPY_FETCH_START = '[searches] copy search fetch start';
export const SEARCH_COPY_FETCH_SUCCESS = '[searches] copy search fetch success';
export const SEARCH_COPY_FETCH_ERROR = '[searches] copy search fetch error';
export const SEARCH_COPY_REMOVE = '[searches] copy search remove';

export const SEARCH_EDIT_FETCH_START = '[searches] edit search fetch start';
export const SEARCH_EDIT_FETCH_SUCCESS = '[searches] edit search fetch success';
export const SEARCH_EDIT_FETCH_ERROR = '[searches] edit search fetch error';

export const SEARCH_CURRENT_SELECTION = '[searches] load current save search';
export const SEARCH_CLEAN_MESSAGE = '[searches] clean message';
export const CLEAN_CURRENT_SAVED_SEARCH = '[searches] clean current save search';

/// List searchs actions
export const searchListFetchStart = query => ({
  type: SEARCH_LIST_FETCH_START,
  payload: query,
});

export const searchListFetchSuccess = searches => ({
  type: SEARCH_LIST_FETCH_SUCCESS,
  payload: { searches },
});

export const searchListFetchError = error => ({
  type: SEARCH_LIST_FETCH_ERROR,
  payload: { error },
});

/// Saved search actions
export const searchSaveFetchStart = query => ({
  type: SEARCH_SAVE_FETCH_START,
  payload: query,
});

export const searchSaveFetchSuccess = searches => ({
  type: SEARCH_SAVE_FETCH_SUCCESS,
  payload: { searches },
});

export const searchCleanMessage = query => ({
  type: SEARCH_CLEAN_MESSAGE,
  payload: { query },
});

export const searchSaveFetchError = error => ({
  type: SEARCH_SAVE_FETCH_ERROR,
  payload: { error },
});

/// Load current search action
export const populateCurrentSavedSelection = selectedSearch => ({
  type: SEARCH_CURRENT_SELECTION,
  payload: { selectedSearch },
});

export const cleanCurrentSavedSearch = () => ({
  type: CLEAN_CURRENT_SAVED_SEARCH,
  payload: {  },
});
/// Delete searchs actions
export const searchDeleteFetchStart = search => ({
  type: SEARCH_DELETE_FETCH_START,
  payload: search,
});

export const searchDeleteFetchSuccess = search => ({
  type: SEARCH_DELETE_FETCH_SUCCESS,
  payload: { search },
});

export const searchDeleteFetchError = error => ({
  type: SEARCH_DELETE_FETCH_ERROR,
  payload: { error },
});

/// Copy searchs actions
export const searchCopyFetchStart = id => ({
  type: SEARCH_COPY_FETCH_START,
  payload: { id: id},
});

export const searchCopyFetchSuccess = search => ({
  type: SEARCH_COPY_FETCH_SUCCESS,
  payload: { search },
});

export const searchCopyFetchError = error => ({
  type: SEARCH_COPY_FETCH_ERROR,
  payload: { error },
});

export const searchCopyRemove = () => ({
  type: SEARCH_COPY_REMOVE,
  payload: { },
});

export const searchEditFetchStart = search => ({
  type: SEARCH_EDIT_FETCH_START,
  payload: search,
});

export const searchEditFetchSuccess = search => ({
  type: SEARCH_EDIT_FETCH_SUCCESS,
  payload: { search },
});

export const searchEditFetchError = error => ({
  type: SEARCH_EDIT_FETCH_ERROR,
  payload: { error },
});
