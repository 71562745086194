export const HISTORY_LIST_FETCH_START = '[history] list history fetch start';
export const HISTORY_LIST_FETCH_SUCCESS = '[history] list history fetch success';
export const HISTORY_LIST_FETCH_ERROR = '[history] list history fetch error';

export const HISTORY_SAVE_FETCH_START = '[history] save history fetch start';
export const HISTORY_SAVE_FETCH_SUCCESS = '[history] save history fetch success';
export const HISTORY_SAVE_FETCH_ERROR = '[history] save history fetch error';

export const HISTORY_EDIT_FETCH_START = '[history] edit history fetch start';
export const HISTORY_EDIT_FETCH_SUCCESS = '[history] edit history fetch success';
export const HISTORY_EDIT_FETCH_ERROR = '[history] edit history fetch error';

export const HISTORY_CURRENT_SELECTION = '[history] load current save history';

export const historyListFetchStart = query => ({
  type: HISTORY_LIST_FETCH_START,
  payload: query,
});

export const historyListFetchSuccess = histories => ({
  type: HISTORY_LIST_FETCH_SUCCESS,
  payload: { histories },
});

export const historyListFetchError = error => ({
  type: HISTORY_LIST_FETCH_ERROR,
  payload: { error },
});

export const historySaveFetchStart = query => ({
  type: HISTORY_SAVE_FETCH_START,
  payload: query,
});

export const historySaveFetchSuccess = histories => ({
  type: HISTORY_SAVE_FETCH_SUCCESS,
  payload: { histories },
});

export const historySaveFetchError = error => ({
  type: HISTORY_SAVE_FETCH_ERROR,
  payload: { error },
});

export const populateCurrentSavedSelection = selectedHistory => ({
  type: HISTORY_CURRENT_SELECTION,
  payload: { selectedHistory },
});

export const historyEditFetchStart = history => ({
  type: HISTORY_EDIT_FETCH_START,
  payload: history,
});

export const historyEditFetchSuccess = history => ({
  type: HISTORY_EDIT_FETCH_SUCCESS,
  payload: { history },
});

export const historyEditFetchError = error => ({
  type: HISTORY_EDIT_FETCH_ERROR,
  payload: { error },
});
