import HttpService from './http';
import config from '../config';

export class AuthService {
  async getUser(params) {
    const path = config.api.endpoints.getUser.replace(':id', params.payload.id);
    return HttpService.get(path);
  }

  async getAuthUser(data) {
    const path = config.api.endpoints.getAuthUser;
    return HttpService.post(path, {
      email: data.payload.email,
      password: data.payload.password,
    });
  }

  async activateUser(data) {
    const path = config.api.endpoints.activateUser;
    return HttpService.post(path, {
      id: data.payload.userId,
      activation_code: data.payload.activationCode,
    });
  }

  async resetUser(data) {
    const path = config.api.endpoints.resetUser;
    return HttpService.post(path, {
      id: data.payload.userId,
      activation_code: data.payload.activationCode,
      password: data.payload.password
    });
  }

  async recoverUser(data) {
    const path = config.api.endpoints.recoverUser;
    return HttpService.post(path, {
      email: data.payload.email
    });
  }

  async registerUser(data) {
    const path = config.api.endpoints.registerUser;
    return HttpService.post(path, {
      name: data.payload.name,
      email: data.payload.email,
      password: data.payload.password,
    });
  }

  async editUser(data) {
    const path = config.api.endpoints.editUser;
    return HttpService.put(path, {
      name: data.payload.name,
      email: data.payload.email,
    });
  }
}

export const authService = new AuthService();
