export const FILTERED_TOPIC_FETCH_START = '[topic] filtered topic fetch start';
export const FILTERED_TOPIC_FETCH_SUCCESS = '[topic] filtered topic fetch success';
export const FILTERED_TOPIC_FETCH_ERROR = '[topic] filtered topic fetch error';
export const SEARCH_TERMS_REMOVE = '[topic] remove search terms';

export const filteredTopicFetchStart = param => ({
  type: FILTERED_TOPIC_FETCH_START,
  payload: param,
});

export const filteredTopicFetchSuccess = result => ({
  type: FILTERED_TOPIC_FETCH_SUCCESS,
  payload: { result },
});

export const filteredTopicFetchError = error => ({
  type: FILTERED_TOPIC_FETCH_ERROR,
  payload: { error },
});
