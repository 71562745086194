import { FILTER_INPUTS_SAVE_SELECTED } from '../../actions/menu';
import { SEARCH_CURRENT_SELECTION, CLEAN_CURRENT_SAVED_SEARCH } from '../../actions/search';
import { availableSources } from '../../constants/sources';

export const initialState = {
  inputs: {
    sources: availableSources,
    analysis: [],
    companies: [],
    customTerm: '',
    sectors: [],
    timeframe: 0,
    toDate: null,
    fromDate: null,
  },
  terms: {
    selected: [],
    all: [],
  },
  id: null,
  name: null,
};

export const filterInputsSelectedReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FILTER_INPUTS_SAVE_SELECTED:
      return { ...state, inputs: payload };
    case SEARCH_CURRENT_SELECTION:
      return {
        ...state,
        inputs: payload.selectedSearch.filters.filterInputs,
        terms: {
          selected: payload.selectedSearch.filters.terms,
          all: payload.selectedSearch.filters.allTerms,
        },
        id: payload.selectedSearch._id,
        name: payload.selectedSearch.name,
      };
    case CLEAN_CURRENT_SAVED_SEARCH: 
      return {
        ...state,
        id: null,
        name: null,
      }
    default:
      return state;
  }
};
