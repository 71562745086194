export const CATEGORY_LIST_FETCH_START = '[menu] list category fetch start';
export const CATEGORY_LIST_FETCH_SUCCESS = '[menu] list category fetch success';
export const CATEGORY_LIST_FETCH_ERROR = '[menu] list category fetch error';
export const CATEGORY_SAVE_SELECTED = '[menu] save selected category';

export const categoryListFetchStart = () => ({
  type: CATEGORY_LIST_FETCH_START,
  payload: null,
});

export const categoryListFetchSuccess = categories => ({
  type: CATEGORY_LIST_FETCH_SUCCESS,
  payload: { categories },
});

export const categoryListFetchError = error => ({
  type: CATEGORY_LIST_FETCH_ERROR,
  payload: { error },
});

export const categorySaveSelected = selectedCategory => ({
  type: CATEGORY_SAVE_SELECTED,
  payload: { selectedCategory },
});
