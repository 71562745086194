import { call, put } from 'redux-saga/effects';

import {
  searchListFetchStart,
  searchListFetchSuccess,
  searchSaveFetchSuccess,
  searchSaveFetchError,
  searchDeleteFetchSuccess,
  searchDeleteFetchError,
  searchCopyFetchSuccess,
  searchCopyFetchError,
  searchEditFetchSuccess,
  searchEditFetchError,
} from '../actions/search';

import { searchService } from '../services/search';

export function* getSearches(action) {
  const response = yield call(searchService.getSearches, action);
  yield put(searchListFetchSuccess(response));
}

export function* saveSearch(action) {
  try {
    const response = yield call(searchService.saveSearch, action.payload);
    yield put(searchSaveFetchSuccess(response));
    yield put(searchListFetchStart());
  } catch (error) {
    yield put(searchSaveFetchError(error));
  }
}

export function* deleteSearch(action) {
  try {
    const response = yield call(searchService.deleteSearch, action.payload);
    yield put(searchDeleteFetchSuccess(response));
  } catch (error) {
    yield put(searchDeleteFetchError(error));
  }
}

export function* copySearch(action) {
  try {
    const response = yield call(searchService.copySearch, action.payload);
    yield put(searchCopyFetchSuccess(response));
    yield put(searchListFetchStart());
  } catch (error) {
    yield put(searchCopyFetchError(error));
  }
}

export function* editSearch(action) {
  try {
    const response = yield call(searchService.editSearch, action.payload);
    yield put(searchEditFetchSuccess(response));
  } catch (error) {
    yield put(searchEditFetchError(error));
  }
}
