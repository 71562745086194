import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPenSquare, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import FolderDeleteModal from './search-delete-modal';
import SearchShareFolderModal from './search-share-folder-modal';
import './styles.css';

const FavoriteItem = props => {
  const { index, folder, confirmDeleteAction , searchFolderEditFetchStart } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [folderName, setItemName] = useState(folder.name);
  const [openDeleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [openShareFolderConfirmationModal, setShareFolderConfirmationModal] = useState(false);
  useEffect(() => {
    setItemName(folder.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder]);

  const displayModalShareFolderItem = () => {
    setShareFolderConfirmationModal(true);
  };

  const displayModalDeleteItem = () => {
    setDeleteConfirmationModal(true);
  };

  const handleEditItem = () => {
    setEditOpen(!editOpen);
  };

  const handlePressEnter = e => {
    setItemName(e.target.value);
    if (e.keyCode === 13) {
      // EDIT SEARCH NAME
      folder.name = e.target.value;
      searchFolderEditFetchStart(folder);
      setEditOpen(!editOpen);
    }
  };

  return (
    <div key={index} >
      {!editOpen ? (
        <>
        {folderName}
        <FontAwesomeIcon
          icon={faShareAlt}
          className="icon-action-search blue"
          onClick={() => displayModalShareFolderItem()}
        />
        <FontAwesomeIcon
          icon={faPenSquare}
          className="icon-action-search gray"
          onClick={() => handleEditItem()}
        />
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="icon-action-search red"
          onClick={() => displayModalDeleteItem()}
        />
        <FolderDeleteModal
          openModal={openDeleteConfirmationModal}
          confirmDeleteAction={() => confirmDeleteAction(folder)}
          closeModal={value => setDeleteConfirmationModal(value)}
        />
        <SearchShareFolderModal
          openModal={openShareFolderConfirmationModal}
          shareItem={folder}
          closeModal={value => setShareFolderConfirmationModal(value)}
        />
        </>
      ) : (
          <>
            <span className="enter-label">Press enter to edit the name</span>
            <input
              type="text"
              className="enter-label-input"
              value={folderName}
              onChange={e => {
                setItemName(e.target.value);
              }}
              onKeyDown={e => handlePressEnter(e)}
            />
          </>
      )}
    </div>
  );
};

FavoriteItem.propTypes = {
  folder: PropTypes.object,
  index: PropTypes.number,
};

export default FavoriteItem;
