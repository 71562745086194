import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPenSquare } from '@fortawesome/free-solid-svg-icons';
import FolderDeleteModal from './template-delete-modal';
import './styles.css';

const TemplateFolderItem = props => {
  const { folder, confirmDeleteAction , templateFolderEditFetchStart } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [folderName, setItemName] = useState(folder.name);
  const [openDeleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  useEffect(() => {
    setItemName(folder.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder]);

  const displayModalDeleteItem = () => {
    setDeleteConfirmationModal(true);
  };

  const handleEditItem = () => {
    setEditOpen(!editOpen);
  };

  const handlePressEnter = e => {
    setItemName(e.target.value);
    if (e.keyCode === 13) {
      // EDIT SEARCH NAME
      folder.name = e.target.value;
      templateFolderEditFetchStart(folder);
      setEditOpen(!editOpen);
    }
  };

  return (
    <>
      {!editOpen ? (
        <>
        {folderName}&nbsp;&nbsp;
        
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="icon-action-template red"
          onClick={() => displayModalDeleteItem()}
          size="lg"
        />&nbsp;
        <FontAwesomeIcon
          icon={faPenSquare}
          className="icon-action-template gray"
          onClick={() => handleEditItem()}
          size="lg"
        />&nbsp;
        <FolderDeleteModal
          openModal={openDeleteConfirmationModal}
          confirmDeleteAction={() => confirmDeleteAction(folder)}
          closeModal={value => setDeleteConfirmationModal(value)}
        />
        </>
      ) : (
          <>
            <span className="enter-label">Press enter to edit the name</span>
            <input
              type="text"
              className="enter-label-input"
              value={folderName}
              onChange={e => {
                setItemName(e.target.value);
              }}
              onKeyDown={e => handlePressEnter(e)}
            />
          </>
      )}
    </>
  );
};

TemplateFolderItem.propTypes = {
  folder: PropTypes.object,
  index: PropTypes.number,
};

export default TemplateFolderItem;
