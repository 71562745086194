
import React, { useState } from 'react';
import {useDispatch} from "react-redux";
import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from 'react-redux';
import {highlightSaveList} from "../../actions/highlight";

export default function SliderHiglights () {
    const dispatch = useDispatch();
    const [index, setIndex] = useState(0);
    const articleHighlight = useSelector(state => state.articleHighlight.data);
    const handleSelect = (selectedIndex, e) => {
      articleHighlight.forEach((highlight, index) => {
        if (selectedIndex === index){
          window.location.hash = highlight.id;
        }
      });
      setIndex(selectedIndex);
    };

    const handleChangeTextarea = (value, index) =>{
      const auxList = [...articleHighlight];
      auxList[index].notes = value;
      dispatch(highlightSaveList(auxList));
    }

    return (
      <Carousel activeIndex={index} interval={null} onSelect={handleSelect}>
        {
          articleHighlight.map((highlight, index) => {
            return (
            <Carousel.Item key={highlight.id}>
              <div style={{'height':'170px', 'width':'100%','backgroundColor':'#ddd'}}>
                <Carousel.Caption>
                  <a href={'#'+highlight.id} ><p style={{'color':'black'}} dangerouslySetInnerHTML={{__html: highlight.content}}></p></a>
                  <textarea defaultValue={(highlight.notes ? highlight.notes : '')} rows="2" cols="60" style={{fontStyle:'10px', color:'black'}} onChange={event => { handleChangeTextarea(event.target.value, index) }} placeholder="Notes"></textarea>
                </Carousel.Caption>
              </div>
            </Carousel.Item>);
          })
        }
      </Carousel>
    );
  }
