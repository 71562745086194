const config = {
  environment: process.env.NODE_ENV || 'develop',
  locale: 'en-US',
  language: 'en-US',
  debugUrl: 'http://admin.grundoon.bafactory.net:8000/result',
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3800/api',
    endpoints: {
      getPdf: '/article/get_pdf',
      getArticle: '/article/get/:id',
      getArticles: '/article/all',
      filteredTopic: '/topic/filter/:id',
      getTerms: '/term/all',
      getTermsModalCompanies: 'term/all-modal-companies',
      getTermsModalMetrics: 'term/all-modal-metrics',
      getTermsArticle: '/terms/article',
      getTermType: '/term/type',
      getCategories: '/article/all-category',
      getSectors: '/sector/all',
      getHistories: '/history/user/:id',
      saveHistory: '/history/add',
      editHistory: '/history/edit/:id',
      getSearches: '/search/user/:id',
      saveSearch: '/search/add',
      copySearch: '/search/copy',
      getSearch: '/search/get',
      deleteSearch: '/search/delete/:id',
      editSearch: '/search/edit/:id',
      searchFolderEmail: '/search-folder/send-email/:id',
      getSearchesFolders: '/search-folder/user/:id',
      saveSearchFolder: '/search-folder/add',
      copySearchFolder: '/search-folder/copy',
      deleteSearchFolder: '/search-folder/delete/:id',
      editSearchFolder: '/search-folder/edit/:id',
      getTemplates: '/template/user/:id',
      saveTemplate: '/template/add',
      copyTemplate: '/template/copy',
      getTemplate: '/template/get',
      deleteTemplate: '/template/delete/:id',
      editTemplate: '/template/edit/:id',
      templateFolderEmail: '/template-folder/send-email/:id',
      getTemplatesFolders: '/template-folder/user/:id',
      saveTemplateFolder: '/template-folder/add',
      copyTemplateFolder: '/template-folder/copy',
      deleteTemplateFolder: '/template-folder/delete/:id',
      editTemplateFolder: '/template-folder/edit/:id',
      getAuthUser: '/user/login',
      activateUser: 'user/activate',
      registerUser: '/user/register',
      recoverUser: '/user/recover',
      resetUser: '/user/reset',
      editUser: '/user/edit',
      shareEmail: '/share/share-email/:id'
    },
  },
  apiTakeApps: {
    baseUrl: process.env.REACT_APP_API_BASE_URL_GRUNDOON || 'http://admin.grundoon.bafactory.net:8000/api',
    endpoints: {
      getArticles: '/search_by_term',
    },
  },
};

export default config;
