import React from 'react';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
const FavoriteEmailPreview = (props) =>{
    const { draftEmail, goBackDraft ,sendFavoriteListEmail } = props;
    const getSource= (sourceKey, sources) => {
        let name = '';
        sources.forEach((source, index) => {
            if (source.id === sourceKey){
                name = source.label;
            }
        });
        return name;
    }
    const printArticles = () =>{
        if(draftEmail.items){
            return draftEmail.items.map((item, index)=>{
                return(<>
                    <div className="article-list-item">
                        <Row>
                            <Col className="article-access-item" >
                                {item.value.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="article-list-subtext">{getSource(item.value.filters.currentSource, item.value.filters.filterInputs.sources)}</Col>
                        </Row>
                        <Row>
                            <Col>
                                <div dangerouslySetInnerHTML={{__html: printSummary(item.value.filters.filterInputs.article)}}></div>
                            </Col>
                        </Row>
                    </div>
                </>)
            });
        }
    }

    const printSummary = (item) => {
        let text = '';
        if (item.highlights){
          text +=`
          <tr>
              <td bgcolor="#fff" style="padding: 10px 10px 10px 10px;">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 700px;">
                      <tr>
                          <td bgcolor="#FFECD1" style="padding: 30px 30px 30px 30px; border-radius: 4px 4px 4px 4px; color: #333; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 12px; font-weight: 400; ">
          `;
          if (item.highlights.notes){
            text += item.highlights.notes;
          }
          if (item.highlights.data){
            item.highlights.data.forEach((highlight, index)=>{
              text += `<p><b>`+highlight.content+`</b></p>`;
              text += `<p style="padding-left:10px"><i>`+highlight.notes+`</i></p>`
            });
          }
          text +=`
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          `;
        }
        return text;
    }

    return(
        <>
            <Button
            variant="primary"
            className="pull-right btn-margin"
            onClick={() => goBackDraft()}
            >
                Go Back Draft
            </Button><br/><br/>
            <div className="preview-draft" dangerouslySetInnerHTML={{__html: draftEmail.body}}>
            </div>
            <div>
                {printArticles()}
            </div>
            <Button
            variant="primary"
            className="pull-left btn-margin"
            onClick={() => sendFavoriteListEmail()}
            >
                Send E-mail
            </Button>
        </>
    )
}

export default FavoriteEmailPreview;