
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCollapse from 'react-collapsed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import "./styles.css";

export default function SummaryHiglightsOnlyRead(props) {
    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0
    };
    const [value, setValue] = useState('');
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
    const [list, setList ]= useState([]);
    const articleHighlight = useSelector(state => state.articleHighlight);
    useEffect(()=>{
      if (articleHighlight){
        setList([...articleHighlight.data]);
      }
    },[articleHighlight]);
    useEffect(()=>{
      if(articleHighlight){
        setValue(articleHighlight.notes);
      }
    // eslint-disable-next-line  
    },[]);
return (
    <div className="collapsible">
        <div className="header" {...getToggleProps()}>
            <div className="title">{props.title}</div>
            <div className="icon">
                {( isExpanded && <FontAwesomeIcon icon={faChevronCircleDown} />)}
                {( !isExpanded && <FontAwesomeIcon icon={faChevronCircleUp} />)}
            </div>
        </div>
        <div className="summary" {...getCollapseProps()}>
          <h3>Summary</h3>
          <div dangerouslySetInnerHTML={{__html: value}} ></div>
          {list.map((item, index) => { return (
              <li className="summary-item" key={index}>
                <div>
                  <a href={'#'+item.id} ><p style={{'color':'black'}} dangerouslySetInnerHTML={{__html: item.content}}></p></a>
                </div>
                <div>
                  <p style={{'font-size':'10px', 'font-style': 'italic'}} >{item.notes}</p>
                </div>
              </li>
            );}
          )}
          <br></br>
        </div>
    </div>
    );
}