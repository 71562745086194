import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPenSquare } from '@fortawesome/free-solid-svg-icons';
import SearchDeleteModal from './search-delete-modal';
import './styles.css';

const FavoriteItem = props => {
  const [editOpen, setEditOpen] = useState(false);
  const { item, loadSavedSearch, editSaveSearchAction, confirmDeleteAction } = props;
  const [itemName, setItemName] = useState(item.name);
  const [openDeleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    setItemName(item.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const displayModalDeleteItem = () => {
    setDeleteConfirmationModal(true);
  };

  const handleEditItem = () => {
    setEditOpen(!editOpen);
  };

  const handlePressEnter = e => {
    setItemName(e.target.value);
    if (e.keyCode === 13) {
      // EDIT SEARCH NAME
      item.name = e.target.value;
      editSaveSearchAction(item);
      setEditOpen(!editOpen);
    }
  };

  return (
      <>
      {!editOpen ? (
        <>
          <div className="search-item-buttons">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="icon-action-search red"
              onClick={() => displayModalDeleteItem()}
            />
            <FontAwesomeIcon
              icon={faPenSquare}
              className="icon-action-search gray"
              onClick={() => handleEditItem()}
            />
            <SearchDeleteModal
              openModal={openDeleteConfirmationModal}
              confirmDeleteAction={() => confirmDeleteAction(item)}
              closeModal={value => setDeleteConfirmationModal(value)}
            />
          </div>
          <span className="article-access-item" role="button" aria-hidden="true" tabIndex="0" onClick={() => loadSavedSearch(item)}>
            {(item.filters.filterInputs && item.filters.filterInputs.article) ? '' : '#' }{item.name.length < 100 ? item.name : item.name.substr(0, 50).concat('...')}
          </span>
          

        </>
      ) : (
        <>
          <span className="enter-label">Press enter to save the name</span>
          <input
            type="text"
            className="enter-label-input"
            value={itemName}
            onChange={e => {
              setItemName(e.target.value);
            }}
            onKeyDown={e => handlePressEnter(e)}
          />
        </>
      )}
    </>
  );
};

FavoriteItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  loadSavedSearch: PropTypes.func,
};

export default FavoriteItem;
