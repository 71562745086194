export const availableSources = [
  {
    id: 'the-wall-street-journal',
    name: 'The Wall Street Journal',
    type: "news",
    label: "The Wall Street Journal",
    publisher: "the-wall-street-journal"
  },
  {
    id: 'cnbc',
    name: 'CNBC',
    type: "news",
    label: "CNBC",
    publisher: "cnbc"
  }];
export const availableAnalysisSources = [
  {
    id: 'fidelity-industry-survey',
    name: 'CFRA - Industry Survey',
    type: "analysis",
    label: "CFRA - Industry Survey",
    publisher: "fidelity",
    firm: "cfra",
    report_type: "Industry Survey"
  },
  {
    id: 'fidelity-sector-watch',
    name: 'CFRA - Sector Watch',
    type: "analysis",
    label: "CFRA - Sector Watch",
    publisher: "fidelity",
    firm: "cfra",
    report_type: "Sector Watch"
  }
];
