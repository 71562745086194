import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { DropdownList } from 'react-widgets'
import { Multiselect } from 'react-widgets';

import { availableSources, availableAnalysisSources } from './../../constants/sources';
import Timeframe from './timeframe';

import 'react-widgets/dist/css/react-widgets.css';

import './styles.css';

const SubMenu = props => {
  const { inputs, setInputs} = props;
  const newsSources = availableSources;
  const newAnalysisSources = availableAnalysisSources;
  let processSector = () => {
      let sectors = props.sectors;
      let empty = {order_dropdown: 0 , id: null, parent_id: null, level_number: 1, name: 'All'}
      sectors = sectors
        .map(function (sector, index, arraySector){
          let parent_id = 0;
          if (sector.level_number === 1) {
            sector.order_dropdown = sector.id * 1000;
          }
          if (sector.level_number === 3) {
            for (let i = 0; i < arraySector.length; i++){
              if (arraySector[i].id === sector.parent_id){
                parent_id = arraySector[i].parent_id;
              }
            }
            sector.order_dropdown = parent_id *1000 + index;
          }
          return sector;
        })
        .filter(function(item){ return item.level_number === 3 || item.level_number === 1;});
      sectors.unshift(empty);
      return sectors;
  }

  let sortSector = (a, b) => {
    let comparison = 0;
    if (a.order_dropdown > b.order_dropdown)
      comparison = 1;
    else if (a.order_dropdown < b.order_dropdown)
      comparison = -1;
    return comparison;
  }

  const processedSectors = processSector();
  processedSectors.sort(sortSector);

  let ListItem = ({ item }) => {
    return (item.level_number === 1 ) ?
    (
      <span>
        <strong>
      {item.name}</strong>
      </span>
    ) : (
      <span style={{'paddingLeft' : '10px'}}>{'  ' + item.name}</span>
    );
  }
  return (
    <>
      <Row className="bg-light-gray">
        <Col xs={4}>
          <span className="header-label">News Sources:</span>
          <Multiselect
            data={newsSources}
            value={inputs.sources}
            textField="label"
            onChange={selected => setInputs({ ...inputs, sources: selected })}
          />
        </Col>
        <Col xs={4}>
          <span className="header-label">Analysis Sources:</span>
          <Multiselect
            data={newAnalysisSources}
            value={inputs.analysis}
            textField="label"
            onChange={selected => setInputs({ ...inputs, analysis: selected })}
          />
        </Col>
        <Col xs={4}>
          <span className="header-label">Sectors:</span>
          <DropdownList
            data={processedSectors ? processedSectors : []}
            value={inputs.sectors}
            onChange={selected => setInputs({ ...inputs, sectors: selected })}
            textField="name"
            itemComponent={ListItem}
          />
        </Col>
      </Row>
      <hr />
      <Timeframe inputs={inputs} setInputs={setInputs} />
    </>
  );
};

SubMenu.propTypes = {
  categories: PropTypes.array,
  sectors: PropTypes.array,
  inputs: PropTypes.object,
  setInputs: PropTypes.func
};

export default SubMenu;
