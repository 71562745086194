import {
  TERM_LIST_FETCH_START,
  TERM_LIST_FETCH_SUCCESS,
  TERM_LIST_FETCH_ERROR,
  TERM_LIST_CLEAN,
  TERM_CUSTOM_ADDED,
  TERM_TYPE_FETCH_SUCCESS,
} from '../../actions/term';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

export const termsListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TERM_LIST_FETCH_START:
      return { ...state, isLoading: true };
    case TERM_LIST_FETCH_SUCCESS:
      return {
        ...state,
        data: payload.terms,
        error: null,
      };
    case TERM_LIST_CLEAN:
      return {
        ...state,
        data: [],
        error: null,
      };  
    case TERM_LIST_FETCH_ERROR:
      return { ...state, data: '', error: payload.error, isLoading: false, };
    case TERM_CUSTOM_ADDED:
      return {
        ...state,
        data: [...state.data, payload.term],
      };
    case TERM_TYPE_FETCH_SUCCESS:
      let find = false;
      state.data.map((item) => {
        if (item.core === payload.term.phrase) {
          item.selected = true;
          find = true;
        }
        return true;
      });
      if (!find && payload.term.phrase) {
        let custom = (payload.term.type === 'metric' || payload.term.type ==='company') ? false: true;
        let term = {"core": payload.term.phrase , "type": payload.term.type, "selected": true, "custom": custom, related_terms: [], calculated_terms: []};
        if (payload.term.type ==='company'){
          term.ticker = payload.term.ticker;
          term.phrase = payload.term.phrase;
          term.core = payload.term.indexed_name;
        }
        return {
          ...state,
          data: [...state.data, term],
        }
      }else return state;
      
    default:
      return state;
  }
};
