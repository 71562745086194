import { call, put } from 'redux-saga/effects';

import {
  historyListFetchStart,
  historyListFetchSuccess,
  historySaveFetchSuccess,
  historySaveFetchError,
  historyEditFetchSuccess,
  historyEditFetchError,
} from '../actions/history';

import { historyService } from '../services/history';

export function* getHistories(action) {
  const response = yield call(historyService.getHistories, action);
  yield put(historyListFetchSuccess(response));
}

export function* saveHistory(action) {
  try {
    const response = yield call(historyService.saveHistory, action.payload);
    yield put(historySaveFetchSuccess(response));
    yield put(historyListFetchStart());
  } catch (error) {
    yield put(historySaveFetchError(error));
  }
}

export function* editHistory(action) {
  try {
    const response = yield call(historyService.editHistory, action.payload);
    yield put(historyEditFetchSuccess(response));
  } catch (error) {
    yield put(historyEditFetchError(error));
  }
}