import { call, put } from 'redux-saga/effects';

import { articleListFetchSuccess, articleDetailFetchSuccess } from '../actions/article';

import { articleService } from '../services/article';

export function* getArticles(action) {
  const response = yield call(articleService.getArticles, action);
  yield put(articleListFetchSuccess(response));
}

export function* getArticle(action) {
  const response = yield call(articleService.getArticle, action);
  yield put(articleDetailFetchSuccess(response));
}
