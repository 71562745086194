import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetFetchStart } from '../../actions/auth';

import './styles.css';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const loginStatus = useSelector(state => state.login.status);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const { userId , activationCode } = useParams();
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const handleSubmit = event => {
    setValidated(false);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) setValidated(true);
    else {
      dispatch(resetFetchStart({userId: userId, activationCode: activationCode, password: password}));
    }
  };
  useEffect(() => {
    if (loginStatus) {
      history.push('/articles');
    }
  }, [loginStatus, history]);
  return (
    <Col>
      <Row>
        <Col className="auth-header">
          <img alt="logo" src="/logo.png" />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="auth-body">
          <Card>
            <Card.Header>Reseting your password</Card.Header>
            <Card.Body>
              <Card.Text>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      minLength={6}
                      required
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                    {!password && (
                      <Form.Control.Feedback type="invalid">
                        You must enter your password.
                      </Form.Control.Feedback>
                    )}
                    {password && password.length < 6 && (
                      <Form.Control.Feedback type="invalid">
                        You must enter a password with min 6 character.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicRePassword">
                    <Form.Label>Re-type Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Re-type Password"
                      minLength={6}
                      required
                      onChange={e => {
                        setRepassword(e.target.value);
                      }}
                    />
                    {!repassword && (
                      <Form.Control.Feedback type="invalid">
                        You must re-type your password.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Button variant="primary" block type="submit">
                    Reset Password
                  </Button>
                  <Form.Group controlId="formBasicLabel" className="forgot-label">
                    <Form.Label>
                      Go back to <Link to={'/login'}>Login Page.</Link>
                    </Form.Label>
                  </Form.Group>
                </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default ResetPassword;